<template>
	<b-form @submit="submit" class="edit-form mb-3 pl-3">
		<input-form class="p-0" :options="basicFormOptions" :actions="{submit: false, cancel: false}" v-model="result" :use-form="false"></input-form>
		<b-row class="justify-content-center">
			<b-button class="form-btn cancel-btn" @click="cancel">取消</b-button>
			<b-button class="form-btn submit-btn" type="submit">確定</b-button>
		</b-row>
	</b-form>
</template>


<script>
import InputText from '@/components/Input/InputText';
import InputSelect from '@/components/Input/InputSelect';
import InputTags from '@/components/Input/InputTags';
import InputForm from '@/components/Input/InputForm';
import { isEmpty, checkAllTrue } from '@/utils/assist';
import ProjectConfigs from '@/projects';

export default {
	name: 'ProjectInfoEditor',
	components: {
		InputText,
		InputSelect,
		InputTags,
		InputForm,
	},
	props: {
		project: {
			type: Object,
			default: () => { return {} }
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: { getUnitList: false, getBidList: false, getConstructionList: false, getUnitTags: false, getUnitMemberList: false},
			loading: true,
			result: {
				project_type: null,
				contractor_id: null, address: null, excavation_permit_name: null, emergency_repair_number: null,
				party_a_id: null, party_a_branch_id: null, supervisor_id: null, commissioner_id: null, pipeline_center_id: null, //right_of_way_department_id: null,
				expected_constructed_at: undefined, assigned_at: undefined, measured_at: undefined, file_made_at: undefined,
				bid_id: null, construction_id: null, construction_type: null,
				unit_tags_id: [], scene_pictures_url: null,
				// approved_start_at: null, approved_end_at: null,
				constructed_start_at: null, constructed_end_at: null,
			},
			constants: {
				project_types: [],
				unit_tags: [],
				unit_type_list: [],
				bid_list: [],
				construction_list: [],
				unit_branch_list: [],
				contractor_list: [],
				party_a_list: [],
				pipeline_center_list: [],
				tc_project_type_list: [],
				unit_list: [],
				unit_member_list: [],
			},
			options: {},
			initial: false,
			basicFormOptions: [],
		}
	},
	created() {
		this.result = Object.keys(this.result).reduce((obj, key) => {
			obj[key] = this.project[key]
			return obj
		}, {})
		this.loading = true
		this.constants.project_types = this.$store.getters.enum('project.type')
		this.result.unit_tags_id = this.project.unit_tags.map(tag => tag.id)
		this.constants.unit_type_list = this.$store.getters.enum('unit.type')
		this.constants.unit_type_list.shift()
		this.constants.tc_project_type_list = this.$store.getters.enum('telecom_project_type.type')
		// this.getUnitList(this.constants.unit_type_list.map(o=>o.index))
		this.initResult()
		this.getUnitList()
		this.getUnitTags()
		this.getUnitBranchList()
		this.getBidList()
		this.getConstructionList()
		this.getUnitMemberList()
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		// 'result.contractor_id': {
		// 	handler() { this.filterOptions() }
		// },
		// 'result.party_a_id': {
		// 	handler() { this.filterOptions() }
		// },
		// // 'result.pipeline_center_id': {
		// // 	handler() { this.filterOptions() }
		// // },
		// // 'result.right_of_way_department_id': {
		// // 	handler() { this.filterOptions() }
		// // },
		// 'result.bid_id': {
		// 	handler() { this.filterConstructions() }
		// },
		'result.assigned_at': {
			handler(value) {
				if(value && this.initial) {
					this.result.measured_at = (new Date(value)).addWorkDays(2)
					this.result.file_made_at = (new Date(value)).addDays(9)
				}
			}
		},
		'result.received_at': {
			handler(value) {
				if(value && this.initial){
					this.result.assigned_at = (new Date(value)).addWorkDays(1)
				}
			}
		},
		result: {
			deep: true,
			handler(value) {
				let projectType = this.constants.project_types.find(type => type.index === this.result.project_type)
				if(projectType && projectType.data.unit_name) {
					let party_a = this.constants.party_a_list.find(unit => unit.name === projectType.data.unit_name)
					if(party_a)
						this.result.party_a_id = party_a.id;
				}
				this.updateBasicFormOptions()
			}
		},
	},
	mounted() {
	},
	computed: {
		getDateAfterDays(date, days=0, only_workday=false) {
			return (date, days=0, only_workday=false) => {
				if(!date)
					date = new Date()
				date = new Date(date);
				if(only_workday)
					date.addWorkDays(days);
				else
					date.addDays(days);
				return new Date(date).format('yyyy-MM-dd')
			}
		},
		projectTypeEnum() {
			return this.constants.project_types.find(type => type.index === this.projectType)
		},
		formParams() {
			return ProjectConfigs.formParams(this.projectTypeEnum ? this.projectTypeEnum.key : null)
		},
		constructionList() {
			return [
				...this.result.party_a_branch_id ? this.constants.construction_list.filter(construction => construction.party_a_branch_id === this.result.party_a_branch_id) : [],
				// ...this.result.bid_id ? this.constants.construction_list.filter(construction => construction.bid_id === this.result.bid_id) : [],
			].map(construction => {
				return {
					...construction,
					text: construction.abbreviation && construction.abbreviation !== construction.name ? `${construction.abbreviation} (${construction.name})` : construction.name
				}
			})
		},
	},
	methods:{
		onLoadEnd() {
			this.updateBasicFormOptions()
			// this.filterOptions()
			this.initial = true;
		},
		initResult() {
			Object.assign(this.result, {
				...this.project,
				project_type: this.projectType
			})
			this.result.measured_at = this.project.measured_at
			this.result.file_made_at = this.project.file_made_at
			this.result.construction_type = this.project.construction_type_index
		},
		updateBasicFormOptions() {
			let party_a_branch_list = this.result.party_a_id ? this.constants.unit_branch_list.filter(unit_branch => unit_branch.unit_id === this.result.party_a_id) : [];
			let bid_list = this.constants.bid_list.filter(bid => this.getSelectedUnitIds().includes(bid.unit_id))
			let unit_member_list = this.result.party_a_branch_id ? this.constants.unit_member_list.filter(member => member.unit_branch_id === this.result.party_a_branch_id) : (this.result.party_a_id ? this.constants.unit_member_list.filter(member => member.unit_id === this.result.party_a_id) : []);
			this.basicFormOptions = [
				{ key: "party_a_id", type: "select", label: "甲方", required: true, options: this.constants.party_a_list, keys: {value: 'id', text: 'name'}, hide: this.projectType !== this.$store.getters.enum('project.type.other').index},
				{ key: "party_a_branch_id", type: "select", label: "甲方區處", options: party_a_branch_list, keys: {value: 'id', text: 'name'} },
				{ key: "supervisor_id", type: "select", label: "監工", required: true, options: unit_member_list, keys: {value: 'id', text: 'name'} },
				{ key: "commissioner_id", type: "select", label: "承辦人", required: true, options: unit_member_list, keys: {value: 'id', text: 'name'} },
				{ key: "construction_type", type: "select", label: "施工種類", required: true, options: this.constants.tc_project_type_list, keys: {value: 'index', text: 'text'} },
				{ key: "pipeline_center_id", type: "select", label: "道管中心", required: true, options: this.constants.pipeline_center_list, keys: {value: 'id', text: 'name'} },
				{ key: "bid_id", type: "select", label: "標案", options: bid_list, keys: {value: 'id', text: 'name'} },
				{ key: "construction_id", type: "select", label: "工程", options: this.constructionList, keys: {value: 'id', text: 'text'} },
				{ key: "contractor_id", type: "select", label: "承包商", required: this.projectType !== this.$store.getters.enum('project.type.other').index, options: this.constants.contractor_list, keys: {value: 'id', text: 'name'} },
				{ key: "serial_number", type: "text", label: "流水號", required: false },
				{ key: "work_order_number", type: "text", label: "工作單編號", required: false },
				{ key: "address", type: "text", label: "施工地址", required: true },
				{ key: "work_number", type: "text", label: "施工案號/W案號", required: false },
				{ key: "excavation_permit_name", type: "text", label: "路證編號", placeholder: '無路證', required: false },
				{ key: "emergency_repair_number", type: "text", label: "搶修路證編號", required: false, hide: !this.result.excavation_permit_name },
				// { key: "approved_start_at", type: "date", label: "路證核准起日", required: false, hide: !this.result.excavation_permit_name },
				// { key: "approved_end_at", type: "date", label: "路證核准迄日", required: false, hide: !this.result.excavation_permit_name},
				{ key: "constructed_start_at", type: "date", label: "路證施工起日", required: false, hide: !this.result.excavation_permit_name},
				{ key: "constructed_end_at", type: "date", label: "路證施工迄日", required: false, hide: !this.result.excavation_permit_name},
				{ key: "expected_constructed_at", type: "date", label: "施工日期", required: true },
				{ key: "received_at", type: "date", label: "收件日期", required: true },
				{ key: "assigned_at", type: "date", label: "派工日期", required: true },
				{ key: "measured_at", type: "date", label: "測量日期", required: true },
				{ key: "file_made_at", type: "date", label: "文件製作日期", required: true },
				{ key: "scene_pictures_url", type: "url", label: "資料連結", required: true },
			].filter(option => this.formParams.includes(option.key))
		},
		getSelectedUnitIds() {
			return [
				this.result.party_a_id ? this.result.party_a_id : 0,
				this.result.pipeline_center_id ? this.result.pipeline_center_id : 0,
				this.result.contractor_id ? this.result.contractor_id : 0,
			].filter(o => o)
		},
		getUnitList() {
			this.call.getUnitList = false
			this.$axios.getUnitList({}, (response) => {
				this.constants.unit_list = response.data;
				this.constants.party_a_list = response.data.filter(type => type.type_key === this.$store.getters.enum('unit.type.party_a').key);
				this.constants.contractor_list = response.data.filter(type => type.type_key === this.$store.getters.enum('unit.type.contractor').key);
				this.constants.pipeline_center_list = response.data.filter(type => type.type_key === this.$store.getters.enum('unit.type.pipeline_center').key);
				this.call.getUnitList = true
			}, (error) => {
				this.call.getUnitList = true
			})
		},
		getUnitBranchList() {
			this.call.getUnitBranchList = false
			this.$axios.getUnitBranchList({}, (response) => {
				this.constants.unit_branch_list = response.data;
				this.call.getUnitBranchList = true
			}, (error) => {
				this.call.getUnitBranchList = true
			})
		},
		getBidList() {
			this.call.getBidList = false
			this.$axios.getBidList({}, (response) => {
				this.constants.bid_list = response.data;
				this.call.getBidList = true
			}, (error) => {
				this.call.getBidList = true
			})
		},
		getConstructionList() {
			this.call.getConstructionList = false
			this.$axios.getConstructionList({}, (response) => {
				this.constants.construction_list = response.data;
				this.call.getConstructionList = true
			}, (error) => {
				this.call.getConstructionList = true
			})
		},
		getUnitTags() {
			this.call.getUnitTags = false
			this.$axios.getUnitTags({}, (response) => {
				this.constants.unit_tags = response.data
				this.filterTags();
				this.call.getUnitTags = true
			}, (error) => {
				this.call.getUnitTags = true
			})
		},
		getUnitMemberList() {
			this.call.getUnitMemberList = false
			this.$axios.getUnitMemberList({}, (response) => {
				this.constants.unit_member_list = response.data;
				this.call.getUnitMemberList = true
			}, (error) => {
				this.call.getUnitMemberList = true
			})
		},
		filterTags() {
			if(this.initial)
				this.result.unit_tags_id.splice(0, this.result.unit_tags_id.length)
			this.$set(this.options, "tags", this.constants.unit_tags.filter(tag => {
				return this.result.contractor_id === tag.unit_id || this.result.party_a_id === tag.unit_id || this.result.pipeline_center_id === tag.unit_id || this.result.right_of_way_department_id === tag.unit_id
			}))
		},
		cancel() {
			this.$emit("cancel")
		},
		submit(evt) {
			evt.preventDefault();
			// this.$emit("submit")
			this.$axios.updateProjectInfo(this.projectID, this.result, (response) => {
				alert("更新成功")
				this.$emit("submit")
			}, (error) => {
			})
		}
	}
}
</script>

<style scoped>
.edit-form > * {
	margin-top: .5rem;
}
ul > li {
	list-style: none;
	display: flex;
}
ul > li > *:first-child {
	flex: 0 1 30%;
	align-self: flex-start;
	justify-content: flex-end;
	margin-left: 1rem;
}
.info-outter-wrapper {
	width: 100%;
}
.info-wrapper > div {
	padding: .5rem;
}
.basic-wrapper {
	padding: .5rem;
	margin-bottom: 1rem;
}
.basic-wrapper:last-child {
	margin-bottom: 0;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.timeline-outside-wrapper {
	height: 4.5rem;
}
.timeline-wrapper {
	position: fixed;
	left: 4rem;
	bottom: 0;
	width: calc(100% - 4rem);
	width: -webkit-calc(100% - 4rem);
	width: -moz-calc(100% - 4rem);
	width: -o-calc(100% - 4rem);
	padding: .5rem;
}
@media (max-width:991px) {
	.timeline-wrapper {
		left: 0;
		width: 100%;
		display: none;
	}
}
.timeline {
	min-height: 4rem;
}
.project-data-wrapper {
	border-bottom: 1px solid #dee2e6;
	min-height: 2rem;
}
.project-data-wrapper:last-child {
	border: none;
}



.right-top {
	position: absolute;
	top: 0;
	right: 0;
}


.google-map {
	min-height: 300px;
	height: 400px;
}


.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>
