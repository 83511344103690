<template>
	<loading v-if="loading"/>
	<div v-else v-show="newProjects.length">
		<loading mode="fixed" image="1" size="lg" v-if="uploading"/>
		<b-card class="border-0" sub-title="新派工案件" body-class="pb-1">
			<bv-table class="px-lg-2 px-1" :columns="newProjectTable.columns" :detail-columns="newProjectTable.detailColumns" :data="newProjects" :configs="tableConfigs" :actions="newProjectTable.actions"></bv-table>
		</b-card>
		<modal v-model="projectCreatorModal.show" centered size="xl" title="新增案件">
			<project-creator ref="projectCreator" v-model="projectCreatorModal.formData" :group="group" :projectDataTypes="projectDataTypes" :submittable="false" @load="projectCreatorModal.loading=false"></project-creator>
			<template #footer>
				<b-button size="sm" variant="outline-primary" @click="showConstructionCreatorModal" :disabled="projectCreatorModal.loading">
					下一步 <i class="fa-fw fas fa-arrow-right"></i>
				</b-button>
			</template>
		</modal>
		<modal v-model="constructionSiteCreatorModal.show" centered size="xl" title="新增工地">
			<construction-site-creator ref="constructionSiteCreator" v-model="constructionSiteCreatorModal.formData" :group="group" :project="projectCreatorModal.formData" :submittable="false" @load="constructionSiteCreatorModal.loading=false"></construction-site-creator>
			<template #footer>
				<b-button size="sm" variant="outline-secondary" @click="showProjectCreatorModal(undefined)">
					<i class="fa-fw fas fa-arrow-left"></i> 上一步
				</b-button>
				<b-button size="sm" variant="outline-primary" @click="submit" :disabled="constructionSiteCreatorModal.loading">
					確認
				</b-button>
			</template>
		</modal>
	</div>
</template>

<script>
import Loading from '@/components/Loading/Loading.vue'
import BvTable from '@/components/Table/BvTable.vue'
import Modal from '@/components/Modal/Modal'
import ProjectCreator from '@/components/Project/Creator/ProjectCreator.vue'
import ConstructionSiteCreator from '@/components/Project/Site/ConstructionSiteCreator.vue'
import { checkAllTrue } from '@/utils/assist';

export default {
	name: 'NewProjectList',
	components:{
		Loading,
		BvTable,
		Modal,
		ProjectCreator,
		ConstructionSiteCreator,
	},
	props: {
		group: {
			type: Object
		},
		permission: {
			type: Object
		}
	},
	data() {
		return {
			loading: true,
			call: { getGroupNewAssignedProject: false, getProjectDataType: false },
			uploading: false,
			group_id: Number.isInteger(parseInt(this.group.id)) ? parseInt(this.group.id) : 0,
			projects: [],
			projectDataTypes: [],
			newProjectTable: {
				columns: [
					{ key: 'excavation_permit', label: '路證編號', sortable: true, searchable: true },
					{ key: 'address', label: '地址', sortable: true, searchable: true },
					{ key: 'date', label: '派案時間', sortable: true, searchable: true },
					{ key: 'paved_at', label: '銑鋪日期', sortable: true, searchable: true },
				],
				detailColumns: [
					{ key: 'excavation_permit', label: '路證編號' },
					{ key: 'address', label: '地址' },
					{ key: 'construction_name', label: '工程' },
					{ key: 'date', label: '派案時間' },
					{ key: 'paved_at', label: '銑鋪日期' },
				],
				configs: {
					actionsColumn: true,
					rowDetail: true,
					perPage: 5,
				},
				actions: [
					{
						//icon, label
						button: { icon: "fas fa-plus", label: "新增案件" },
						//function, link, route
						action: { type: 'function', function: this.showProjectCreatorModal },
						hide: this.editable
					},
					{
						//icon, label
						button: { icon: "fas fa-trash", label: "刪除案件" },
						//function, link, route
						action: { type: 'function', function: this.deleteNewProject },
						hide: this.editable
					},
				],
			},
			projectCreatorModal: {
				show: false,
				index: -1,
				formData: {},
				loading: true,
			},
			constructionSiteCreatorModal: {
				show: false,
				index: -1,
				formData: {},
				loading: true,
			},
		}
	},
	created() {
		this.getProjectDataType()
		this.getGroupNewAssignedProject()
	},
	destroyed() {
	},
	mounted() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	computed: {
		editable() {
			return this.checkPermission('edit') ? true : false
		},
		tableConfigs() {
			return {
				actionsColumn: true,
				rowDetail: true,
				perPage: 5,
			}
		},
		newProjects() {
			const params = {
				excavation_permit: {
					key: [ 'excavation_permit_name', 'new_project.excavation_permit_name', 'new_project.emergency_repair_number', 'new_project.work_number' ],
					strict: true
				},
				excavation_permit_name: [ 'excavation_permit_name', 'new_project.excavation_permit_name' ],
				emergency_repair_number: [ 'new_project.emergency_repair_number' ],
				work_number: [ 'new_project.work_number' ],
				address: [ 'address', 'new_project.address' ],
				construction_name: [ 'construction.name', 'new_project.construction_name' ],
				date: [ 'saved_at_time' ],
				received_at: {
					key: 'saved_at_time',
					value: (time) => {
						let datetime = new Date(time)
						let add = datetime > new Date(`${datetime.toDateString()} 16:00`) ? 1 : 0
						return datetime.addWorkDays(add).toDateString()
					}
				},
				assigned_at: {
					key: 'saved_at_time',
					value: (time) => {
						let datetime = new Date(time)
						let add = datetime > new Date(`${datetime.toDateString()} 16:00`) ? 1 : 0
						return datetime.addWorkDays(add + 1).toDateString()
					}
				},
				paved_at: [ 'paved_at' ],
				construction_id: [ 'construction.id', 'new_project.construction.id' ],
				project_group_id: [ 'project_group_id' ],
				pipeline_center_id: [ 'new_project.pipeline_center_id' ],
				party_a_id: [ 'construction.party_a.id', 'new_project.party_a_id' ],
				party_a_branch_id: [ 'construction.party_a_branch_id', 'new_project.construction.party_a_branch_id' ],
				supervisor_id: [ 'supervisor.id', 'new_project.supervisor.id' ],
				constructed_start_at: [ 'new_project.excavation_permit_approved_start_at' ],
				constructed_end_at: [ 'new_project.excavation_permit_approved_end_at' ],
			}
			return this.projects.map(project => {
				return Object.make(project, params)
			})
		},
		newSites() {
			const params = {
				paved: {
					key: 'paved_at',
					value: (value) => this.$store.getters.enum(`construction_site.paved.${value ? 'paved' : 'unknown'}`).index,
				},
				paved_at: [ 'paved_at' ],
				scene_pictures_url: [ 'scene_pictures_url' ],
			}
			return this.projects.map((project, index) => {
				let form = this.projectCreatorModal.index === index ? this.projectCreatorModal.formData : {}
				return Object.make({
					...project,
					...form,
				}, params)
			})
		},
	},
	methods:{
		reload() {
			this.$emit('reload')
		},
		checkPermission(action_key) {
			if(!this.group.id) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
		getGroupNewAssignedProject() {
			this.call.getGroupNewAssignedProject = false;
			this.$axios.getGroupNewAssignedProject(this.group.id, (response) => {
				this.projects = response.data
				this.call.getGroupNewAssignedProject = true;
			}, (error) => {
				alert(error)
				this.call.getGroupNewAssignedProject = true;
			})
		},
		getProjectDataType() {
			this.call.getProjectDataType = false
			this.$axios.getProjectDataType((response) => {
				this.projectDataTypes = response.data
				this.call.getProjectDataType = true
			},(error) => {
				this.call.getProjectDataType = true
			})
		},
		showProjectCreatorModal(index) {
			this.projectCreatorModal.show = true
			this.projectCreatorModal.loading = true
			if(index !== undefined) {
				this.projectCreatorModal.index = index
				this.projectCreatorModal.formData = this.newProjects[this.projectCreatorModal.index]
			}
			this.constructionSiteCreatorModal.show = false
		},
		getNewSite() {

		},
		showConstructionCreatorModal(e) {
			e.preventDefault();
			this.constructionSiteCreatorModal.loading = true
			let index = this.projectCreatorModal.index
			if(this.$refs.projectCreator.checkForm()) {
				if(this.constructionSiteCreatorModal.index !== index) {
					this.constructionSiteCreatorModal.index = index
					this.constructionSiteCreatorModal.formData = this.newSites[this.constructionSiteCreatorModal.index]
				}
				this.projectCreatorModal.show = false
				this.constructionSiteCreatorModal.show = true
			}
		},
		submit() {
			if(this.$refs.constructionSiteCreator.checkForm()) {
				this.createProjectAndConstructionSite({
					sync: this.$store.getters.enum('project.sync.new').index,
					...this.projectCreatorModal.formData,
					construction_sites: [ this.constructionSiteCreatorModal.formData ],
				})
			}
		},
		createProjectAndConstructionSite(data) {
			this.uploading = true
			this.$axios.createProjectAndConstructionSite(data, (response) => {
				alert(`新增成功`)
				console.log(response.data)
				this.uploading = false
				let new_project_id = response.data.id
				let new_project_type = response.data.project_type_index
				let group_id = response.data.project_group_id
				this.$router.push({name: '案件資訊', params: {groupID: group_id, group: this.group, projectID: new_project_id, projectType: new_project_type}});
			}, (error) => {
				alert(error)
				this.uploading = false
			})
		},
		deleteNewProject(index) {
			let project = this.projects[index]
			if(confirm(`是否確認刪除新派工案件：${project.excavation_permit_name}`)) {
				this.$axios.deleteGroupNewAssignedProject(this.group.id, {
					excavation_permit_name: project.excavation_permit_name,
				}, (response) => {
					alert("刪除成功")
					this.getGroupNewAssignedProject()
				})
			}
		},
	}
}
</script>

<style scoped>
</style>