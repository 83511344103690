<template>
	<loading v-if="loading"/>
	<div v-else>
		<vue-google-map ref="map" class="google-map" :center="map.center" :zoom="map.zoom" :address="map.address" :markers="map.markers" :configs="map.configs" :modes="map.modes" @addOverlay="addOverlay" @changeOverlay="changeOverlay" @geocoderAddress="zoomToAddress"></vue-google-map>
		<b-row class="mt-2 justify-content-between" v-if="mode !== 'view'">
			<div class="text-center flex-fill" v-if="mode === 'create' || mode === 'edit'" style="color: dimgrey">
				若需調整工地位置，請結束新增模式，並拖曳地圖圖標
			</div>
			<div v-if="mode === 'create'">
				<b-button type="button" class="form-btn clear-btn" @click="map.configs.drawing=!map.configs.drawing">{{map.configs.drawing ? "結束" : "繼續"}}新增</b-button>
				<b-button type="button" class="form-btn delete-btn" @click="clear">清除點位</b-button>
			</div>
		</b-row>
	</div>
</template>

<script>
import VueGoogleMap from '@/components/GoogleMap/VueGoogleMap'
import Loading from '@/components/Loading/Loading.vue'
import { oneOf, checkAllTrue, isEqual } from '@/utils/assist';

export default {
	name: 'ConstructionSiteViewer',
	components: {
		VueGoogleMap,
		Loading
	},
	props: {
		sites: {
			type: Array,
			default: () => { return [] }
		},
		address: {
			type: String,
			default: ''
		},
		images: {
			type: Array,
			default: () => []
		},
		mode: {
			type: String,
			validator (value) {
				return oneOf(value, [ 'view', 'create', 'edit', 'editData' ]);
			},
			default: 'view'
		},
		editSiteIndex: {
			type: Number,
			default: -1
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			position: null,
			map: {
				center: {// 預設經緯度在信義區附近
					lat: 25.0325917,
					lng: 121.5624999,
				},
				markers: [],
				overlays: [],
				zoom: 12,
				configs: {
					drawing: false,
					drawingMode:  "marker",
					drawingOptions: {
						markerOptions: {
							draggable: true,
							icon: {
								url: 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_blue1.png',
								scaledSize: { height: 40, width: 22 }
							}
						}
					},
					geocoding: true,
					searchingAddress: true
				},
				modes: { hideBussiness: true, hideMarkers: true, hideTransitions: true },
				address: '',
			},
			call: {},
			loading: true,
			newSites: [],
			uploading: false,
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
		uploading: {
			handler(value) {
				this.$emit('loading', this.uploading)
			}
		},
		mode: {
			immediate: true,
			handler(value) {
				switch(value) {
					case 'view':
						this.cancel()
						break;
					case 'create':
						this.startDrawingSites()
						break;
					case 'edit':
					case 'editData':
						this.startEditingSite(this.editSiteIndex, this.mode === 'edit')
						break;
				}
			}
		},
		newSites: {
			handler(newSites, oldValue) {
				if(this.mode === 'create') {
					this.$emit('add', newSites.map(site => {
						return {
							index: site.index,
							exists: site.exists,
							position: site.position,
						}
					}))
				}
			},
		},
		editSiteIndex: {
			handler(value) {
				this.startEditingSite(value, this.mode === 'edit')
			},
		},
	},
	created() {
		if(this.sites.length)
			this.markExistSites()
		else
			this.map.address = this.address
	},
	mounted() {
	},
	computed: {
		charIconUrl() {
			return (char='.', color='red') => {
				let reg = new RegExp('^([1-9]|[1-9][0-9]|[1][0-9][0-9]|100|[A-Z!@$+\\-=#%&.])$')
				const colors = {
					red: "red", black: "black", blue: "blue", green: "green", grey: "grey", orange: "orange", purple: "purple", white: "white", yellow: "yellow"
				}
				const baseUrl = 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/'
				const ext = '.png'
				return `${baseUrl}marker_${oneOf(color, Object.keys(colors)) ? colors[color] : 'red'}${reg.test(char) ? char : '.'}${ext}`
			}
		},
		iconUrl() {
			return (type) => {
				let typelist = ['red', 'orange', 'yellow', 'green', 'blue'] // red, black, blue, green, grey, orange, purple, white, yellow
				return this.charIconUrl(this.map.markers.length + 1, typelist[type])
			}
			// return `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red${this.map.markers.length + 1}.png`
		},
		newIconUrl() {
			return this.charIconUrl(this.newSiteIndex, 'purple')
			// return `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_blue${this.map.markers.length + (this.map.overlays.length > 0 ? this.map.overlays[this.map.overlays.length - 1].index + 1 : 0) + 1}.png`
		},
		newSiteIndex() {
			return this.sites.length + this.newSites.length + 1
			// return this.map.markers.length + (this.map.overlays.length > 0 ? this.map.overlays[this.map.overlays.length - 1].index + 1 : 0) + 1
		},
		existsNewSites() {
			return this.newSites.filter(site => site.exists)
		},
		editSite() {
			return this.sites[this.editSiteIndex]
		},
	},
	methods:{
		markExistSites() {
			this.map.markers.splice(0, this.map.markers.length)
			this.sites.forEach(site => {
				this.map.markers.push({
					position: site.position,
					icon: this.iconUrl(site.status_index),
					info: {
						工地名稱: site.name,
						座標: `${site.lat}°N ${site.lng}°E`,
						狀態: site.status
					}
					// info: `<img src="${this.data.data_list[0].content}" style="width:100%;"/>`
				})
			})
			this.updateDrawingMarkerIcon()
		},
		startDrawingSites() {
			// if(this.images.length <= 0) {
			// 	alert('無法新增工地，請先上傳案件設計圖！')
			// 	return
			// }
			this.map.configs.drawing = true
			if(this.sites.length <= 0 && this.position) {
				let index = this.newSiteIndex
				let overlay = {
					position: this.position,
					icon: this.newIconUrl,
					index: index,
					draggable: true,
					dragend: (event, marker) => {
						let site = this.newSites.find(s => s.index === marker.index);
						site.position.lat = event.latLng.lat();
						site.position.lng = event.latLng.lng();
					},
				}
				this.map.markers.push(overlay)
				this.addNewSite('markers', index, overlay.position)
			}
		},
		startEditingSite(index, draggable) {
			if(index < 0 || !this.map.markers[index]) return
			// this.map.markers[index].visible = false
			this.map.markers[index].icon = this.charIconUrl(index + 1, 'purple')
			if(draggable) {
				this.map.markers[index].draggable = true;
				this.map.markers[index].dragend = (event, marker) => {
					let site = this.sites[index]
					this.$emit('edit', {
						...site,
						lat: event.latLng.lat(),
						lng: event.latLng.lng(),
					})
				}
			}
			this.updateMap()
		},
		zoomToAddress(results, status) {
			if(this.map.markers.length >= 0 && status === 'OK') {
				let result = results[0]
				this.position = {
					lat: result.geometry.location.lat(),
					lng: result.geometry.location.lng()
				}
				this.resetMap()
				this.map.zoom = 18
			}
			else if(status === "ZERO_RESULTS") {
				alert("地址錯誤！無搜尋結果！")
			}
		},
		changeOverlay(event, marker) {
			let overlay = this.map.overlays.find(o => isEqual(marker, o.overlay))
			let site = this.newSites.find(s => s.index === overlay.index);
			site.position.lat = event.latLng.lat();
			site.position.lng = event.latLng.lng();
		},
		addOverlay(overlay) {
			let index = this.newSiteIndex
			overlay.index = index
			this.map.overlays.push({...overlay, index: index})
			this.addNewSite('overlays', index, overlay.overlay.position)
		},
		addNewSite(map, index, position) {
			this.newSites.push({
				map: map,
				index: index,
				exists: true,
				position: {
					lat: typeof position.lat === 'function' ? position.lat() : position.lat,
					lng: typeof position.lng === 'function' ? position.lng() : position.lng,
				},
			})
			this.updateDrawingMarkerIcon()
		},
		updateDrawingMarkerIcon() {
			this.map.configs.drawingOptions.markerOptions.icon.url = this.newIconUrl
		},
		deleteNewSite(index) {
			let site = this.newSites.find(site => site.index === index)
			site.exists = false
			let iconIndex = this.map[site.map].findIndex(icon => icon.index === site.index)
			if(site.map === 'overlays') {
				this.map[site.map][iconIndex].overlay.setMap(null)
			}
			else if(site.map === 'markers') {
				this.map[site.map].splice(iconIndex, 1)
			}
			this.updateMap()
			this.updateDrawingMarkerIcon()
		},
		clear() {
			this.map.overlays.forEach(overlay => overlay.overlay.setMap(null))
			this.map.overlays.splice(0, this.map.overlays.length)
			this.map.markers.splice(0, this.map.markers.length)
			this.newSites.splice(0, this.newSites.length)
			this.markExistSites()
			this.resetMap()
			this.updateMap()
			this.$emit('clear')
		},
		cancel() {
			this.map.configs.drawing = false
			this.clear()
			this.$emit('cancel')
		},
		resetMap() {
			let position = this.position ? this.position : (this.sites[0] ? this.sites[0].position : {lat: 25.0325917, lng: 121.5624999})
			if(this.map.center.lat === position.lat && this.map.center.lng === position.lng) {
				this.map.center = {...{lat: position.lat - 0.01, lng: position.lng - 0.01}}
				this.$nextTick(() => {
					this.map.center = position
				})
			}
			else {
				this.map.center = position
			}
		},
		updateMap() {
			if(this.$refs.map) {
				this.$refs.map.refresh()
			}
		},
	}
}
</script>

<style scoped>
.google-map {
	min-height: 300px;
	height: 400px;
}
</style>