<template>
	<b-row class="w-100 outside-wrapper" v-if="label">
		<label class="input-label col-md-2 col-12" :class="`${labelClass} ${required ? 'input-label-required' : ''}`">{{label}}</label>
		<span class="form-input-wrapper" :size="size">
			<date-range-picker class="input-text no-icon" v-model="model" :dateFormat="formatDate" :localeData="localeData" auto-apply control-container-class="" v-if="range" @update="input" append-to-body></date-range-picker>
			<date-picker class="input-text" v-model="model" :disabled="disabled" :state="state" v-else @input="input" @change="change"></date-picker>
			<button class="col-1 input-label link" v-show="button" @click="click"><i class="fa-fw" :class="button" aria-hidden="true"></i></button>
			<span class="input-background"></span>
		</span>
		<b-row class="w-100">
			<div class="col-md-2 col-12" :class="labelClass"></div>
			<div class="flex-fill text-hint font-size-sm text-left" :class="hintClass" v-if="hint">* {{hint}}</div>
		</b-row>
	</b-row>
	<b-row class="col-12 outside-wrapper" v-else >
		<label class="col-1 input-label" v-if="iconlabel"><i class="fa-fw" :class="iconlabel" aria-hidden="true"></i></label>
		<span class="form-input-wrapper" :size="size">
			<date-range-picker class="input-text no-icon" v-model="model" :dateFormat="formatDate" :localeData="localeData" auto-apply control-container-class="" v-if="range" @update="input" append-to-body></date-range-picker>
			<date-picker class="input-text" v-model="model" :disabled="disabled" :state="state" v-else @input="input" @change="change"></date-picker>
			<button class="col-1 input-label link" v-show="button" @click="click"><i class="fa-fw" :class="button" aria-hidden="true"></i></button>
			<span class="input-background"></span>
		</span>
		<b-row class="w-100">
			<div class="flex-fill text-hint font-size-sm text-left" :class="hintClass" v-if="hint">* {{hint}}</div>
		</b-row>
	</b-row>
</template>

<script>
// Import this component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// Import DateRangePicker
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
	name: 'InputDate',
	components: {
		datePicker,
		DateRangePicker,
	},
	props: {
		value: {
			type: [Object, String, Date, Array],
		},
		array: {
			type: Boolean,
			default: false
		},
		startKey: {
			type: String,
			default: 'startDate',
		},
		endKey: {
			type: String,
			default: 'endDate',
		},
		placeholder: {
			type: String,
			default: ""
		},
		size: {
			type: String,
			default: ""
		},
		label: {
			type: String,
		},
		labelClass: {
			type: String,
			default: ""
		},
		iconlabel: {
			type: String,
		},
		button: {
			type: String,
			default: ""
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		state: {
			type: Boolean,
			default: null
		},
		hint: {
			type: String,
			default: ""
		},
		hintClass: {
			type: String,
			default: ""
		},
		format: {
			type: String,
			default: 'yyyy-MM-dd',
		},
		range: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			model: null,
		}
	},
	created() {
		this.model = this.defaultValue
		if(this.model !== this.value) {
			this.input(this.model)
		}
	},
	watch: {
		value: {
			handler(value) {
				this.model = this.parseModelValue(value)
			}
		}
	},
	computed: {
		defaultValue() {
			return this.range ? {
				startDate: new Date(),
				endDate: new Date(),
			} : new Date()
		},
		localeData() {
			return {
				format: 'yyyy-mm-dd',
			}
		},
		keys() {
			return {
				startDate: this.array ? 0 : this.startKey,
				endDate: this.array ? 1 : this.endKey,
			}
		},
	},
	methods: {
		formatDate(classes, date) {
			return classes
		},
		parseModelValue(value) {
			if(this.range) {
				value = this.array ? [...(Array.isArray(value) ? value : [])] : { ...value }
				return Object.map(this.keys, k => new Date(value[k]))
			}
			return value
		},
		click(e) {
			e.preventDefault();
			let target = e.target;
			while(target.tagName.toLowerCase() == 'svg' || target.tagName.toLowerCase() == 'path' || target.tagName.toLowerCase() == 'i')
				target = target.parentNode;
			target.parentNode.querySelector('input').focus()
			target.parentNode.querySelector('input').click()
		},
		input(e) {
			this.$emit('input', this.parseValue(e))
		},
		change(e) {
			this.$emit('change', this.parseValue(e))
		},
		parseValue(value) {
			if(this.range) {
				value = Object.map(value, v => (new Date(v)).format(this.format))
				return this.array ? Object.values(value) : Object.mapKeys(value, (v, k) => this.keys[k])
			}
			return value
		}
	}
}
</script>

<style scoped>
.outside-wrapper {
	align-items: center;
}
.form-input-wrapper {
	padding: 0 1rem;
	border-radius: 1.5rem;
	height: 2.5rem;
	display: flex;
	align-items: center;
	flex: 1 1;
}
.form-input-wrapper[size="xs"] {
	border-radius: 0.5rem;
	height: 1.8rem;
}
.form-input-wrapper[size="xs"] .input-label {
	display: flex;
	align-items: center;
}
.form-input-wrapper[size="xs"] .input-label [data-icon="search"] {
	font-size: 0.8rem
}
/* 清除自動填字背景色 */
.input-text:-webkit-autofill,
.input-text:-webkit-autofill:hover,
.input-text:-webkit-autofill:focus,
.input-text:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
	-webkit-transition: background-color 5000s ease-in-out 0s;
	-moz-transition: background-color 5000s ease-in-out 0s;
	-o-transition: background-color 5000s ease-in-out 0s;
	-webkit-text-fill-color: #495057;
}
.form-input-wrapper[size="xs"] .input-text {
	height: 1rem;
	font-size: 0.9rem;
	padding: 0;
}
.input-text {
	border: none;
	z-index: 3;
	padding: .25rem;
	flex: 1 1;
}
.input-text:focus {
	border: none;
	box-shadow: none;
}
.input-text:focus ~ .input-label + .input-background {
	border: 1px solid #6b8ca9;
	box-shadow: 0 0 1px 1px #8ea9c230, 0 0 1px 1px #8ea9c230;
}
.input-text[state=false] + .input-label + .input-background,
.input-text.is-invalid + .input-label + .input-background {
	border: 1px solid #e79580;
}
.input-text[state=false]:focus ~ .input-label + .input-background,
.input-text.is-invalid:focus ~ .input-label + .input-background{
	border: 1px solid #d87b6a;
	box-shadow: 0 0 1px 1px #e7958030, 0 0 1px 1px #e7958030;
}
.input-text[disabled], .input-text.is-invalid, .input-text[state=false] {
	background: transparent;
	background-image: none;
}
.input-text[disabled] + .input-label + .input-background {
	background-color: #e9ecef;
	border: 1px solid #b8bcbb;
}
.input-background {
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 1.25rem;
	border: 1px solid #8ea9c2;
	width: 100%;
	height: 100%;
}
.input-label {
	background-color: transparent;
	border: none;
	z-index: 3;
	display: flex;
	align-items: center;
	padding-right: .5rem;
}
button.input-label {
	color: #6b8ca9;
}
label.input-label {
	overflow-wrap: break-word;
	word-wrap: break-word;
	white-space: normal;
}
label.input-label.input-label-required::after {
	content: '*';
	color: crimson;
}
</style>
