<template>
	<div v-if="!project.construction_sites.length">
		無工地資料，請新增工地
	</div>
	<div v-else>
		<loading mode="fixed" image="1" size="lg" v-if="loading" style="z-index:99999"/>
		<alert :errors="errors" v-if="errors.length"></alert>
		<button-group class="col-12" :buttons="buttons"></button-group>
		<project-result-gmap-viewer class="mt-3" :project="project" :position="position" :points="showPoints" v-model="showMap"></project-result-gmap-viewer>
		<!-- <input-checkbox :options="project.construction_sites" :keys="{value: 'id', text: 'name'}" v-model="showConstructionSiteIds"></input-checkbox>
		<input-checkbox options="包含其餘工地連接點" boolean v-model="acrossConstructionSites"></input-checkbox> -->
		<!-- <project-result-info class="mt-3" :project="project" :sites="sites"></project-result-info>
		<project-result-point-list class="mt-3" :project="project" :points="points"></project-result-point-list> -->
		<template v-if="editable">
			<div class="p-2 mt-3" v-for="(site, key) in project.construction_sites" :key="key">
				<hr />
				<b-row class="justify-content-between">
					<h5>工地{{site.id}}：{{site.name}}</h5>
					<router-link :to="{name: '編輯案件成果', params: {siteID: site.id}}" target="_blank">
						<i class="fa-fw fas fa-map-marked-alt"></i> 管線編輯工具
					</router-link>
				</b-row>
				<result-viewer :project="project" :site="site" :permission="permission" :project-data-type="projectDataType" v-if="measuredStatusIndexList.includes(site.status_index)" @reload="reload"></result-viewer>
				<div class="p-2" v-else-if="site.status_index === $store.getters.enum('construction_site.status.assigned').index">尚未上傳工地表單</div>
				<div class="p-2" v-else-if="site.status_index === $store.getters.enum('construction_site.status.unassigned').index">尚未指派</div>
			</div>
		</template>
	</div>
</template>

<script>
import ResultViewer from '@/components/Project/Result/ResultViewer'
import ProjectResultGmapViewer from '@/components/Project/Result/ProjectResultGmapViewer.vue'
import ProjectResultInfo from '@/components/Project/Result/ProjectResultInfo.vue'
import ProjectResultPointList from '@/components/Project/Result/ProjectResultPointList.vue'
import ButtonGroup from '@/components/Button/ButtonGroup'
import fileManager from '@/utils/file';
import Loading from '@/components/Loading/Loading.vue'
import InputCheckbox from '@/components/Input/InputCheckbox';
import Alert from '@/components/Alert/Alert.vue';
import { deepCopy, checkAllTrue } from '@/utils/assist';
import { GetPointTargetMaxAmount, IsFacilityType } from '@/gml/utils'

export default {
	name: "ProjectResultViewer",
	components: {
		ResultViewer,
		ProjectResultGmapViewer,
		ProjectResultInfo,
		ProjectResultPointList,
		ButtonGroup,
		Loading,
		InputCheckbox,
		Alert,
	},
	props: {
		project: {
			type: Object
		},
		permission: {
			type: Object
		},
		projectDataType: {
			type: Array
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: true,
			showConstructionSiteIds: [],
			acrossConstructionSites: true,
			errors: [],
			showMap: false,
			buttons: [
				{
					//icon, label
					button: { icon: "fas fa-map", label: "顯示地圖" },
					//function, link, route
					action: { type: 'function', function: () => this.showMap = true },
					visible: () => !this.showMap,
				},
				{
					//icon, label
					button: { icon: "far fa-map", label: "隱藏地圖" },
					//function, link, route
					action: { type: 'function', function: () => this.showMap = false },
					visible: () => this.showMap,
				},
				{
					//icon, label
					button: { icon: "fas fa-download", label: "下載點位清冊" },
					//function, link, route
					action: { type: 'dropdown', dropdown: [
						{
							//icon, label
							button: { label: "大地起伏表(QGIS專用)" },
							//function, link, route
							action: { type: 'function', function: this.downloadAllConstructionSitesPointsPositionCsvForQgis },
						},
						{
							//icon, label
							button: { label: "大地起伏表" },
							//function, link, route
							action: { type: 'function', function: this.downloadAllConstructionSitesPointsPositionCsv },
						},
						{
							//icon, label
							button: { label: "點位csv" },
							//function, link, route
							action: { type: 'function', function: this.downloadAllConstructionSitesPointsToOrthometricHeight },
						},
					]},
				},
				{
					//icon, label
					button: { icon: "fas fa-retweet", label: "轉換點位正高" },
					//function, link, route
					action: { type: 'function', function: this.transformMultiConstructionSitesOrthometricHeight },
				},
			],
		}
	},
	created() {
		this.init()
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	mounted() {
	},
	computed: {
		position() {
			return this.project.construction_sites.reduce((obj, site, index, sites) => {
				return Object.keys(site.position).reduce((pos, key) => {
					if(pos[key] === undefined) {
						pos[key] = 0;
					}
					pos[key] += site.position[key] / sites.length;
					return pos
				}, obj)
			}, {})
		},
		editable() {
			return this.checkPermission('edit') || this.checkPermission('write') ? true : false
		},
		measuredStatusIndexList() {
			return [
				'measured',
				'measurement_finished',
				'remeasured',
			].map(status => this.$store.getters.enum(`construction_site.status.${status}`).index)
		},
		allPoints() {
			return this.project.construction_sites.map(site => site.points).flat()
		},
		showConstructionSites() {
			return this.project.construction_sites.filter(site => this.showConstructionSiteIds.includes(site.id))
		},
		showPoints() {
			let points = this.showConstructionSites.map(site => site.points).flat()
			if(!this.checkPointCoordinates(points)) {
				points = points.filter(p => p.coordinate)
			}
			if(!this.acrossConstructionSites)
				return points
			let pointNames = points.map(point => point.name)
			let targetNames = points.map(point => point.targets).flat().filter(name => !pointNames.includes(name))
			return [
				...points,
				...this.allPoints.filter(point => targetNames.includes(point.name)).map(point => {
					return {
						...point,
						form_data: Object.keys(point.form_data).reduce((obj, key) => {
							if(!key.startsWith('連接點'))
								obj[key] = deepCopy(point.form_data[key])
							return obj
						}, {})
					}
				})
			]
		},
	},
	methods:{
		reload() {
			// this.$emit("reload")
			location.reload()
		},
		init() {
			this.showConstructionSiteIds = this.project.construction_sites.map(site => site.id)
			this.acrossConstructionSites = true
		},
		checkPointCoordinates(points) {
			let errorPoints = points.filter(p => !p.coordinate)
			if(errorPoints.length) {
				alert(`部分點位未取得測量座標，請重新上傳測量座標csv`);
				this.errors = [{
					title: `部分點位未取得測量座標，請重新上傳測量座標csv`,
					messages: errorPoints.map(p => p.name).join(', '),
				}]
			}
		},
		checkPermission(action_key) {
			if(!this.groupID) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
		transformMultiConstructionSitesOrthometricHeight() {
			this.$set(this.call, "transformMultiConstructionSitesOrthometricHeight", false)
			this.$axios.transformMultiConstructionSitesOrthometricHeight({
				construction_sites_id: this.project.construction_sites.map(site =>site.id)
			}, (response) => {
				alert(`${response.fail_count}個點位轉換失敗，共轉換${response.all_count}個點位。`);
				if(response.fail_count < response.all_count) {
					this.$emit("reload");
				}
				this.call.transformMultiConstructionSitesOrthometricHeight = true
			}, (error) => {
				// let errors = Array.isArray(error.response.data.errors) ? error.response.data.errors : Object.values(error.response.data.errors);
				// switch(error.response.status) {
				// 	case 422:
				// 		alert(`轉換失敗！資料錯誤：\n${errors.flat().join("\n")}`);
				// 		break;
				// 	default:
				// 		alert(error);
				// 		break;
				// }
				this.call.transformMultiConstructionSitesOrthometricHeight = true
			})
		},
		downloadAllConstructionSitesPointsPositionCsv(){
			let sites = this.project.construction_sites.filter(site => site.status_index > this.$store.getters.enum('construction_site.status.assigned').index)
			let points = sites.map(site => site.points).flat()

			let content = ["序號,點號,N,E,橢球高,大地起伏,正高,埋深,種類,地表高度,目標,目標2,目標3"]
			points.forEach( (pt, index, arr) => {
				let coordinate = this.getPointCoordinate(pt.coordinate)
				content.push([
					index + 1,
					pt.name,
					coordinate.twd97_y,
					coordinate.twd97_x,
					coordinate.ellipsoidal_height,
					coordinate.orthometric_height !== null ? (coordinate.ellipsoidal_height - coordinate.orthometric_height).toFixed(3) : '',
					coordinate.orthometric_height !== null ? coordinate.orthometric_height : '',
					...pt.transformed_data ? [
						pt.transformed_data.depth,
						pt.transformed_type.type,
						0,
						!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target1 : '',
						!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target2 : '',
						!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target3 : ''
					] : []
				].join(","))
			})
			fileManager.saveFile(`${this.project.excavation_permit}-${this.project.address}-大地起伏轉換`, "csv", content.join("\r\n"), {bom:true})
		},
		downloadAllConstructionSitesPointsPositionCsvForQgis() {
			let sites = this.project.construction_sites.filter(site => site.status_index > this.$store.getters.enum('construction_site.status.assigned').index)
			let allPoints = sites.map(site => site.points).flat()
			let points = allPoints.filter(point => point.point_name === point.name)

			let content = ["序號,點號,N,E,橢球高,大地起伏,正高,埋深,種類,地表高度,目標,目標2,目標3"]
			points.forEach( (pt, index, arr) => {
				let coordinate = this.getPointCoordinate(pt.coordinate)
				let targets = !IsFacilityType(pt.transformed_type.type) ? pt.targets.map(target => {
					let p = allPoints.find(p => p.name === target)
					return p ? p.point_name : ''
				}) : []
				content.push([
					index + 1,
					pt.name,
					coordinate.twd97_y,
					coordinate.twd97_x,
					coordinate.ellipsoidal_height,
					coordinate.orthometric_height !== null ? (coordinate.ellipsoidal_height - coordinate.orthometric_height).toFixed(3) : '',
					coordinate.orthometric_height !== null ? coordinate.orthometric_height : '',
					...pt.transformed_data ? [
						pt.transformed_data.depth,
						pt.transformed_type.type,
						0,
						...Object.values({
							...Object.mapKeys(Array(GetPointTargetMaxAmount()).fill(''), (v, i) => i),
							...Object.mapKeys(targets, (v, i) => i),
						})
					] : []
				].join(","))
			})
			fileManager.saveFile(`${this.project.excavation_permit}-${this.project.address}-大地起伏轉換(QGIS專用)`, "csv", content.join("\r\n"), {bom:true})
		},
		downloadAllConstructionSitesPointsToOrthometricHeight(){
			let sites = this.project.construction_sites.filter(site => site.status_index > this.$store.getters.enum('construction_site.status.assigned').index)	
			let content = []
			sites.map(site => { 
				site.points.forEach( (pt, index, arr) => {
					let coordinate = this.getPointCoordinate(pt.coordinate)
					content.push([
						pt.name,
						coordinate.twd97_y,
						coordinate.twd97_x,
						coordinate.ellipsoidal_height,
					].join(","))
				} )
			})
			return fileManager.saveFile(`${this.project.excavation_permit}-${this.project.address}-點位CSV`, "csv", content.join("\r\n"), {bom:true})
		},
		getPointCoordinate(coordinate) {
			return {
				twd97_y : coordinate ? coordinate.twd97_y : 0,
				twd97_x : coordinate ? coordinate.twd97_x : 0,
				ellipsoidal_height : coordinate ? coordinate.ellipsoidal_height : 0,
				orthometric_height : coordinate ? coordinate.orthometric_height : 0,
			}
		},
	}
}
</script>

<style scoped>
.google-map {
	min-height: 500px;
	height: 500px;
}
</style>