import Feature from './Feature'

/**
 * 設施物-點
 * @extends Feature 
 */
class Point extends Feature {
	/**
	 * @param {Node} node
	 */
	constructor(collection, node) {
		/**
		 * @var {Type} type 
		 * @var {Info} info 
		 */
		super(collection, node.type, node.info)

		/**
		 * @var {Node} node 
		 */
		this.node = node
	}

	/**
	 * 此點座標
	 * @returns {Position}
	 */
	getPosition() {
		return this.node.position
	}

	/**
	 * 依keys取得此點座標值
	 * @param {String[]} keys 
	 * @param {String} delim 
	 * @returns {String}
	 */
	getPositionByKey(keys=[], delim=' ') {
		return this.node.position.getPositionByKey(keys, delim)
	}

	getData() {
		return {
			...super.getData(),
			...this.node.data,
			...this.node.position.get()
		}
	}
	getName() {
		return this.node.toString()
	}
	getNumber() {
		return this.node.getNumber()
	}

	/**
	 * @returns {AdditionalInfo}
	 */
	getAdditionalInfo() {
		return this.node.additionalInfo
	}

	toString() {
		return `${this.type.getTypeKey()}: ${this.getName()}`;
	}

	get() {
		return {
			...super.get(),
			coordinate: this.getPosition().get(),
			targets: Object.values(Object.map(this.node.targetNodes, node => node.get(false))),
		}
	}
}

export default Point
