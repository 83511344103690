import Constants from './Constants'
import Position from './Position'
import Type from './Type'
import Info from './Info'
import Node from './Node'
import NodeCollection from './NodeCollection'
import GmlConverter from './GmlConverter'
import { TransformPointData, GetAllUtilityCategoryKeys, GetPointInfos, CheckPointTargetInfos } from './utils'
import { resetObject } from '@/utils/assist'

class GmlGenerator {
	/**
	 * @param {Object} project 
	 * @param {Array} points 
	 */
	constructor(project, points=[], ignoreUnpairedNodes=false) {
		this.exceptions = [];
		try {
			this.ignoreUnpairedNodes = ignoreUnpairedNodes
			Node.resetCounter()
			this.data = {}
			this.featureData = {}
			this.setProjectData(project)
			if(!this.#checkProjectData()) {
				let lost = Object.filter(Constants.DATA.PROJECT_KEYS_TABLE, (setting, key) => setting.required && this.data[key] === undefined)
				throw new Error(`案件資料不完整: ${Object.values(lost).mapValues('text').uniq().join(', ')}`)
			}
			
			/**
			 * @var {NodeCollection}
			 */
			this.nodes = new NodeCollection(this);
			this.addNodes(points);
		} catch (error) {
			// alert(error.message);
			console.error(error.message);
			this.exceptions.push(error.message);
		}
	}

	/**
	 * 初始化data
	 */
	setProjectData(project) {
		let data = resetObject(project, Constants.DATA.PROJECT_KEYS_TABLE)
		this.data = { ...this.#getConstantValues(Constants.DATA.PROJECT_DATA, data), ...data }

		let featureData = this.#getConstantValues(Constants.DATA.POINT_DATA, this.data)
		this.featureData = Object.keys(featureData).reduce((obj, key) => {
			let value = featureData[key]
			if(value !== undefined) {
				Object.keys(value).forEach(type => {
					(type === 'DEFAULT' ? GetAllUtilityCategoryKeys() : [type]).forEach(t => {
						obj[t] = obj[t] ?? {}
						obj[t][key] = value[type]
					})
				})
			}
			return obj
		}, {})
	}

	/**
	 * 檢查案件資料是否完整
	 * @returns bool
	 */
	#checkProjectData = () => {
		return Object.keys(Constants.DATA.PROJECT_KEYS_TABLE).filter(key => Constants.DATA.PROJECT_KEYS_TABLE[key].required).every(key => this.data[key] !== undefined)
	}

	/**
	 * #private 由資料key對應表取值
	 * @param {Object} configs 
	 * @param {Object} data 
	 * @returns {Object}
	 */
	#getConstantValues = (configs, data) => {
		return Object.keys(configs).reduce((obj, param_key) => {
			let param = configs[param_key]
			let key = param.KEYS.map(k => data[k])
			let value = undefined
			while(value === undefined && key.length) {
				value = param.VALUES[key.join("|")]
				if(value === undefined)
					key.pop();
			}
			obj[param_key.toCamelCase()] = value === undefined ? param.VALUES.DEFAULT : value;
			return obj
		}, {})
	}

	/**
	 * 新增多個測量點
	 * @param {Array} nodes 
	 */
	addNodes(nodes) {
		nodes.forEach(pt => this.addNode(pt));
		this.nodes.checkUnpairedNodes(!this.ignoreUnpairedNodes);
		return this
	}

	/**
	 * 新增測量點
	 * @param {Object} pt
	 */
	addNode(pt) {
		pt = TransformPointData(pt)
		let type = new Type(pt.type, pt.feature_type, pt.feature_category ? pt.feature_category : pt.other_facility_type ? pt.other_facility_type : undefined)
		// 若非可用type，則忽略
		if(!type.getTypeKey())
			return this
		let data = {...this.featureData[type.getTypeKey()], ...pt}
		CheckPointTargetInfos(pt, data.pointInfoFormat, true)
		let position = new Position(pt.X, pt.Y, pt.ellipsoidal_height, pt.orthometric_height, pt.depth)
		GetPointInfos(pt).uniq(true).forEach(pointInfo => {
			let info = Info.newByObject(pointInfo)
			let pipelineType = type.copy()
			if(pointInfo.pipelineType) { pipelineType.formType = pointInfo.pipelineType }
			let node = new Node(this.nodes, pt.name, position, pipelineType, info, data)
			this.nodes.push(node)
		})
		return this
	}

	/**
	 * 檢查資料是否正確
	 * @returns {Boolean}
	 */
	checkValid() {
		if(this.exceptions.length) {
			alert(`GML資料計算錯誤：\n${this.exceptions.join("\n")}`);
			console.error(`GML資料計算錯誤：\n${this.exceptions.join("\n")}`);
			return false;
		}
		return true;
	}

	/**
	 * @returns {FeatureCollection}
	 */
	getFeatures() {
		return this.nodes.getFeatures()
	}

	getGmlUtilities() {
		let utilities = []
		try {
			let converter = new GmlConverter(this.data, this.getFeatures())
			let version = converter.getGmlVersions().first
			utilities = converter.getGmlUtilities(version)
		} catch (error) {
			console.error(error);
			alert(error.message)
		}
		return utilities.map(utility => utility.get())
	}

	/**
	 * 取得GML
	 */
	getGml() {
		let gml = {};
		try {
			let converter = new GmlConverter(this.data, this.getFeatures())
			let versions = converter.getGmlVersions()
			gml = versions.reduce((obj, version_key) => {
				let version = Constants.GML.VERSIONS[version_key]
				let utilities = converter.getGmlUtilities(version_key)
				console.log('PIPELINE CONNECTION:', utilities.map(utility => utility.toString()).join("\n"))
				if(version) {
					obj[version.key] = {
						...converter.getGml(version.key),
						version: version
					}
				}
				return obj
			}, {})
		} catch (error) {
			console.error(error);
			alert(error.message)
		}
		return gml;
	}

	/**
	 * 取得管線總長
	 * @param {int} fixed 四捨五入位數
	 * @returns {Number}
	 */
	getPipelineLength(fixed=2) {
		let features = this.getFeatures();
		let length = features.reduce((sum, feature) => {
			return sum + feature.getLength();
		}, 0);
		return Number(length.toFixed(fixed));
	}

	/**
	 * 取得管線總長
	 * @param {int} fixed 四捨五入位數
	 * @returns {Number}
	 */
	getPipelineLengthByInfo(checkMaterial=false, checkPipeBundle=false, fixed=2) {
		return Object.filter(Object.map(this.getFeatures().groupBy(feature => feature.type.getType('countText')), features => {
			return Object.filter(Object.map(features.groupBy(feature => {
				return [
					checkMaterial ? feature.info.material : '',
					feature.info.getPipeCalibreString(),
					checkPipeBundle ? feature.info.getPipeBundleString() : '',
				].filter(o => o).join(' ').trim()
			}), features => Number(features.sumBy(feature => feature.getLength()).toFixed(fixed))), o => o)
		}), o => !Object.isEmpty(o))
	}
}

export default GmlGenerator
