<template>
	<b-row>
		<loading mode="fixed" image="1" size="lg" v-if="loading"/>
		<div class="col-12 p-1" :class="{'col-md-6': controlPointDataViewer.show}">
			<b-card>
				<div class="card-wrapper">
					<div class="row justify-content-between">
						<h5>控制點列表</h5>
						<button-group :buttons="buttons" button-class="px-1" ></button-group>
					</div>
					<bv-table :columns="controlPointTable.columns" :data="controlPoints" :detailColumns="controlPointTable.detailColumns" :configs="controlPointTable.configs" :actions="controlPointTable.actions"></bv-table>
				</div>
			</b-card>
		</div>
		<div class="col-md-6 col-12 p-1" v-show="controlPointDataViewer.show">
			<b-card>
				<loading v-if="searching"/>
				<template v-else-if="controlPointDataViewer.show !== null && viewingControlPoint">
					<div class="card-wrapper">
						<b-card class="border-0 p-1" no-body>
							<b-card-body class="p-1">
								<h5>控制點測量成果</h5>
								<h6>
									<template v-if="$store.getters.isDeveloper">[{{viewingControlPoint.id}}]</template>
									控制點編號：{{viewingControlPoint.number}}
								</h6>
								參考座標: 
								N: {{viewingControlPoint.coordinate.twd97_y}},
								E: {{viewingControlPoint.coordinate.twd97_x}},
								H: {{viewingControlPoint.coordinate.ellipsoidal_height}}
							</b-card-body>
						</b-card>
					</div>
					<hr>
					<bv-table :columns="controlPointDataTable.columns" :data="viewingControlPoint.data" :detailColumns="controlPointDataTableDetailColumns" :configs="controlPointDataTable.configs" :actions="controlPointDataTable.actions"></bv-table>
				</template>
			</b-card>
		</div>
		<modal centered v-model="resultListDownloadModal.show" title="下載成果清冊" size="lg" @ok="downloadResultList">
			<div style="min-height: 50vh">
				<input-date v-model="resultListDownloadModal.data.created_at" range button="fas fa-calendar" array></input-date>
			</div>
		</modal>
		<modal v-model="pointStatusUpdateModal.show" centered @ok="updateControlPointStatus">
			是否確定更新控制點狀態：{{pointStatusUpdateModal.message}}
		</modal>
	</b-row>
</template>

<script>
import VueGoogleMap from '@/components/GoogleMap/VueGoogleMap'
import VueGoogleMapWithLayer from '@/components/GoogleMap/VueGoogleMapWithLayer'
import BvTable from '@/components/Table/BvTable'
import ButtonGroup from '@/components/Button/ButtonGroup'
import Modal from '@/components/Modal/Modal'
import InputDate from '@/components/Input/InputDate.vue'
import Loading from '@/components/Loading/Loading.vue'
import { deepCopy, isEmpty, oneOf, checkAllTrue, sortArray } from '@/utils/assist';
import dynamicFormHelper from '@/utils/dynamicForm';
import fileManager from '@/utils/file';

export default {
	name: 'ControlPointViewer',
	components:{
		VueGoogleMap,
		VueGoogleMapWithLayer,
		BvTable,
		ButtonGroup,
		Modal,
		InputDate,
		Loading
	},
	data() {
		return {
			loading: true,
			searching: false,
			call: { getControlPointList: false, },
			map: {
				center: {// 預設經緯度在信義區附近
					lat: 25.0325917,
					lng: 121.5624999,
				},
				layers: [],
				markers: [],
				zoom: 12,
				configs: {
					drawing: false,
					drawingMode:  "",
				},
				modes: {
					hideBussiness: true,
					hideMarkers: true,
					hideTransitions: true
				},
				overlays: [],
			},
			buttons: [
				{
					//icon, label
					button: { icon: "fas fa-download", label: "下載成果清冊" },
					//function, link, route
					action: { type: 'function', function: () => this.resultListDownloadModal.show = true },
				},
			],
			controlPoints: [],
			controlPointTable: {
				detailColumns: [
					{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper },
					{ key: 'number', label: '編號' },
					{ key: 'name', label: '點名' },
					{ key: 'category', label: '種類' },
					{ key: 'city_district', label: '行政區' },
					{ key: 'data_count', label: '資料筆數' },
					{ key: 'last_data_created_at_time', label: '最新上傳時間' },
				],
				columns: [
					{ key: 'id', label: 'ID', sortable: true, searchable: true, deep: true, hide: !this.$store.getters.isDeveloper },
					{ key: 'number', label: '編號', sortable: true, searchable: true, deep: true },
					{ key: 'name', label: '點名', sortable: true, searchable: true, deep: true },
					{ key: 'category', label: '種類', sortable: true, searchable: true, deep: true },
					{ key: 'city_district', label: '行政區', sortable: true, searchable: true, deep: true },
					{ key: 'data_count', label: '資料筆數', sortable: true, searchable: true, deep: true },
					{ key: 'last_data_created_at_time', label: '最新上傳時間', sortable: true, searchable: true, deep: true },
				],
				data: [],
				actions: [
					{
						//icon, label
						button: { type: 'icon', icon: "fas fa-file", label: '檔案', class: "m-0", style: "color: #094c73" },
						//function, link, route
						action: { type: 'function', function: this.downloadReferenceData },
						hide: (index) => !this.controlPoints[index].data_url
					},
					{
						//icon, label
						button: { type: 'icon', icon: "fas fa-eye", label: '檢視', class: "m-0", style: "color: #094c73" },
						//function, link, route
						action: { type: 'function', function: this.viewPoint },
					},
					...this.$store.getters.enum('point.control_point_status').map(status => {
						let normal = status.key === 'normal'
						return {
							//icon, label
							button: { type: 'icon', icon: `fas fa-${normal ? 'check' : 'times'}-circle`, label: normal ? '復原' : status.text, class: {'text-danger': !normal, 'text-success': normal} },
							//function, link, route
							action: { type: 'function', function: (index) => this.showPointStatusUpdateModal(index, status) },
							hide: (index) => !this.editable || this.controlPoints[index].status_index === status.index
						}
					}),
				],
				configs: {
					rowDetail: true,
					actionsColumn: true,
					perPage: 10,
					showStatistic: true
				}
			},
			controlPointDataViewer: {
				show: false,
				controlPointIndex: null,
				controlPointData: {},
			},
			controlPointDataTable: {
				detailColumns: [
					{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper },
					{ key: 'control_point_id', label: '控制點ID' },
					{ key: 'created_at_time', label: '上傳時間' },
					{ key: 'user_names', label: '測量人員' },
				],
				columns: [
					{ key: 'id', label: 'ID', sortable: true, searchable: true, deep: true, hide: !this.$store.getters.isDeveloper },
					{ key: 'instrument', label: '儀器', sortable: true, searchable: true, deep: true },
					{ key: 'user_names', label: '測量人員', sortable: true, searchable: true, deep: true },
					{ key: 'created_at_time', label: '上傳時間', sortable: true, searchable: true, deep: true },
				],
				actions: [],
				configs: {
					rowDetail: true,
					perPage: 7,
					showStatistic: true
				}
			},
			resultListDownloadModal: {
				show: false,
				data: { created_at: [], },
			},
			pointStatusUpdateModal: {
				show: false,
				point: undefined,
				data: {},
			},
		}
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
	},
	created() {
		this.getControlPointList()
	},
	mounted() {
	},
	computed: {
		editable() {
			return this.$store.getters.hasPermissions('system', 'edit')
		},
		viewingControlPoint() {
			return this.controlPointDataViewer.show && this.controlPoints[this.controlPointDataViewer.controlPointIndex] ? {
				...this.controlPoints[this.controlPointDataViewer.controlPointIndex],
				titles: this.controlPointDataViewer.controlPointData[this.controlPointDataViewer.controlPointIndex].titles,
				data: this.controlPointDataViewer.controlPointData[this.controlPointDataViewer.controlPointIndex].data,
			} : undefined
		},
		controlPointDataTableDetailColumns() {
			const coordinateKeys = [ 'X', 'Y', '平面', '高程' ];
			return [
				...this.controlPointDataTable.detailColumns,
				...coordinateKeys.map(key => {
					return { key: `coordinate_offset.${key}`, label: `${key}差值`, deep: true }
				}),
				...(this.viewingControlPoint.titles ?? []).map(title => {
					return { key: `modified_form_data.${title.key}`, label: title.key, type: title.type, deep: true }
				}),
			]
		},
	},
	methods:{
		getControlPointList() {
			this.$set(this.call, 'getControlPointList', false)
			this.$axios.getControlPointList({}, (response) => {
				this.controlPoints = response.data.map(point => {
					return {
						...point,
						city_district: `${point.city}${point.district}`,
					}
				}).sortBy([ (o) => o.last_data_created_at_time ?? '' ], [ 'desc' ])
				this.call.getControlPointList = true
			}, (error) => {
				this.call.getControlPointList = true
			})
		},
		getControlPointData(controlPointId) {
			this.$set(this.call, 'getControlPointData', false)
			this.searching = true
			this.$axios.getControlPointList({
				ids: [controlPointId],
				less: 0,
			}, (response) => {
				response.data.forEach(point => {
					let controlPoint = this.controlPoints.find(p => p.id === point.id)
					this.controlPointDataViewer.controlPointData[this.controlPointDataViewer.controlPointIndex].titles = response.titles
					this.controlPointDataViewer.controlPointData[this.controlPointDataViewer.controlPointIndex].data = point.data.map(datum => {
						let offset = {
							x: (controlPoint.coordinate.twd97_x - (datum.modified_form_data.測量座標_實測E ?? (datum.modified_form_data.測量座標_E ?? 0))),
							y: (controlPoint.coordinate.twd97_y - (datum.modified_form_data.測量座標_實測N ?? (datum.modified_form_data.測量座標_N ?? 0))),
							z: (controlPoint.coordinate.ellipsoidal_height - (datum.modified_form_data.測量座標_實測H ?? (datum.modified_form_data.測量座標_H ?? 0))),
						}
						return {
							...datum,
							user_names: datum.users.map(u => u.name).join('、'),
							instrument: `${datum.modified_form_data.儀器_經緯儀 ?? ''}${datum.modified_form_data.儀器_衛星定位儀 ?? ''}`,
							form_data: dynamicFormHelper.decodeFormData(response.titles, datum.modified_form_data),
							coordinate_offset: {
								X: offset.x.toFixed(3),
								Y: offset.y.toFixed(3),
								平面: Math.sqrt(Math.pow(offset.x, 2) + Math.pow(offset.y, 2)).toFixed(3),
								高程: offset.z.toFixed(3),
							},
						}
					})
				})
				this.call.getControlPointData = true
				this.searching = false
			}, (error) => {
				this.call.getControlPointData = true
				this.searching = false
			})
		},
		zoomMap(center, zoom) {
			this.map.center = center;
			this.map.zoom = zoom;
		},
		viewPoint(index) {
			let controlPointId = this.controlPoints[index].id
			this.getControlPointData(controlPointId)
			this.controlPointDataViewer.show = true;
			this.controlPointDataViewer.controlPointIndex = index;
			this.$set(this.controlPointDataViewer.controlPointData, index, {
				titles: undefined,
				data: undefined,
			})
		},
		downloadReferenceData(index) {
			let controlPoint = this.controlPoints[index]
			fileManager.saveFileFromUrl(controlPoint.data_url, true);
		},
		downloadResultList() {
			this.$set(this.call, 'exportControlPointDataList', false)
			let data = this.resultListDownloadModal.data
			let dates = this.resultListDownloadModal.data.created_at
			this.$axios.exportControlPointDataList(data, (response) => {
				let filename = `控制點成果清冊${dates.join('_')}`
				fileManager.saveFile(filename, 'csv', response)
				this.call.exportControlPointDataList = true
			}, (error) => {
				this.call.exportControlPointDataList = true
			})
		},
		showPointStatusUpdateModal(index, status) {
			let point = this.controlPoints[index]
			console.log(index, point)
			this.pointStatusUpdateModal.show = true
			this.pointStatusUpdateModal.message = `${point.number} ${point.name ?? ''}`
			this.pointStatusUpdateModal.point = point
			this.pointStatusUpdateModal.data = {
				status: status.index
			}
		},
		updateControlPointStatus() {
			this.$set(this.call, 'updateControlPointStatus', false)
			this.$axios.updateControlPoint(this.pointStatusUpdateModal.point.id, this.pointStatusUpdateModal.data, (response) => {
				alert('修改成功');
				this.call.updateControlPointStatus = true
				location.reload();
			}, (error) => {
				this.call.updateControlPointStatus = true
			})
		},
	}
}
</script>

<style scoped>
.card-wrapper {
	padding: .5rem;
}
.card {
	height: 100%;
}
.assignment-card {
	height: calc(100vh - 150px);
	min-height: 666px;
}
.google-map {
	min-height: 400px;
	height: 100%;
}
.tab-body {
	padding: .5rem 1rem;
	border: 1px solid #dee2e6;
	border-top: 0;
	height: 100%;
}
.tabs-wrapper {
	height: 100%;
}
.tabs-wrapper > .tab-content {
	height: 100%;
}
.table-responsive {
	margin: 0;
}
.action-btn {
	padding: .25rem .75rem;
	border-radius: .5rem;
	background: #b2c8d8aa;
	color: #050b10cc;
	border-color: #b2c8d8;
}
.action-btn:hover {
	text-decoration: none;
	background: #8ea9c2cc;
	color: #050b10;
}
.action-btn:focus {
	box-shadow: none !important;
}
</style>