import http from './reddit'
import store from '@/store'
import { objectToFormData } from '@/utils/formData'
import router from '@/router'

/** 參數轉為query
 * @param {Object} params 
 */
let queryParams = (params, parent_key=undefined) => {
	let query = []
	for(let key in params) {
		let value = params[key];
		key = parent_key ? `${parent_key}[${key}]` : key;
		if(typeof value === 'object')
			query = query.concat(queryParams(value, key))
		else
			query.push(`${key}=${value}`)
	}
	return query.length > 0 ? `${[...(new Set(query))].join('&')}` : ''
}
let getQueryString = (params) => {
	return params ? `?${queryParams(params)}` : '';
}
/** 依key查表
 * @param {String} enum_key 
 * @param {String} key 
 */
let findEnum = (enum_key, key) => {
	let k = enum_key.split('_')
	k.pop();
	return [k.join("_"), key && key.includes('.') ? store.getters.enum(key) : undefined]
}
/** get enum value from key
 * @param {String} enum_key 
 * @param {String} key 
 */
let getEnum = (enum_key, key) => {
	// let k = enum_key.split('_')
	// k.pop();
	// let item = key.includes('.') ? store.getters.enum(key) : undefined
	// k = k.join("_")
	let [k, item] = findEnum(enum_key, key)
	if(!item)
		return null
	let obj = {}
	obj[k] = item ? item.text : ''
	obj[`${k}_index`] = item ? item.index : -1
	obj[`${k}_enum`] = key
	obj[`${k}_key`] = item ? item.key : ''
	obj[`${k}_data`] = item ? item.data : {}
	return obj
}
let checkArrayData = (array, func=null) => {
	let errors = [];
	return {
		data: array.map((data) => {
			data = checkData(data, func);
			errors = [...errors, ...data.errors]
			return data.data;
		}), errors: errors
	}
}
let checkData = (data, func=null) => {
	let errors = [];
	if(data) {
		for(let key in data) {
			if(key.endsWith('_key')) {
				let item = getEnum(key, data[key])
				if(data[key] && !item)
					errors.push(`${key} - ${data[key]}`);
				else
					data = { ...data, ...item }
			}
			else if(key.endsWith('_at') && data[key]) {
				data[`${key}_time`] = new Date(data[key]).format('yyyy-MM-dd hh:mm:ss')
				data[key] = new Date(data[key]).format('yyyy-MM-dd')
			}
			else if(Array.isArray(data[key])) {
				let temp = checkArrayData(data[key])
				data[key] = temp.data
				errors = [...errors, ...temp.errors]
			}
			else if(typeof data[key] === 'object') {
				let temp = checkData(data[key]);
				data[key] = temp.data
				errors = [...errors, ...temp.errors]
			}
		}
		if(data.lat && data.lng) {
			data.position = { lat: data.lat, lng: data.lng }
		}
		if(func!==null) {
			func(data)
		}
}
	return {data: data, errors: errors};
}

/** 排序Array
 * @param {Array} arr 
 * @param {String} key 排序依據
 * @param {Boolean} reverse 
 */
let sortArray = (arr, key=null, reverse=1) => {
	arr.sort(function (a, b) {
		let swap;
		if(typeof a !== "object" || typeof b !== "object")
			swap = a > b ? 1 : -1;
		else
			swap = a[key] > b[key] ? 1 : -1;
		return reverse * swap;
	});
}
/** Object to FormData
 * @param {Object} data 
 * @return {FormData}
 */
let toFormData = (data) => {
	return objectToFormData(data, {indices: true})
}

let onThen = (response, then=(()=>{})) => {
	return then(response.data, response.status, response.headers);
}

let getAuth = () => {
	return {
		headers: {
			'Authorization': `Bearer ${store.getters.currentUser ? store.getters.currentUser.access_token : ''}`
		}
	}
}

let checkAuthError = (error) => {
	// Unauthorized
	if(error.response.status === 401) {
		store.dispatch('setLogout');
		let path = router.history.current.path
		router.push({
			name: '登入',
			query: { redirect: path }
		});
		// alert('登入逾時，請重新登入')
		return true
	}
	return false
}
let showErrorMessage = (error) => {
	let msg = ''
	if(error.response.status === 500) {
		msg = '系統錯誤'
	}
	else {
		msg = error.response && error.response.data && (error.response.data.message || error.response.data.errors) ? `${error.response.data.message}\r\n${Object.values(error.response.data.errors).join("\r\n")}` : (error.message ? error.message : error)
	}
	alert(msg);
}

let httpGet = (url, success=(()=>{}), failed=(()=>{}), withAuth=true, checkAuth=true) => {
	return new Promise((resolve, reject) => {
		http.Get(url, withAuth ? getAuth() : undefined).then((response) => {
			onThen(response, (response, status, headers) => {
				success(response, status, headers)
				resolve(response, status, headers)
			});
		}).catch((error) => {
			console.error(error)
			if(!(checkAuth && checkAuthError(error))){
				showErrorMessage(error)
			}
			failed(error)
			reject(error)
		})
	})
}
let httpPost = (url, data, success=(()=>{}), failed=(()=>{}), configs={}, withAuth=true, checkAuth=true) => {
	if(withAuth) {
		configs = {
			...configs,
			...getAuth()
		};
	}
	return new Promise((resolve, reject) => {
		http.Post(url, data, configs).then((response) => {
			onThen(response, (response, status, headers) => {
				if(typeof response.errors !== 'undefined') {
					alert(response.message)
					console.error(response.errors)
					failed(response, status, headers)
					reject(response, status, headers)
				}
				else {
					success(response, status, headers)
					resolve(response, status, headers)
				}
			});
		}).catch((error) => {
			console.error(error)
			if(!(checkAuth && checkAuthError(error)))
				showErrorMessage(error)
			failed(error)
			reject(error)
		})
	})
}
let httpDelete = (url, success=(()=>{}), failed=(()=>{}), checkAuth=true) => {
	return new Promise((resolve, reject) => {
		http.Delete(url, getAuth()).then((response) => {
			onThen(response, (response, status, headers) => {
				if(typeof response.errors !== 'undefined') {
					alert(response.message)
					console.error(response.errors)
					failed(response, status, headers)
					reject(response, status, headers)
				}
				else {
					success(response, status, headers)
					resolve(response, status, headers)
				}
			});
		})
		.catch((error) => {
			console.error(error)
			if(!(checkAuth && checkAuthError(error)))
				showErrorMessage(error)
			failed(error)
			reject(error)
		})
	})
}


export default {
	// 登入
	login: (user, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/login', user, success, failed, {}, false);
	},
	// 登出
	logout: (success=(()=>{}), failed=(()=>{})) => {
		return httpGet('/api/v1/logout', success, failed, true, false);
	},
	// 編號中文對照
	getTable: (success=(()=>{}), failed=(()=>{})) => {
		return httpGet('/api/v1/table', success, failed);
	},
	/** 案件清單
	 * @param {Object} params 篩選: ids[]
	 */
	getProjectList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/project${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data, (data) => {
				data.excavation_permit_name = data.excavation_permit_name ? data.excavation_permit_name : '無路證'
				data.excavation_permit = (data.excavation_permit_name && data.emergency_repair_number ? `${data.excavation_permit_name} (${data.emergency_repair_number})` : data.excavation_permit_name) + (data.work_number ? ` (${data.work_number})` : '')
				data.bid_full_name = data.bid_name
				data.bid_name = data.bid_abbrev ? data.bid_abbrev : data.bid_name
				data.construction_full_name = data.construction_name
				data.construction_name = data.construction_abbrev ? data.construction_abbrev : data.construction_name
			})
			response.data = data.data;
			if(data.errors.length)
				alert(`案件清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** CMS案件清單
	 * @param {Object} params 篩選: ids[]
	 */
	getCmsProjectList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/cms/project${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data, (data) => {
				data.excavation_permit_name = data.excavation_permit_name ? data.excavation_permit_name : '無路證'
				data.excavation_permit = (data.excavation_permit_name && data.emergency_repair_number ? `${data.excavation_permit_name} (${data.emergency_repair_number})` : data.excavation_permit_name) + (data.work_number ? ` (${data.work_number})` : '')
				// data.bid_full_name = data.bid_name
				// data.bid_name = data.bid_abbrev ? data.bid_abbrev : data.bid_name
				// data.construction_full_name = data.construction_name
				// data.construction_name = data.construction_abbrev ? data.construction_abbrev : data.construction_name
			})
			response.data = data.data;
			if(data.errors.length)
				alert(`案件清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 相似案件清單
	 * @param {*} params 
	 * @returns 
	 */
	getSimilarProjectList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/similar/project${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data, (data) => {
				data.excavation_permit_name = data.excavation_permit_name ? data.excavation_permit_name : '無路證'
				data.excavation_permit = (data.excavation_permit_name && data.emergency_repair_number ? `${data.excavation_permit_name} (${data.emergency_repair_number})` : data.excavation_permit_name) + (data.work_number ? ` (${data.work_number})` : '')
				// data.bid_full_name = data.bid_name
				// data.bid_name = data.bid_abbrev ? data.bid_abbrev : data.bid_name
				// data.construction_full_name = data.construction_name
				// data.construction_name = data.construction_abbrev ? data.construction_abbrev : data.construction_name
			})
			response.data = data.data;
			if(data.errors.length)
				alert(`案件清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	// 案件詳細資料
	getProjectInfo: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/project/project_type/${params.type}/project_id/${params.id}`, (response, status, headers) => {
			let data = checkData(response.data, (data) => {
				data.excavation_permit_name = data.excavation_permit_name ? data.excavation_permit_name : '無路證'
				data.excavation_permit = (data.excavation_permit_name && data.emergency_repair_number ? `${data.excavation_permit_name} (${data.emergency_repair_number})` : data.excavation_permit_name) + (data.work_number ? ` (${data.work_number})` : '')
			})
			response.data = data.data;
			if(data.errors.length)
				alert(`案件詳細資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	// 更新案件資料
	updateProjectInfo: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/project/${id}`, {...data, _method: "PUT"}, success, failed);
	},
	/** 檢查案件狀態
	 * @param {*} data 
	 * project_id
	 * project_type
	 * user_id
	 */
	checkProjectStatus: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/project/status/check', data, success, failed);
	},
	setProjectAcceptedAt: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/project/set_accepted_at', data, success, failed);
	},
	/** 新增多筆案件施工資料
	 * @param {*} data 
	 * project_id
	 * project_type
	 * user_id
	 * project_construction_data.*.project_data_type_id
	 */
	createMultiProjectConstructionData: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/project_construction_data`, data, success, failed);
	},
	/** 更新多筆案件施工資料
	 * @param {*} data 
	 * project_construction_data.*.id
	 * project_construction_data.*.project_data_type_id
	 * project_construction_data.*.status
	 */
	updateMultiProjectConstructionData: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/project_construction_data/update`, data, success, failed);
	},
	/** 新增多筆案件報告
	 * @param {*} data 
	 */
	createMultiProjectUnitReports: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/project_unit_reports`, data, success, failed);
	},
	/** 更新案件報告
	 * @param {*} data 
	 */
	updateProjectUnitReport: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/project_unit_report/${id}`, {...data, _method: "PUT"}, success, failed);
	},
	/** 更新多筆案件報告
	 * @param {*} data 
	 */
	updateMultiProjectUnitReports: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/project_unit_reports/update`, data, success, failed);
	},
	setProjectUnitReportPassed: (id, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/project_unit_report/set_passed/${id}`, {}, success, failed);
	},
	/** 新增案件報告駁回紀錄
	 * @param {Object} data
	 * project_unit_report_id
	 * note
	 */
	createUnitReportRejection: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/project_unit_report_rejection', data, success, failed);
	},
	createMultuUnitReportRejections: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/project_unit_report_rejections', data, success, failed);
	},
	/** 標案清單
	 * @param {Object} params 篩選: units_id
	 */
	getBidList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/bid${getQueryString(params)}`, (response, status, headers) => {
			let err_msg = ''
			for(let i in response.data) {
				for(let key in response.data[i]) {
					if(key.endsWith('_key')) {
						let item = getEnum(key, response.data[i][key])
						if(!item)
							err_msg += `\r\n${key} - ${response.data[i][key]}`
						else
							response.data[i] = { ...response.data[i], ...item }
					}
				}
			}
			if(err_msg)
				alert(`標案清單資料有誤！${err_msg}`)
			success(response, status, headers)
		}, failed);
	},
	/** 新增標案
	 * @param {Object} data
	 */
	createBid: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/bid', data, success, failed);
	},
	/** 更新標案
	 * @param {Object} data
	 */
	updateBid: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/bid/${id}`, {...data, _method: "PUT"}, success, failed);
	},
	/** 工程清單
	 * @param {Object} params 
	 */
	getConstructionList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/construction${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`工程清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 新增工程
	 * @param {Object} data
	 */
	createConstruction: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/construction', data, success, failed);
	},
	/** 更新工程
	 * @param {Object} data
	 */
	updateConstruction: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/construction/${id}`, {...data, _method: "PUT"}, success, failed);
	},
	/** 新增案件
	 * @param {Object} proj
	 * 	{
	 * 		creator_id: 				新增案件的使用者id(user.id),
	 * 		address: 					地址，為最小5個字元的字串,
	 * 		pipeline_center_id: 		道管中心id,
	 * 		contractor_id: 				承包商id,
	 * 		party_a_id: 				甲方id,
	 * 		excavation_permit_name: 	路證編號(選填),
	 * 		excavation_permit: 			路證編號(選填),
	 * 		expected_constructed_at: 	預計施工日期(選填),
	 * 		scene_pictures_url: 		現場照網址,
	 * 		bid_id: 					標案id(選填),
	 * 		construction_id: 			工程id(選填)
	 * }
	 */
	createProject: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/project', data, success, failed);
	},
	// 新增案件與工地
	createProjectAndConstructionSite(data, success=(()=>{}), failed=(()=>{})) {
		return httpPost('/api/v1/project/construction_site', data, (response, status, headers) => {
			let data = checkData(response.data, (data) => {
				data.excavation_permit_name = data.excavation_permit_name ? data.excavation_permit_name : '無路證'
				data.excavation_permit = (data.excavation_permit_name && data.emergency_repair_number ? `${data.excavation_permit_name} (${data.emergency_repair_number})` : data.excavation_permit_name) + (data.work_number ? ` (${data.work_number})` : '')
			})
			response.data = data.data;
			if(data.errors.length)
				alert(`工程清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 工地列表
	 * @param {Object} params 篩選: ids[], status_index[], projects_id[], project_status_index[]
	 */
	getConstructionSiteList(params, success=(()=>{}), failed=(()=>{})) {
		return httpGet(`/api/v1/construction_site${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data, (data) => {
				data.excavation_permit_name = data.excavation_permit_name ? data.excavation_permit_name : '無路證'
				data.excavation_permit = (data.excavation_permit_name && data.emergency_repair_number ? `${data.excavation_permit_name} (${data.emergency_repair_number})` : data.excavation_permit_name) + (data.work_number ? ` (${data.work_number})` : '')
			})
			response.data = data.data;
			if(data.errors.length)
				alert(`工程清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	// 新增工地
	createConstructionSite(data, success=(()=>{}), failed=(()=>{})) {
		return httpPost('/api/v1/construction_site', data, success, failed);
	},
	// 使用者清單
	getUserList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/user${getQueryString(params)}`, (response, status, headers) => {
			let err_msg = ''
			for(let i in response.data) {
				for(let key in response.data[i]) {
					// if(key.endsWith('_name')) {
					// 	response.data[i][key.split('_')[0]] = response.data[i][key]
					// }
					// else 
					if(key.endsWith('_key')) {
						let item = getEnum(key, response.data[i][key])
						if(!item)
							err_msg += `\r\n${key} - ${response.data[i][key]}`
						else
							response.data[i] = { ...response.data[i], ...item }
					}
				}
			}
			if(err_msg)
				alert(`使用者清單資料有誤！${err_msg}`)
			success(response, status, headers)
		}, failed);
	},
	/** 新增使用者
	 */
	createUser: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/user', data, success, failed);
	},
	/** 更新使用者
	 */
	updateUser: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/user/${id}`, {...data, _method: "PUT"}, success, failed);
	},
	/** 任務清單
	 * @param {Object} params 篩選: projects_id[], construction_sites_id[], outsiders_id[]
	 */
	getMissionList: (params, success=(()=>{}), failed=(()=>{})) => {
		let err_msg = ''
		let getValue = (data, key) => {
			if(key.endsWith('_key')) {
				let item = getEnum(key, data[key])
				if(!item)
					err_msg += `\r\n${key} - ${data[key]}`
				else
					data = { ...data, ...item }
			}
			else if(key.endsWith('_at') && data[key]) {
				data[key] = new Date(data[key]).format('yyyy-MM-dd')
			}
			if(data.lat && data.lng) {
				data.position = { lat: data.lat, lng: data.lng }
			}
			if(typeof data[key] === 'object') {
				for(let i in data[key]) {
					data[key] = getValue(data[key], i)
				}
			}
			return data
		}
		return httpGet(`/api/v1/mission${getQueryString(params)}`, (response, status, headers) => {
			for(let key in response.data) {
				if(typeof response.data[key] === 'object') {
					for(let i in response.data[key]) {
						response.data[key] = getValue(response.data[key], i)
					}
				}
				else
					response.data = getValue(response.data, key)
			}
			if(err_msg)
				alert(`任務清單資料有誤！${err_msg}`)
			success(response, status, headers)
		}, failed);
	},
	// 新增任務
	createMission: (mission, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/mission', mission, success, failed);
	},
	// 單位清單
	getUnitList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/unit${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`單位清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	// 單位詳細資料
	getUnitInfo: (id, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/unit/${id}`, (response, status, headers) => {
			let data = checkData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`單位資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	getUnitBranchList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/unit_branch${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`單位區處清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 單位人員清單
	 */
	getUnitMemberList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/unit_member${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`單位人員清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 新增單位
	 * name
	 * unit_id
	 * full_name
	 */
	createUnit: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/unit`, data, success, failed);
	},
	/** 更新單位
	 */
	updateUnit: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/unit/${id}`, {...data, _method: "PUT"}, success, failed);
	},
	/** 新增單位區處
	 * name
	 * type_index
	 * full_name
	 */
	createUnitBranch: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/unit_branch`, data, success, failed);
	},
	/** 更新單位區處
	 */
	updateUnitBranch: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/unit_branch/${id}`, {...data, _method: "PUT"}, success, failed);
	},
	/** 新增單位人員
	 * name
	 * unit_id
	 */
	createUnitMember: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/unit_member`, data, success, failed);
	},
	/** 更新單位人員
	 * name
	 * unit_id
	 */
	updateUnitMember: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/unit_member/${id}`, {...data, _method: "PUT"}, success, failed);
	},
	/** 取得電子章列表 */
	getSignatureList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/signature${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`電子章清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 上傳電子章 */
	uploadSignatures: (data, success=(()=>{}), failed=(()=>{})) => {
		let formData = toFormData(data)
		return httpPost(`/api/v1/signatures`, formData, success, failed);
	},
	/** 刪除電子章 */
	deleteSignature: (id, success=(()=>{}), failed=(()=>{})) => {
		return httpDelete(`/api/v1/signature/${id}`, success, failed);
	},
	// 案件資料種類清單
	getProjectDataType: (success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/project_data_type`, (response, status, headers) => {
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`案件資料種類清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	// 更新案件資料種類
	updateProjectDataType: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/project_data_type/${id}`, {...data, _method: "PUT"}, success, failed);
	},
	/** 更新案件資料
	 * @deprecated
	 */
	uploadProjectDatum: (data, success=(()=>{}), failed=(()=>{})) => {
		let formData = new FormData();
		for(let key in data) {
			formData.append(key, data[key])
		}
		return httpPost('/api/v1/project_datum', formData, success, failed, { 'Content-Type': 'multipart/form-data' })
	},
	// 更新案件資料
	uploadMultiProjectData: (data, success=(()=>{}), failed=(()=>{})) => {
		let formData = toFormData(data)
		return httpPost('/api/v1/project_data', formData, success, failed, { 'Content-Type': 'multipart/form-data' })
	},
	/** 刪除案件資料
	 * @param {int} id
	 */
	deleteProjectDatum: (id, success=(()=>{}), failed=(()=>{})) => {
		return httpDelete(`/api/v1/project_datum/${id}`, success, failed);
	},
	/** 配對案件資料至點位
	 * @deprecated
	 */
	matchProjectDatumToPoints: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/project_datum/${id}/points/match`, data, success, failed);
	},
	/** 配對多筆案件資料至點位
	 * @returns 
	 */
	matchMultiProjectDataToPoints: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/project_data/points/match`, data, success, failed);
	},
	/** 新增案件資料種類
	 */
	createProjectDataType: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/project_data_type`, data, success, failed);
	},
	/** 取得單位標籤列表
	 */
	getUnitTags: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/unit_tag${getQueryString(params)}`, (response, status, headers) => {
			let err_msg = ''
			for(let i in response.data) {
				for(let key in response.data[i]) {
					if(key.endsWith('_key')) {
						let item = getEnum(key, response.data[i][key])
						if(!item)
							err_msg += `\r\n${key} - ${response.data[i][key]}`
						else
							response.data[i] = { ...response.data[i], ...item }
					}
				}
			}
			if(err_msg)
				alert(`單位標籤列表資料有誤！${err_msg}`)
			success(response, status, headers)
		}, failed);
	},
	/** 新增單位標籤
	 * @deprecated
	 */
	createUnitTag: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/unit_tag`, data, success, failed);
	},
	/** 動態表單列表
	 * @param {Object} params 篩選: projects_id[], construction_sites_id[], id[], type[]
	 */
	getDynamicFormList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/dynamic_form${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`動態表單列表資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 動態表單列表
	 * @param {Object} params 篩選: projects_id[], construction_sites_id[], id[], type[]
	 */
	getUnitDynamicForm: (id, params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/unit/${id}/dynamic_form${getQueryString(params)}`, (response, status, headers) => {
			let data = checkData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`動態表單列表資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	// 新增動態表單
	createDynamicForm: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/dynamic_form', data, success, failed);
	},
	/** 連結動態表單
	 * @deprecated
	 */
	linkDynamicForm: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/project/link/dynamic_form', data, success, failed);
	},
	/** 工地詳細資料
	 * @param {Object} params 篩選: projects_id[], project_status_index[], status_index[]
	 */
	getConstructionSiteData: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/construction_site_data${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`工地詳細資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	// 更新工地資料
	updateConstructionSiteData: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/construction_site/${id}`, {...data, _method: "PUT"}, success, failed);
	},
	/** 設定工地待補測
	 */
	setConstructionSiteRemeasure: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/construction_site/${id}/remeasure`, data, success, failed);
	},
	/** 設定工地已測量
	 */
	setConstructionSiteMeasured: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/construction_site/${id}/measured`, data, success, failed);
	},
	/** 設定工地已完成
	 */
	setConstructionSiteComplete: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/construction_site/${id}/complete`, data, success, failed);
	},
	/** 點位列表
	 * @param {Object} params 篩選: construction_sites_id[]
	 */
	getPointList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/point${getQueryString(params)}`, (response, status, headers) => {
			sortArray(response.data, 'index')
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`點位列表資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	createMultiResectionPoints: (data, success=(()=>{}), failed=(()=>{})) => {
		let formData = toFormData(data)
		return httpPost('/api/v1/resection_points', formData, success, failed);
	},
	/** 上傳點位照片
	 * @param {Object}
	 * {
	 * 	construction_site_id						工地id
	 * 	user_id										使用者id
	 * 	points[].index								對應點位index
	 * 	points[].type								對應點位type
	 * 	points[].images[].project_data_type_id		照片類型id
	 * 	points[].images[].content					照片
	 * }
	 */
	uploadPointImages: (data, success=(()=>{}), failed=(()=>{})) => {
		let formData = toFormData(data)
		return httpPost('/api/v1/point/images', formData, success, failed);
	},
	/** 交會點點位列表
	 * @param {Object} params 篩選: construction_sites_id[]
	 */
	getResectionPointList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/resection_point${getQueryString(params)}`, (response, status, headers) => {
			sortArray(response.data, 'index')
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`交會點點位列表資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 支距點點位列表
	 * @param {Object} params 篩選: construction_sites_id[]
	 */
	getPositioningPointList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/positioning_point${getQueryString(params)}`, (response, status, headers) => {
			sortArray(response.data, 'index')
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`支距點點位列表資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 刪除支距點
	 * @param {*} id 支距點ID
	 */
	deletePositioningPoint: (id, success=(()=>{}), failed=(()=>{})) => {
		return httpDelete(`/api/v1/positioning_point/${id}`, success, failed);
	},
	matchPositioningPointWithPoint: (data, success=(()=>{}), failed=(()=>{})) => {
		let formData = toFormData(data)
		return httpPost(`/api/v1/positioning_point/point/match`, formData, success, failed);
	},
	uploadCoordinateFile: (data, success=(()=>{}), failed=(()=>{})) => {
		let formData = toFormData(data)
		return httpPost('/api/v1/coordinate_file', formData, success, failed);
	},
	/** 刪除任務
	 * @deprecated
	 * @param {int} id
	 */
	deleteMission: (id, success=(()=>{}), failed=(()=>{})) => {
		return httpDelete(`/api/v1/mission/${id}`, success, failed);
	},
	/** 刪除任務點位
	 * @param {int} id
	 */
	deleteMissionPoint: (mission_id, construction_site_id, success=(()=>{}), failed=(()=>{})) => {
		return httpDelete(`/api/v1/mission/${mission_id}/construction_site/${construction_site_id}`, success, failed);
	},
	/** 刪除多個任務點位
	 * 
	 */
	deleteMultiMissionPoints: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/mission/construction_site/delete', data, success, failed);
	},
	/** 轉移至四支距系統
	 * @param {int} id 工地ID
	 */
	postFourPitchSystem: (id, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/taipei_water/construction_site/four_positioning/${id}`, {}, success, failed);
	},
	/** 工地點位手動轉正高
	 * @param Number construction_site_id
	 */
	transformPointsOrthometricHeight: (construction_site_id, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/construction_site/${construction_site_id}/update/orthometric`, (response, status, headers) => {
			if(typeof response.errors !== 'undefined') {
				alert(response.message)
				console.error(response.errors)
			}
			success(response, status, headers)
		}, failed);
	},
	transformMultiConstructionSitesOrthometricHeight: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/construction_site/update/multi/orthometric', data, (response, status, headers) => {
			if(typeof response.errors !== 'undefined') {
				alert(response.message)
				console.error(response.errors)
			}
			success(response, status, headers)
		}, failed);
	},
	/** 生成四支距報表
	 * @param {int} id 北水案件ID
	 */
	exportFourPitchReport: (id, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/taipei_water/construction_site/four_positioning/report/${id}`, (response, status, headers) => {
			if(typeof response.errors !== 'undefined') {
				alert(response.message)
				console.error(response.errors)
			}
			success(response, status, headers)
		}, failed);
	},
	/** 生成報表
	 * @param {int} id 工地ID
	 */
	exportReport: (data, success=(()=>{}), failed=(()=>{})) => {
		let formData = toFormData(data);
		return httpPost(`/api/report/export`, formData, success, failed);
	},
	/** 取得報表生成種類
	 * @param {Object} data 
	 * {
	 * 	project_type
	 * 	project_id
	 * }
	 */
	getExportedReportTypes: (data, success=(()=>{}), failed=(()=>{})) => {
		let formData = toFormData(data);
		return httpPost(`/api/report/project/report_type`, formData, success, failed);
	},
	/** 生成案件報表
	 * @param {Object} data 
	 * {
	 * 	int project_type
	 * 	int project_id
	 * 	array report_types
	 * 	object data
	 * }
	 */
	exportProjectReport: (data, success=(()=>{}), failed=(()=>{})) => {
		let formData = toFormData(data);
		return httpPost(`/api/report/project/export`, formData, success, failed);
	},
	/** 北水四支距案件資料
	 * @param {Object} params 篩選: four_positioning_project_ids[]
	 */
	getFourPitchReportData: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/taipei_water/construction_site/four_positioning/project/data${getQueryString(params)}`, (response, status, headers) => {
			if(typeof response.errors !== 'undefined') {
				alert(response.message)
				console.error(response.errors);
			}
			success(response, status, headers)
		}, failed);
	},
	/** 取得案件群組清單
	 * @param {*} params 
	 */
	getGroupList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/project_group${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`專案清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 取得案件群組詳細資料
	 * @param {*} id 
	 */
	getGroupInfo: (id, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/project_group/${id}`, (response, status, headers) => {
			let data = checkData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`專案詳細資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 新增案件群組
	 * {
	 * 	name															案件群組名稱
	 * 	project_group_construction_data_types[].project_data_type_id	群組預設施工資料data_type
	 * 	project_unit_report_types[].unit_type							群組預設報告單位type
	 * 	project_unit_report_types[].project_data_type_id				群組預設報告data_type
	 * }
	 */
	createGroup: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/project_group', data, success, failed);
	},
	/** 更新案件群組資料
	 */
	updateGroup: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/project_group/${id}`, {...data, _method: "PUT"}, success, failed);
	},
	/** 刪除案件群組
	 */
	deleteGroup: (id, success=(()=>{}), failed=(()=>{})) => {
		return httpDelete(`/api/v1/project_group/${id}`, success, failed);
	},
	/** 取得專案新派工案件
	 */
	getGroupNewAssignedProject: (id, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/project_group/${id}/project/new/assigned`, (response, status, headers) => {
			let data = checkData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 刪除專案新派工案件
	 */
	deleteGroupNewAssignedProject: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/project_group/${id}/project/new/assigned/delete`, data, success, failed);
	},
	/** 新增案件群組預設施工資料（多筆）
	 * 	project_group_id												案件群組id
	 * 	project_group_construction_data_types[].project_data_type_id	群組預設施工資料data_type
	 */
	createMultiProjectGroupConstructionData: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/project_construction_data_types', data, success, failed);
	},
	/** 刪除案件群組預設施工資料（多筆）
	 */
	deleteMultiProjectGroupConstructionData: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/project_construction_data_types/delete', data, success, failed);
	},
	/** 新增案件群組權預設單位繳交報告（多筆）
	 * 	project_group_id												案件群組id
	 * 	project_unit_report_types[].project_data_type_id				群組預設報告data_type
	 */
	createMultiProjectGroupUnitReport: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/project_unit_report_types', data, success, failed);
	},
	/** 刪除案件群組權預設單位繳交報告（多筆）
	 * @param {Array[int]} data
	 */
	deleteMultiProjectGroupUnitReport: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/project_unit_report_types/delete', data, success, failed);
	},
	/** 取得使用者權限
	 */
	getUserPermissions: (success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/user_permission`, (response, status, headers) => {
			let data = checkData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 新增使用者對案件群組權限
	 * @deprecated
	 * 	user_id															使用者id
	 * 	project_group_id												案件群組id
	 * 	permission_type													權限等級
	 */
	createGroupPermission: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/group_permission', data, success, failed);
	},
	/** 刪除使用者對案件群組權限
	 * @deprecated
	 */
	deleteGroupPermission: (id, success=(()=>{}), failed=(()=>{})) => {
		return httpDelete(`/api/v1/group_permission/${id}`, {}, success, failed);
	},
	/** 搜尋使用者對案件群組權限列表
	 */
	getGroupPermissionList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/group_permission${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`專案清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 新增多筆使用者案件群組權限
	 */
	createMultiGroupPermission: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/group_permissions', data, success, failed);
	},
	/** 刪除多筆使用者案件群組權限
	 */
	deleteMultiGroupPermission: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/group_permissions/delete', data, success, failed);
	},
	/** 更新多筆使用者案件群組權限
	 */
	updateMultiGroupPermission: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/group_permissions/update', data, success, failed);
	},
	/** 下載Excel報表
	 */
	exportProjectExcel: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/export/project/excel', data, success, failed, {
			responseType: 'blob',
		});
	},
	/** 下載北水每日收件檢核表
	 */
	exportTpwProjectDailyReceivedExcel: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/export/tpw/project/received/daily/excel', data, success, failed, {
			responseType: 'blob',
		});
	},
	/** 下載北水專案執行狀況Excel
	 */
	exportTpwProjectImplementationExcel: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/export/tpw/project/implementation/excel', data, success, failed, {
			responseType: 'blob',
		});
	},
	/** 下載北水單位報告繳交Excel
	 */
	exportTpwProjectUnitReportSubmittedExcel: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/export/tpw/project/report/submitted/excel', data, success, failed, {
			responseType: 'blob',
		});
	},
	/** 下載北水單位報告未繳交清冊Excel
	 */
	exportTpwProjectUnitReportUncommittedExcel: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/export/tpw/project/report/uncommitted/excel', data, success, failed, {
			responseType: 'blob',
		});
	},
	/** 下載北水案件狀態清冊Excel
	 */
	exportTpwProjectStatusExcel: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/export/tpw/project/status/list/excel', data, success, failed, {
			responseType: 'blob',
		});
	},
	/** 下載北水案件銑舖資料清冊Excel
	 */
	exportTpwProjectPavedInformationExcel: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/export/tpw/project/paved/information/excel', data, success, failed, {
			responseType: 'blob',
		});
	},
	/** 下載案件列表csv
	 */
	exportProjectCsv: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/export/project/csv${getQueryString(params)}`, success, failed, {
			responseType: 'blob',
		});
	},
	/** 取得控制點列表
	 */
	getControlPointList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/control_point${getQueryString(params)}`, (response, status, headers) => {
			let data = checkArrayData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`控制點清單資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 更新控制點資料
	 */
	updateControlPoint: (id, data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost(`/api/v1/control_point/${id}`, {...data, _method: "PUT"}, success, failed);
	},
	/** 輸出控制點列表
	 */
	exportControlPointDataList: (data, success=(()=>{}), failed=(()=>{})) => {
		return httpPost('/api/v1/control_point/data/export', data, success, failed, {
			responseType: 'blob',
		});
	},
	/** 取得座標校驗參考控制點
	 */
	getControlPointExaminationData: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/v1/control_point/data/examination${getQueryString(params)}`, (response, status, headers) => {
			let data = checkData(response.data)
			response.data = data.data;
			if(data.errors.length)
				alert(`座標校驗控制點資料有誤！${data.errors.join('\r\n')}`)
			success(response, status, headers)
		}, failed);
	},
	/** 取得道管爬蟲log
	 */
	getPipelineCenterCrawlerLogs: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/crawler/v1/logs${getQueryString(params)}`, success, failed);
	},
	/** 下載道管爬蟲清冊
	 */
	exportPipelineCenterCrawlerProjectsList: (params, success=(()=>{}), failed=(()=>{})) => {
		return httpGet(`/api/crawler/v1/projects/export${getQueryString(params)}`, success, failed, {
			responseType: 'blob',
		});
	},
}