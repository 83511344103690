<template>
	<div class="w-100">
		<div class="d-flex justify-content-between photo-album-header">
			<div class="photo-album-title"><b v-show="title">{{title}}</b></div>
			<div class="photo-album-buttons"><slot name="buttons"></slot></div>
		</div>
		<b-row class="photo-album-wrapper px-md-3 p-1" v-if="files.length">
			<photo :photo="photo" :keys="{name: 'name', src: 'src'}" :class="photoClass" :photo-style="photoStyle" @select="select(key)" @delete="deleteFile(key)" @edit="edit(key)" :selectable="selectable" :deletable="deletable" :editable="editable" :showname="showname" v-for="(photo, key) in files" :key="key"></photo>
		</b-row>
		<b-row class="col-12 no-photo" v-else><b>{{noPhotoText}}</b></b-row>
	</div>
</template>

<script>
import Photo from "./Photo"
import { isEmpty, getRecursiveValue } from "@/utils/assist"
export default {
	name: 'PhotoAlbum',
	components:{
		Photo,
	},
	props: {
		photos: {
			type: Array,
			default() { return [] }
		},
		photoClass: {
			type: String,
			default: "col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12"
		},
		photoStyle: {
			type: String,
			default: ""
		},
		textClass: {
			type: String,
			default: ""
		},
		textStyle: {
			type: String,
			default: ""
		},
		title: {
			type: String,
			default: ""
		},
		noPhotoText: {
			type: String,
			default: '目前無照片'
		},
		deletable: {
			type: Boolean,
			default: false
		},
		selectable: {
			type: Boolean,
			default: false
		},
		editable: {
			type: Boolean,
			default: false
		},
		showname: {
			type: Boolean,
			default: false
		},
		keys: {
			type: [Object, String],
			default: ''
		}
	},
	data() {
		return {
			files: [],
		}
	},
	watch: {
		photos: {
			deep: true,
			handler(val, old) {
				if(!Object.isEqual(val, old)){
					this.init()
				}
			}
		},
		keys: {
			deep: true,
			handler(val, old) {
				if(!Object.isEqual(val, old)){
					this.init()
				}
			}
		},
	},
	created() {
		this.init()
	},
	destroyed() {
	},
	mounted() {
	},
	computed: {
	},
	methods:{
		init() {
			this.getPhotos(this.photos, this.keys)
		},
		isEmpty(obj) { return isEmpty(obj) },
		getPhotos(photos, key) {
			this.files = Array.isArray(photos) ? photos.map(photo => {
				let opt = typeof photo === 'object' && !this.isEmpty(key.name) && !this.isEmpty(key.src) ? { name: getRecursiveValue(photo, key.name), src: getRecursiveValue(photo, key.src) } : typeof photo === 'object' && typeof key === 'string' && !this.isEmpty(photo[key]) ? { name: photo[key], src: photo[key] } : typeof photo === 'object' && !this.isEmpty(photo.name) && !this.isEmpty(photo.src) ? photo : { name: photo.split('/').pop().substr(0, photo.split('/').pop().lastIndexOf('.')), src: photo }
				return {...photo, ...opt}
			}) : typeof photos === "string" ? { name: photos.split('/').pop().substr(0, photos.split('/').pop().lastIndexOf('.')), src: photos } : { name: photos.name, src: photos };
			this.$forceUpdate()
		},
		deleteFile(index) {
			this.$emit("delete", this.photos[index], index)
		},
		select(index) {
			this.$emit("select", this.photos[index], index)
		},
		edit(index) {
			this.$emit("edit", this.photos[index], index)
		}
	}
}
</script>

<style scoped>
.photo-album-header {
	flex-wrap: wrap;
}
.photo-album-header .photo-album-title {
	flex: 1 1;
}
/* .photo-album-header .photo-album-buttons {
} */
.photo-album-wrapper {
	flex-wrap: wrap;
	margin-bottom: .5rem;
}
.photo-wrapper {
	padding: .25rem;
}
.photo {
	/* height: 100%; */
	width: 100%;
	padding: .5rem;
	border: .5px solid #cbd8e1;
	border-radius: 1rem;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}
.photo img {
	max-width: 100%;
	max-height: 200px;
	border-radius: .75rem;
}
.photo-name {
	font-size: .6rem;
}
.photo-close-btn {
	position: absolute;
	right: .75rem;
	top: .75rem;
	z-index: 3;
	height: 1rem;
	width: 1rem;
	font-size: .6rem;
	display: none;
	align-items: center;
	justify-content: center;
	border: .5px solid #cbd8e150;
	border-radius: 50%;
	box-shadow: 0 0 2px 1px #cbd8e1
}
.photo:hover ~ .photo-close-btn {
	display: flex;
}
.photo-close-btn:hover {
	display: flex;
	background-color: #cbd8e1;
	box-shadow: 0 0 2px 1px #b2c8d8;
}
.no-photo {
	margin-bottom: .5rem;
	text-align: center;
	color: #aaa;
	justify-content: center;
}
</style>