<template>
	<div>
		<loading mode="fixed" image="1" size="lg" v-if="loading" style="z-index:99999"/>
		<point-list :site="site" :title="pointType.text" :points="site[`${pointType.key}s`]" :columns="tableColumns[pointType.key.toCamelCase()]" :detailColumns="tableDetailColumns[pointType.key.toCamelCase()]" :actions="tableActions[pointType.key.toCamelCase()]" :errors="errors[pointType.key.toCamelCase()]" :warnings="warnings[pointType.key.toCamelCase()]" v-for="(pointType, key) in $store.getters.enum('point.type').filter(type => type.data.has_project)" :key="key">
			<template v-slot:buttons>
				<b-button class="ml-2 form-btn submit-btn" @click="button.function" v-for="(button, key) in tableButtons[pointType.key.toCamelCase()]" :key="key">{{button.label}}</b-button>
			</template>
		</point-list>
		<b-row class="col-12 p-md-3 p-1 justify-content-end align-items-center">
			<template v-if="showOrthometricHeightButton">
				<b-button class="form-btn submit-btn" @click="transformPointsOrthometricHeight">轉換點位正高</b-button>
				<a class="row align-items-center" href="https://egnss.nlsc.gov.tw/trans/geo.aspx" target="_blank"><i class="fa-fw fas fa-globe"></i>轉正高網頁連結<i class="fa-fw fas fa-angle-double-right ml-1"></i></a>
			</template>
		</b-row>
		<modal v-model="fourPitchModal" centered @ok="postFourPitchPoints">
			是否確定新增四支距點位
		</modal>
		<modal v-model="coordinateModal" centered size="lg" title="上傳座標csv">
			<div style="min-height: 50vh">
				<input-form ref="coordinateForm" class="p-0 col-12" :options="coordinateFormOptions" v-model="coordinateFormData" :actions="{submit:false, cancel:false}"></input-form>
			</div>
			<template v-slot:footer>
				<b-button variant="primary" @click="submitCoordinateForm">確認</b-button>
			</template>
		</modal>
		<modal v-model="newResectionPointModal" centered size="lg" title="新增後方交會點">
			<div style="min-height: 50vh">
				<input-form ref="newResectionPointForm" class="p-0 col-12" :options="newResectionPointFormOptions" v-model="newResectionPointFormData" :actions="{submit:false, cancel:false}"></input-form>
			</div>
			<template v-slot:footer>
				<b-button variant="primary" @click="createMultiResectionPoints">確認</b-button>
			</template>
		</modal>
		<modal v-model="matchPositioningPointModal" centered size="lg" title="配對支距點至測量點">
			<div style="min-height: 50vh">
				<input-form ref="matchPositioningPointForm" class="p-0 col-12" :options="matchPositioningPointFormOptions" v-model="matchPositioningPointFormData" :actions="{submit:false, cancel:false}" :deep="true"></input-form>
			</div>
			<template v-slot:footer>
				<b-button variant="primary" @click="matchPositioningPointWithPoint">確認</b-button>
			</template>
		</modal>
		<modal v-model="deletePositioningPointModal" centered @ok="deletePositioningPoint">
			是否確定刪除支距點 {{deletePositioningPointData.name}}
		</modal>
	</div>
</template>

<script>
import PointList from '@/components/Project/Result/Point/PointList.vue'
import Modal from '@/components/Modal/Modal'
import InputForm from '@/components/Input/InputForm';
import Loading from '@/components/Loading/Loading.vue';
import { deepCopy, sortArray, checkAllTrue } from '@/utils/assist';
import { IsFacilityType } from '@/gml/utils';
import fileManager from '@/utils/file';

export default {
	name: 'ResultPointList',
	components: {
		PointList,
		Modal,
		InputForm,
		Loading
	},
	props: {
		site: {
			type: Object,
			default: () => { return {} }
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: false,
			validator: {
				point: {
					errors: [
						{
							key: 'fourPitchPointsCount',
							validator: pt => this.site.four_positioning_points_count && (this.site.tp_system_four_positioning_data && this.site.tp_system_four_positioning_data.points_count > this.site.four_positioning_points_count),
							title: "含有多餘四支距點位，請至北水巡查系統查詢",
						},
						{
							key: 'errorCoordinate',
							validator: pt => pt.coordinate === null,
							title: "未取得測量座標，請重新上傳測量座標csv",
							messager: point => `${point.name}`,
						},
						{
							key: 'errorFormData',
							validator: pt => pt.status_index === this.$store.getters.enum('point.status.created').index || pt.status_index === this.$store.getters.enum('point.status.located').index,
							title: "點位資料不全，請重新上傳表單：設施物、型態、埋深",
							messager: point => `${point.name}`,
						},
						{
							key: 'errorOrthometricHeight',
							validator: pt => pt.coordinate ? pt.coordinate.orthometric_height === null : false,
							title: "測量點正高轉換錯誤，請點擊下方按鈕轉換",
						},
					],
					warnings: [
						{
							key: 'singleFacility',
							validator: (pt, index, points) => IsFacilityType(pt.transformed_type.type) && typeof this.getPipelinePointUnderFacility(pt, points) === "undefined",
							title: "單點設施物（未與任何管線相連）",
							messager: point => `${point.name}（設施物種類：${point.transformed_data.type}${point.transformed_data.facility_type ? `-${point.transformed_data.facility_type}` : ''}）`,
						},
						{
							key: 'incorrectDepth',
							validator: pt => pt.transformed_data.depth > 3,
							title: "點位深度過深（埋深大於3公尺）",
							messager: point => `${point.name}（埋深：${point.transformed_data.depth}）`,
						},
						{
							key: 'facilityPosition',
							validator: (pt, index, points) => IsFacilityType(pt.transformed_type.type) && this.getDistanceFromFacilityToPipeline(pt, points) >= 0.01,
							title: "設施物座標與所在管線距離大於1cm",
							messager: point => `${point.name}（所在管線點號：${point.transformed_data.target1}）`,
						},
						{
							key: 'fourPitchPointsCount',
							validator: pt => this.site.four_positioning_points_count && (!this.site.tp_system_four_positioning_data || this.site.tp_system_four_positioning_data && this.site.tp_system_four_positioning_data.points_count < this.site.four_positioning_points_count),
							title: `部分點位尚未匯入北水巡查系統（應有四支距：${this.site.four_positioning_points_count}，已有：${this.site.tp_system_four_positioning_data ? this.site.tp_system_four_positioning_data.points_count : 0}）`,
						},
						{
							key: 'needPositioning',
							validator: pt => pt.need_positioning && !pt.positioning_point,
							title: '無支距定位資料',
							messager: point => `${point.name}`,
						},
					]
				},
				resectionPoint: {
					errors: [
						{
							key: 'errorCoordinate',
							validator: pt => pt.coordinate === null,
							title: "未取得測量座標，請重新上傳測量座標csv",
						},
						{
							key: 'errorOrthometricHeight',
							validator: pt => pt.coordinate ? pt.coordinate.orthometric_height === null : false,
							title: "測量點正高轉換錯誤，請點擊下方按鈕轉換",
						},
					]
				},
				positioningPoint: {
					errors: [
						{
							key: 'uploadPositioningPipelineImage',
							validator: pt => !(pt.data['支距管線圖'] && pt.data['繪製後支距管線圖']),
							title: '尚未上傳支距管線圖或繪製後支距管線圖',
							messager: point => `${point.name}`,
						},
						{
							key: 'matchPositioningPointWithPoint',
							validator: pt => !pt.point,
							title: '尚未配對支距點位至測量點',
							messager: point => `${point.name}`,
						},
						{
							key: 'matchPositioningPointWithIncorrectPoint',
							validator: pt => pt.point && !pt.point.need_positioning,
							title: '配對支距點位至不需支距定位之測量點',
							messager: point => `${point.name}`,
						},
					],
					warnings: [
					],
				},
			},
			tableButtons: {
				point: [
					{
						label: "新增四支距點位（北水巡查系統）",
						function: () => { this.fourPitchModal=true },
						hide: !this.showPostFourPitchPointButton
					},
					{
						label: "下載正高轉換檔",
						function: this.downloadPointOrthometricHeightFile,
						hide: !this.showPointOrthometricHeightButton
					},
				],
				resectionPoint: [
					{
						label: "新增後方交會點",
						function: () => this.showNewResectionPointModal('resection_point'),
						hide: !this.showPointOrthometricHeightButton
					},
					{
						label: "上傳座標csv",
						function: () => this.showCoordinateModal('resection_point'),
						hide: !this.site.resection_points.length
					},
					{
						label: "下載正高轉換檔",
						function: this.downloadResectionPointOrthometricHeightFile,
						hide: !this.showResectionPointOrthometricHeightButton
					},
				],
				positioningPoint: [
					{
						label: "配對至測量點",
						function: () => this.showMatchPositioningPointModal(),
						hide: !this.site.positioning_points.length
					},
				],
			},
			tableActions: {
				positioningPoint: [
					{
						//icon, label
						button: { icon: "fas fa-trash", label: "刪除", style: "color: #903934" },
						//function, link, route
						action: { type: 'function', function: this.showDeletePositioningPointModal }
					}
				],
			},
			fourPitchModal: false,
			coordinateModal: false,
			coordinateFormData: {},
			coordinateFile: {},
			newResectionPointModal: false,
			newResectionPointFormData: {},
			newResectionPoints: [],
			matchPositioningPointModal: false,
			matchPositioningPointFormData: {},
			deletePositioningPointModal: false,
			deletePositioningPointData: {},
		}
	},
	created() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	mounted() {
	},
	computed: {
		tableColumns() {
			const columns = {
				point: [
					// { key: 'ellipsoidal_height', label: '橢球高', sortable: true, searchable: true },
					{ key: 'transformed_data.depth', label: '埋深', sortable: true, searchable: true, deep: true},
					{ key: 'transformed_type.type', label: '種類', sortable: true, searchable: true, deep: true},
					// { key: 'facility_height', label: '管頂高程(Z-H)', sortable: true, searchable: true },
				],
				positioningPoint: [
					{ key: 'point_name', label: '對應點位', sortable: true, searchable: true},
					{ key: 'modified_form_data.支距1_設施物', label: '支距1', sortable: true, searchable: true, deep: true},
					{ key: 'modified_form_data.支距2_設施物', label: '支距2', sortable: true, searchable: true, deep: true},
					{ key: 'modified_form_data.支距3_設施物', label: '支距3', sortable: true, searchable: true, deep: true},
				],
			}
			return this.$store.getters.enum('point.type').filter(type => type.data.has_project).reduce((obj, pointType) => {
				obj[pointType.key.toCamelCase()] = [
					...pointType.data.has_coordinates ? [
						{ key: 'coordinate.twd97_x', label: 'X', sortable: true, searchable: true, deep: true},
						{ key: 'coordinate.twd97_y', label: 'Y', sortable: true, searchable: true, deep: true},
						{ key: 'coordinate.orthometric_height', label: '正高', sortable: true, searchable: true, deep: true}
					] : [],
					...columns[pointType.key.toCamelCase()] ?? [],
				]
				return obj
			}, {})
		},
		tableDetailColumns() {
			return this.$store.getters.enum('point.type').filter(type => type.data.has_project).reduce((obj, pointType) => {
				let dyanmicFormType = this.$store.getters.enum('dynamic_form.type').filter(type => type.data.has_unit).find(type => type.index === pointType.data.dynamic_form_type)
				let dyanmicFormTitles = dyanmicFormType ? this.site.dynamic_form_titles[dyanmicFormType.key] : []
				obj[pointType.key.toCamelCase()] = [
					...pointType.data.has_coordinates ? [
						{ key: 'coordinate.twd97_x', label: 'X', deep: true },
						{ key: 'coordinate.twd97_y', label: 'Y', deep: true },
						{ key: 'coordinate.orthometric_height', label: '正高', deep: true },
						{ key: 'coordinate.ellipsoidal_height', label: '橢球高', deep: true },
					] : [],
					...(dyanmicFormType ? [...new Set(this.site[`${pointType.key}s`].map(point => Object.keys(point.modified_form_data)).flat())].map(key => {
						let title = dyanmicFormTitles.find(title => title.key === key) ?? {}
						return { key: `modified_form_data.${key}`, label: key.includes(title.name) ? key : title.name, deep: true,
							...(title.type === 'image' ? { type: 'image' } : {})
						}
					}) : []),
					...[...new Set(this.site[`${pointType.key}s`].map(point => Object.keys(point.data)).flat())].map(label => {
						return { key: `data.${label}.content`, label: label, deep: true, type: 'image' }
					})
				]
				return obj
			}, {})
		},
		errors() {
			return this.$store.getters.enum('point.type').filter(type => type.data.has_project).reduce((obj, pointType) => {
				obj[pointType.key.toCamelCase()] = ((this.validator[pointType.key.toCamelCase()] ?? {}).errors ?? []).map(error => {
					let points = (this.site[`${pointType.key}s`] ?? []).filter(error.validator)
					return points.length ? {
						...error,
						messages: error.messager ? points.map(pt => {
							return typeof error.messager === 'function' ? error.messager(pt) : error.messager
						}).join(', ') : ''
					} : undefined
				}).filter(e => e)
				return obj;
			}, {})
		},
		warnings() {
			return this.$store.getters.enum('point.type').filter(type => type.data.has_project).reduce((obj, pointType) => {
				obj[pointType.key.toCamelCase()] = ((this.validator[pointType.key.toCamelCase()] ?? {}).warnings ?? []).map(error => {
					let points = (this.site[`${pointType.key}s`] ?? []).filter(error.validator)
					return points.length ? {
						...error,
						messages: error.messager ? points.map(pt => {
							return typeof error.messager === 'function' ? error.messager(pt) : error.messager
						}).join(', ') : ''
					} : undefined
				}).filter(e => e)
				return obj;
			}, {})
		},
		showPointOrthometricHeightButton() {
			return !!this.errors.point.find(err => err.key === 'errorOrthometricHeight')
		},
		showResectionPointOrthometricHeightButton() {
			return !!this.errors.resectionPoint.find(err => err.key === 'errorOrthometricHeight')
		},
		showOrthometricHeightButton() {
			return this.showPointOrthometricHeightButton || this.showResectionPointOrthometricHeightButton
		},
		showPointCoordinateButton() {
			return this.errors.point.find(err => err.key === 'errorCoordinate')
		},
		showPostFourPitchPointButton() {
			return this.site.four_positioning_points_count && (!this.site.tp_system_four_positioning_data || this.site.tp_system_four_positioning_data && this.site.tp_system_four_positioning_data.points_count !== this.site.four_positioning_points_count)
		},
		coordinateFormOptions() {
			return [
				{ key: "point_type", type: "select", label: "點位種類", required: true, options: this.$store.getters.enum('point.type').filter(type => type.data.has_project), keys: {value: 'index', text: 'text'}, size: 'xs', disabled: true },
				{ key: "instrument_type", type: "select", label: "儀器種類", required: true, options: this.$store.getters.enum('coordinate.instrument'), keys: {value: 'index', text: 'text'}, size: 'xs', onInput: (e) => (e) => this.loadCoordinateFile(this.coordinateFormData.coordinate_file, this.coordinateFormData.instrument_type, this.coordinateFormData.point_type) },
				{ key: "coordinate_file", type: "file", label: "座標csv", required: true, accept: '.csv', size: 'xs', onLoad: (file) => (file) => this.loadCoordinateFile(file.file, this.coordinateFormData.instrument_type, this.coordinateFormData.point_type), hide: this.coordinateFormData.instrument_type===undefined },
				// { key: "unit_id", type: "select", label: "甲方", required: true, options: this.partyAUnits, keys: {value: 'id', text: 'name'}, size: 'xs' },
				// { key: "measurer_unit_id", type: "select", label: "測量公司", required: true, options: this.measurerUnits, keys: {value: 'id', text: 'name'}, size: 'xs' },
			]
		},
		newResectionPointFormOptions() {
			return [
				{ key: "point_type", type: "select", label: "點位種類", required: true, options: this.$store.getters.enum('point.type').filter(type => type.data.has_project), keys: {value: 'index', text: 'text'}, size: 'xs', disabled: true },
				{ key: "point_amount", type: "number", label: "點位數量", required: true, size: 'xs' },
				{ key: "instrument_type", type: "select", label: "儀器種類", required: true, options: this.$store.getters.enum('coordinate.instrument'), keys: {value: 'index', text: 'text'}, size: 'xs', onInput: (e) => (e) => this.loadCoordinateFile(this.newResectionPointFormData.coordinate_file, this.newResectionPointFormData.instrument_type, this.newResectionPointFormData.point_type) },
				{ key: "coordinate_file", type: "file", label: "座標csv", required: true, accept: '.csv', size: 'xs', onLoad: (file) => (file) => this.loadCoordinateFile(file.file, this.newResectionPointFormData.instrument_type, this.newResectionPointFormData.point_type), hide: this.newResectionPointFormData.instrument_type===undefined },
			]
		},
		matchPositioningPointFormOptions() {
			return this.site.positioning_points.map((point, key) => {
				return { key: `${key}.point_index`, type: "select", label: point.name, options: this.site.points.filter(p => p.need_positioning).map(pt => {
					return { value: pt.index, text: `${pt.name} (${pt.transformed_type.type})` }
				}), size: 'xs', emptyOption: false }
			})
		},
	},
	methods:{
		reload() { this.$emit('reload') },
		load(loading) {
			this.loading = loading
		},
		getDistance(pos1, pos2) {
			return Math.sqrt(Math.pow(pos1.twd97_x - pos2.twd97_x, 2) + Math.pow(pos1.twd97_y - pos2.twd97_y, 2))
		},
		getDistanceFromFacilityToPipeline(point, points) {
			let target = this.getPipelinePointUnderFacility(point, points)
			return target && target.coordinate ? this.getDistance(point.coordinate, target.coordinate) : 0
		},
		getPipelinePointUnderFacility(point, points) {
			return points.find(pt => pt.name === point.transformed_data.target1)
		},
		downloadPointOrthometricHeightFile() {
			let content = this.site.points.map( pt => {
				return [pt.full_name, "NEh", pt.coordinate.twd97_y, pt.coordinate.twd97_x, pt.coordinate.ellipsoidal_height].join(",")
			})
			fileManager.saveFile(`${this.site.name}-轉正高`, "txt", content.join("\n"))
		},
		downloadResectionPointOrthometricHeightFile() {
			let content = this.site.resection_points.map( pt => {
				return [pt.full_name, "NEh", pt.coordinate.twd97_y, pt.coordinate.twd97_x, pt.coordinate.ellipsoidal_height].join(",")
			})
			fileManager.saveFile(`${this.site.name}-轉正高`, "txt", content.join("\n"))
		},
		showCoordinateModal(pointType) {
			this.coordinateModal = true
			this.coordinateFormData = Object.assign(this.coordinateFormData, {
				point_type: this.$store.getters.enum(`point.type.${pointType}`).index,
				instrument_type: undefined,
				coordinate_file: undefined,
			})
		},
		loadCoordinateFile(file, instrument_type, point_type) {
			if(!file) return;
			let instrumentType = this.$store.getters.enum('coordinate.instrument').find(type => type.index === instrument_type)
			let format = instrumentType.data.csv_format

			fileManager.readFile(file, {encoding: "BIG5"}, (text) => {
				let csv = fileManager.readSheet(text, false)
				if(format.has_header) {
					csv.shift()
				}
				let coordinates = []
				let data = {}
				csv.forEach((row, row_index) => {
					let pointIndex = row[format.header.indexOf('點名')]
					pointIndex = pointIndex ? pointIndex.replace(/ptA/i, '') : -1
					coordinates.push(Object.keys(format.row).reduce((obj, key) => {
						let index = format.header.indexOf(format.row[key])
						obj[key] = index && row[index] ? row[index] : obj[key]
						return obj
					}, {
						row: row_index + 1,
						index: pointIndex,
					}))
					data[row_index] = format.header.reduce((obj, key, index) => {
						obj[index] = { "key": key, "value": row[index] ? row[index] : '' }
						return obj
					}, {})
				})
				this.coordinateFile = Object.assign(this.coordinateFile, {
					file_name: file.name,
					instrument_type: instrument_type,
					point_type: point_type,
					// coordinates: coordinates,
					// raw_data: data,
					point_position_coordinates: JSON.stringify(coordinates),
					data: JSON.stringify(data),
				})
			})
		},
		checkCoordinates(message='', err_message='') {
			let messages = message ? [message] : []
			let coordinates = JSON.parse(this.coordinateFile.point_position_coordinates)
			let instrumentType = this.$store.getters.enum('coordinate.instrument').find(type => type.index === this.coordinateFile.instrument_type)
			let pointType = this.$store.getters.enum('point.type').filter(type => type.data.has_project).find(type => type.index === this.coordinateFile.point_type)
			let points = []
			if(pointType) {
				switch(pointType.key) {
					case 'point':
						points = this.site.points
						break;
					case 'resection_point':
						points = [
							...this.site.resection_points,
							...this.newResectionPoints
						]
						break;
				}
			}
			if(points.length) {
				let keys = Object.keys(instrumentType.data.csv_format.row)
				const ERROR_MESSAGE = {
					coordinate: `csv資料格式錯誤`,
					point: `無法配對點位`
				}
				let errors = coordinates.reduce((errors, coordinate) => {
					let key = ''
					if(keys.some(key => coordinate[key] === undefined))
						key = 'coordinate';
					else if(!points.find(pt => pt.index === parseInt(coordinate.index)))
						key = 'point'
					if(key)
						errors[key].push(`pt${coordinate.index}`)
					return errors
				}, {
					coordinate: [],
					point: []
				})
				errors = Object.keys(errors).filter((key) => errors[key].length).map((key) => `${ERROR_MESSAGE[key]}: ${errors[key].join(", ")}`)
				messages = [
					...messages,
					...errors.length && err_message ? [err_message] : [],
					...errors
				]
			}
			if(messages.length)
				alert(messages.join("\n"))
			return messages.length <= (message ? 1 : 0)
		},
		transformPointsOrthometricHeight() {
			this.$set(this.call, "transformPointsOrthometricHeight", false)
			this.$axios.transformPointsOrthometricHeight(this.site.id, (response) => {
				alert(`${response.fail_count}個點位轉換失敗，共轉換${response.all_count}個點位。`);
				if(response.fail_count < response.all_count) {
					this.$emit("reload");
				}
				this.call.transformPointsOrthometricHeight = true
			}, (error) => {
				this.call.transformPointsOrthometricHeight = true
			})
		},
		postFourPitchPoints() {
			this.$set(this.call, "postFourPitchSystem", false)
			this.$axios.postFourPitchSystem(this.site.id, (response) => {
				if(response.new_construction_site) {
					alert(`成功新增${response.new_points_count}個點位，請至四支距系統查詢。`);
				}
				else {
					alert(`無需測量四支距之點位或北水巡查系統已有此工地之四支距點位。`);
				}
				this.call.postFourPitchSystem = true
			}, (error) => {
				let errors = Array.isArray(error.response.data.errors) ? error.response.data.errors : Object.values(error.response.data.errors);
				switch(error.response.status) {
					case 422:
						alert(`新增失敗！資料錯誤：\n${errors.flat().join("\n")}`);
						break;
					default:
						alert(error);
						break;
				}
				this.call.postFourPitchSystem = true
			})
		},
		submitCoordinateForm() {
			if(!this.$refs.coordinateForm.reportValidity())
				return;
			this.uploadCoordinateFile(this.coordinateFormData)
		},
		uploadCoordinateFile(formData, msg='') {
			if(!this.checkCoordinates()) return;
			this.$set(this.call, "uploadCoordinateFile", false)
			return this.$axios.uploadCoordinateFile({
				creator_id: this.$store.getters.currentUser.id,
				construction_site_id: this.site.id,
				...formData,
				...this.coordinateFile,
			}, (response) => {
				alert(msg + '座標csv上傳成功')
				this.reload()
				this.call.uploadCoordinateFile = true
			}, (error) => {
				this.call.uploadCoordinateFile = true
			})
		},
		showNewResectionPointModal(pointType) {
			this.newResectionPointModal = true
			this.newResectionPointFormData = Object.assign(this.newResectionPointFormData, {
				point_type: this.$store.getters.enum(`point.type.${pointType}`).index,
				point_amount: 1,
			})
		},
		createMultiResectionPoints() {
			if(!this.$refs.newResectionPointForm.reportValidity())
				return;
			this.$set(this.call, "createMultiResectionPoints", false)
			this.$axios.createMultiResectionPoints({
				construction_site_id: this.site.id,
				...this.newResectionPointFormData,
			}, (response) => {
				this.newResectionPoints = response.data
				let msg = `已新增${this.newResectionPointFormData.point_amount}個點位，`
				if(this.checkCoordinates('', `${msg}座標檔案有誤，請重新上傳`)) {
					this.uploadCoordinateFile(this.newResectionPointFormData, msg).catch((error) => {
						this.reload()
					})
				}
				else
					this.reload()
				this.call.createMultiResectionPoints = true
			}, (error) => {
				this.call.createMultiResectionPoints = true
			})
		},
		showMatchPositioningPointModal() {
			this.matchPositioningPointModal = true
			this.matchPositioningPointFormData = Object.assign(this.matchPositioningPointFormData, this.site.positioning_points.reduce((obj, point, key) => {
				obj[key] = {
					point_index: point.point_index,
					positioning_point_index: point.index,
				}
				return obj
			}, {}))
		},
		matchPositioningPointWithPoint() {
			if(!this.checkMatchPositioningPointFormData()) {
				return;
			}
			this.$set(this.call, "matchPositioningPointWithPoint", false)
			this.$axios.matchPositioningPointWithPoint({
				construction_site_id: this.site.id,
				user_id: this.$store.getters.currentUser.id,
				points: Object.values(this.matchPositioningPointFormData).filter(datum => {
					return datum.point_index && datum.positioning_point_index
				}),
			}, (response) => {
				alert('更新成功')
				this.reload()
				this.call.matchPositioningPointWithPoint = true
			}, (error) => {
				this.call.matchPositioningPointWithPoint = true
			})
		},
		checkMatchPositioningPointFormData() {
			let formData = Object.values(this.matchPositioningPointFormData).filter(datum => {
				return datum.point_index && datum.positioning_point_index
			})
			let formDataCount = formData.countBy('point_index')
			let duplicate = Object.keys(formDataCount).filter(key => formDataCount[key] > 1)
			let duplicateMatch = formData.filter(datum => duplicate.includes(`${datum.point_index}`))
			
			if(duplicate.length)
				alert(`測量點重複配對：\n${duplicateMatch.map(datum => `支距${datum.positioning_point_index} - 測量點 pt${datum.point_index}`).join("\n")}`)
			return !duplicate.length
		},
		showDeletePositioningPointModal(index) {
			this.deletePositioningPointModal = true
			this.deletePositioningPointData = this.site.positioning_points[index]
		},
		deletePositioningPoint() {
			this.$set(this.call, "deletePositioningPoint", false)
			this.$axios.deletePositioningPoint(this.deletePositioningPointData.id, (response) => {
				alert('刪除成功')
				this.reload()
				this.call.deletePositioningPoint = true
			}, (error) => {
				this.call.deletePositioningPoint = true
			})
		},
	}
}
</script>

<style scoped>
</style>