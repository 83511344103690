import Constants from "./Constants"

/**
 * GML設施物
 */
class Utility {
	/**
	 * @var {int}
	 */
	static count = 0
	/**
	 * 依type計數
	 * @var {int{}}
	 */
	static typeCount = {}

	constructor(feature, meta={}) {
		let categoryKey = feature.type.getTypeKey()
		/**
		 * @var {int}
		 */
		this.index = Utility.count;
		/**
		 * @var {int}
		 */
		this.typeIndex = Utility.typeCount[categoryKey] === undefined ? 0 : Utility.typeCount[categoryKey];
		Utility.count++
		Utility.typeCount[categoryKey] = this.typeIndex + 1

		/**
		 * @var {Feature}
		 */
		this.feature = feature
		this.categories = []
		this.configs = {}
		this.#setCategoryConfigs(`${meta.utilityCategory}.${categoryKey}`)
		this.meta = meta
		this.#setMetaData()
	}

	/**
	 * 重新計數
	 */
	static resetCounter() {
		Utility.count = 0;
		Utility.typeCount = {};
	}

	#getCategoryKey = () => {
		return this.feature.type.getTypeKey()
	}
	/**
	 * #private 初始化：依type取得此GML設施資料configs
	 * @param {String} categoryKey 
	 */
	#setCategoryConfigs = (categoryKey) => {
		let categoryKeys = categoryKey.split('.')
		let category = Constants.GML.UTILITY_CATEGORIES[categoryKeys.shift()]
		this.categories = [
			category,
			...categoryKeys.map(key => {
				category = category.categories[key]
				return category
			})
		]
		if(this.categories.latest) {
			let configs = Constants.GML.UTILITIES[category.utility]
			this.configs = {
				...configs,
				attributes: configs.attributes.map(attrKey => Constants.GML.ATTRIBUTES[attrKey])
			}
		}
		return this
	}

	/**
	 * 
	 * @param {String} templateKey 
	 * @returns 
	 */
	getGmlValues(templateKey) {
		let attributes = this.getGmlAttributes(templateKey)
		return attributes.reduce((obj, attr) => {
			let value = attr.value ? attr.value(this, templateKey) : undefined
			obj[attr.key] = value !== undefined ? value : attr.default
			return obj
		}, {})
	}

	/**
	 * 此GML設施模板中的屬性
	 * @param {String} templateKey 
	 * @returns 
	 */
	getGmlAttributes(templateKey) {
		return this.configs.attributes.filter(attr => templateKey ? (!attr.templates || Array.isArray(attr.templates) && attr.templates.includes(templateKey) || (attr.templates[templateKey] && !attr.templates[templateKey].length) || (attr.templates[templateKey] && attr.templates[templateKey].includes(this.getUtilityKey()))) : true)
	}

	/**
	 * #private 初始化meta data
	 */
	#setMetaData = () => {
		this.meta = {
			...this.meta,
			// GML類別碼
			categoryCode: this.categories.map(c => c.code).join(''),
			// GML識別碼
			identifyCode: this.#getIdentifyCode(),
			// GML設施編號
			featureNumber: this.#getFeatureNumber(),
		}
		return this
	}

	/**
	 * #private GML設施編號
	 */
	#getFeatureNumber = () => {
		return this.#getCodeFromFormat(this.meta.featureNumberFormat)
	}
	/**
	 * #private GML識別碼
	 */
	#getIdentifyCode = () => {
		return this.#getCodeFromFormat(this.meta.identifyCodeFormat)
	}
	/**
	 * #private 由格式代號取得GML識別碼或設施編號部分內容
	 */
	#getCodeFromFormat = (format) => {
		let formats = [ format ]
		if(Object.isObject(format)) {
			format = format[this.#getCategoryKey()] ?? (format.DEFAULT ?? format)
			formats = Array.isArray(format) ? format : [format]
		}
		// let values = format.split('.').map(key => this.#getFormattedValue(key))
		// return values.join('');
		// return values.join('').replace(/[^A-Za-z0-9]/g, '');
		return formats.map(format => format.split('.').map(key => this.#getFormattedValue(key)).join('')).filter(o => o).first
	}
	#getFormattedValue = (key) => {
		switch(true) {
			case key === 'FEATURE_NUMBER':
				return this.#getFeatureNumber();
			case key === 'FORM_FEATURE_NUMBER':
				return this.getData('featureNumber');
			case key === 'IDENTIFY_CODE':
				return this.#getIdentifyCode();
			case key === 'FORM_IDENTIFY_CODE':
				return this.getData('identifyCode');
			case key === 'MANAGEMENT':
				return this.getMetaData('management');
			case key === 'FEATURE_CODE':
				return this.#getFeatureCode();
			case key === 'PERMIT':
				return !this.meta.excavationPermit || this.meta.excavationPermit === '無路證' ? Math.round(Math.random()*1000000) : this.meta.excavationPermit;
			case key === 'PERMIT_WITHOUT_SIGNS':
				return `${!this.meta.excavationPermit || this.meta.excavationPermit === '無路證' ? Math.round(Math.random()*1000000) : this.meta.excavationPermit}`.replace(/[^A-Za-z0-9]/g, '');
			case key === 'INDEX':
				return this.index + 1;
			case key === 'FEATURE_INDEX':
				return this.typeIndex + 1;
			case key === 'PERMIT_UNIT':
				return this.meta.permitGrantedUnitNumber ? this.meta.permitGrantedUnitNumber : '';
			case /FEATURE_SEQUENCE-\d+/.test(key): {
				let categoryNumberKey = `${this.categories.latest.key.toLowerCase()}_number`.toCamelCase()
				return Number.padding((this.meta[categoryNumberKey] ? this.meta[categoryNumberKey] : 0) + this.typeIndex, Number(/FEATURE_SEQUENCE-(?<num>\d+)/.exec(key).groups.num))
			}
			case /DATE-.+/.test(key):
				return (new Date()).format(/DATE-(?<format>.+)/.exec(key).groups.format)
			default:
				return key;
		}
	}
	/**
	 * #private GML設施編號-設施代號
	 */
	#getFeatureCode = () => {
		let categoryKey = this.categories.latest.key
		let code = Constants.GML.FEATURE_CODE[categoryKey]
		if(!code) {
			switch(categoryKey) {
				case 'PIPELINE': {
					return this.feature.info.pipelineWidth >= Constants.GML.PIPELINE_MINIMUM_SPLIT_DIAMETER ? Constants.GML.FEATURE_CODE.PIPELINE_UPPER_MINIMUM_SPLIT_DIAMETER : Constants.GML.FEATURE_CODE.PIPELINE_UNDER_MINIMUM_SPLIT_DIAMETER
				}
			}
		}
		return code
	}

	getMetaData(key) {
		return key !== undefined ? this.meta[key] : this.meta
	}

	getData(key) {
		let data = this.feature.getData()
		return key !== undefined ? data[key] : data
	}

	/**
	 * 取設施物座標
	 * @returns {Position|Position[]}
	 */
	getCoordinate() {
		return this.feature.getPosition()
	}

	/**
	 * 依keys取得此設施物座標值
	 * @param {String[]} keys 
	 * @param {String} delim 
	 * @returns {String}
	 */
	getCoordinateString(keys=[]) {
		return this.feature.getPositionByKey(keys)
	}

	/**
	 * UTL key
	 */
	getUtilityKey() {
		return this.configs.key
	}

	/**
	 * @returns {AdditionalInfo}
	 */
	getAdditionalInfo() {
		return this.feature.getAdditionalInfo()
	}

	getType() {
		return this.feature.type
	}

	getName() {
		return this.feature.getName()
	}
	getNumber() {
		return this.feature.getNumber()
	}

	toString() {
		return this.feature.toString()
	}

	get() {
		return {
			...this.feature.get(),
			values: this.getGmlValues(),
		}
	}
}

export default Utility
