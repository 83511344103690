import Vue from 'vue'
import VueRouter from 'vue-router'

import Views from '@/views'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
	// {path:'/',redirect:'/login'},
	// {
	// 	path:'/',
	// 	name:'首頁',
	// 	redirect: '/profile',
	// 	component: Views.Home,
	// 	meta: {
	// 		requiresAuth: true,
	// 		breadcrumb: [{ text: '首頁', to: { name: '首頁' }, active: true }]
	// 	}
	// },
	{
		path: '/login',
		name: '登入',
		component: Views.Login
	},
	{
		path:'/logout',
		name:'登出',
		component: Views.Logout
	},
	{
		path: '/register',
		name: '註冊',
		component: Views.Register
	},
	{
		path: '/',
		component: Views.Page,
		props: true,
		meta: {
			requiresAuth: true,
			breadcrumb: [{ text: '首頁', to: { name: '首頁' }, active: true }]
		},
		children: [
			{
				path:'/',
				name:'首頁',
				component: Views.Home,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' }, active: true },
					]
				},
			},
			{
				path:'/profile',
				name:'個人資料',
				component: Views.Profile,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '個人資料', to: { name: '個人資料' }, active: true },
					]
				},
			},
		]
	},
	{
		path: '/manager',
		name: '系統管理',
		redirect: '/manager/member',
		component: Views.Page,
		props: true,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{ text: '首頁', to: { name: '首頁' } },
				{ text: '系統管理', to: { name: '系統管理' }, active: true },
			]
		},
		children: [
			{
				path: 'member',
				name: '人員管理',
				component: Views.MemberManager,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '系統管理', to: { name: '系統管理' } },
						{ text: '人員管理', to: { name: '人員管理' }, active: true },
					]
				},
			},
			{
				path: 'unit/list',
				name: '單位列表',
				component: Views.UnitManager,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '系統管理', to: { name: '系統管理' } },
						{ text: '單位列表', to: { name: '單位列表' }, active: true },
					]
				},
			},
			{
				path: 'unit/:unitID',
				name: '廠商管理',
				component: Views.UnitInfo,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '系統管理', to: { name: '系統管理' } },
						{ text: '單位列表', to: { name: '單位列表' } },
						{ text: '廠商管理', to: { name: '廠商管理' }, active: true },
					]
				},
			},
			{
				path: 'bid/list',
				name: '標案管理',
				component: Views.BidManager,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '系統管理', to: { name: '系統管理' } },
						{ text: '標案管理', to: { name: '標案管理' }, active: true },
					]
				},
			},
			{
				path: 'construction/list',
				name: '工程管理',
				component: Views.ConstructionManager,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '系統管理', to: { name: '系統管理' } },
						{ text: '工程管理', to: { name: '工程管理' }, active: true },
					]
				},
			},
			{
				path: 'data_type/list',
				name: '案件資料管理',
				component: Views.ProjectDataTypeManager,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '系統管理', to: { name: '系統管理' } },
						{ text: '案件資料', to: { name: '案件資料管理' }, active: true },
					]
				},
			},
			{
				path: 'dynamic_form/list',
				name: '表單管理',
				component: Views.DynamicFormManager,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '系統管理', to: { name: '系統管理' } },
						{ text: '表單管理', to: { name: '表單管理' }, active: true },
					]
				},
			},
			{
				path: 'dynamic_form/:formID',
				name: '表單編輯',
				component: Views.DynamicFormEditor,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '系統管理', to: { name: '系統管理' } },
						{ text: '表單管理', to: { name: '表單管理' } },
						{ text: '表單編輯', to: { name: '表單編輯' }, active: true },
					]
				},
			}
		]
	},
	{
		path: '/instrument',
		name: '儀器校驗',
		redirect: '/instrument/control_point',
		component: Views.Page,
		props: true,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{ text: '首頁', to: { name: '首頁' } },
				{ text: '儀器校驗', to: { name: '儀器校驗' }, active: true },
			]
		},
		children: [
			{
				path: 'control_point',
				name: '控制點',
				component: Views.ControlPointViewer,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '儀器校驗', to: { name: '儀器校驗' } },
						{ text: '控制點', to: { name: '控制點' }, active: true },
					]
				},
			},
		]
	},
	{
		path: '/group',
		name: '專案管理',
		component: Views.Page,
		redirect: '/group/list',
		props: true,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{ text: '首頁', to: { name: '首頁' } },
				{ text: '專案管理', to: { name: '專案管理' }, active: true },
			]
		},
		children: [
			{
				path: 'list',
				name: '專案列表',
				component: Views.GroupList,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '專案管理', to: { name: '專案管理' } },
						{ text: '專案列表', to: { name: '專案列表' }, active: true },
					]
				},
			},
			{
				path: 'new',
				name: '新增專案',
				component: Views.GroupCreator,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '專案管理', to: { name: '專案管理' } },
						{ text: '新增專案', to: { name: '新增專案' }, active: true },
					]
				},
			},
			{
				path: ':groupID',
				name: '專案',
				component: Views.Group,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '專案管理', to: { name: '專案管理' } },
						{ text: '專案資訊', to: { name: '專案資訊' }, active: true },
					]
				},
				children: [
					{
						path: 'setting',
						name: '專案設定',
						component: Views.GroupSetting,
						props: true,
						meta: {
							requiresAuth: true,
							breadcrumb: [
								{ text: '首頁', to: { name: '首頁' } },
								{ text: '專案管理', to: { name: '專案管理' } },
								{ text: '專案設定', to: { name: '專案設定' }, active: true },
							]
						},
					},
					{
						path: 'info',
						name: '專案資訊',
						component: Views.GroupInfo,
						props: true,
						meta: {
							requiresAuth: true,
							breadcrumb: [
								{ text: '首頁', to: { name: '首頁' } },
								{ text: '專案管理', to: { name: '專案管理' } },
								{ text: '專案資訊', to: { name: '專案資訊' }, active: true },
							]
						},
					},
					{
						path: 'project/list',
						name: '專案案件列表',
						component: Views.GroupProjectList,
						props: true,
						meta: {
							requiresAuth: true,
							breadcrumb: [
								{ text: '首頁', to: { name: '首頁' } },
								{ text: '專案管理', to: { name: '專案管理' } },
								{ text: '專案資訊', to: { name: '專案資訊' } },
								{ text: '案件列表', to: { name: '專案案件列表' }, active: true },
							]
						},
					},
					{
						path: 'project/list/form',
						name: '專案案件表單',
						component: Views.GroupProjectFormList,
						props: true,
						meta: {
							requiresAuth: true,
							breadcrumb: [
								{ text: '首頁', to: { name: '首頁' } },
								{ text: '專案管理', to: { name: '專案管理' } },
								{ text: '專案資訊', to: { name: '專案資訊' } },
								{ text: '案件列表', to: { name: '專案案件表單' }, active: true },
							]
						},
					},
					{
						path: 'project/new',
						name: '新增專案案件',
						component: Views.ProjectCreator,
						props: true,
						meta: {
							requiresAuth: true,
							breadcrumb: [
								{ text: '首頁', to: { name: '首頁' } },
								{ text: '專案管理', to: { name: '專案管理' } },
								{ text: '專案資訊', to: { name: '專案資訊' } },
								{ text: '新增案件', to: { name: '新增專案案件' }, active: true },
							]
						},
					},
					{
						path: 'project/:projectType/:projectID',
						name: '案件',
						component: Views.Project,
						props: true,
						meta: {
							requiresAuth: true,
							breadcrumb: [
								{ text: '首頁', to: { name: '首頁' } },
								{ text: '專案管理', to: { name: '專案管理' } },
								{ text: '專案資訊', to: { name: '專案資訊' } },
								{ text: '案件列表', to: { name: '專案案件列表' } },
								{ text: '案件資訊', to: { name: '案件資訊' }, active: true },
							]
						},
						children: [
							{
								path: 'info',
								name: '案件資訊',
								component: Views.ProjectInfo,
								props: true,
								meta: {
									requiresAuth: true,
									breadcrumb: [
										{ text: '首頁', to: { name: '首頁' } },
										{ text: '專案管理', to: { name: '專案管理' } },
										{ text: '專案資訊', to: { name: '專案資訊' } },
										{ text: '案件列表', to: { name: '專案案件列表' } },
										{ text: '案件資訊', to: { name: '案件資訊' }, active: true },
									]
								},
							},
							{
								path: 'sites',
								name: '工地管理',
								component: Views.ConstructionSiteManager,
								props: true,
								meta: {
									requiresAuth: true,
									breadcrumb: [
										{ text: '首頁', to: { name: '首頁' } },
										{ text: '專案管理', to: { name: '專案管理' } },
										{ text: '專案資訊', to: { name: '專案資訊' } },
										{ text: '案件列表', to: { name: '專案案件列表' } },
										{ text: '工地管理', to: { name: '工地管理' }, active: true },
									]
								},
							},
							{
								path: 'result',
								name: '測量成果',
								component: Views.ProjectResult,
								props: true,
								meta: {
									requiresAuth: true,
									breadcrumb: [
										{ text: '首頁', to: { name: '首頁' } },
										{ text: '專案管理', to: { name: '專案管理' } },
										{ text: '專案資訊', to: { name: '專案資訊' } },
										{ text: '案件列表', to: { name: '專案案件列表' } },
										{ text: '測量成果', to: { name: '測量成果' }, active: true },
									]
								},
							},
							{
								path: 'report',
								name: '案件報告',
								component: Views.ProjectReport,
								props: true,
								meta: {
									requiresAuth: true,
									breadcrumb: [
										{ text: '首頁', to: { name: '首頁' } },
										{ text: '專案管理', to: { name: '專案管理' } },
										{ text: '專案資訊', to: { name: '專案資訊' } },
										{ text: '案件列表', to: { name: '專案案件列表' } },
										{ text: '案件報告', to: { name: '案件報告' }, active: true },
									]
								},
							},
						]
					},
					{
						path: 'site/list',
						name: '專案工地列表',
						component: Views.GroupConstructionSiteList,
						props: true,
						meta: {
							requiresAuth: true,
							breadcrumb: [
								{ text: '首頁', to: { name: '首頁' } },
								{ text: '專案管理', to: { name: '專案管理' } },
								{ text: '工地列表', to: { name: '工地列表' }, active: true },
							]
						},
					},
				]
			}
		]
	},
	{
		path: '/project',
		component: Views.Page,
		props: true,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{ text: '首頁', to: { name: '首頁' } },
				{ text: '案件管理', to: { name: '案件管理' }, active: true },
			]
		},
		children: [
			{
				path: '/project',
				name: '案件管理',
				component: Views.ProjectPage,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '案件管理', to: { name: '案件管理' }, active: true },
					]
				},
			},
			{
				path: 'list',
				name: '案件列表',
				component: Views.ProjectList,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '案件管理', to: { name: '案件管理' } },
						{ text: '案件列表', to: { name: '案件列表' }, active: true },
					]
				},
			},
			{
				path: 'new',
				name: '新增案件',
				component: Views.ProjectCreator,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '案件管理', to: { name: '案件管理' } },
						{ text: '新增案件', to: { name: '新增案件' }, active: true },
					]
				},
			},
			{
				path: 'site/list',
				name: '工地列表',
				component: Views.ConstructionSiteList,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '案件管理', to: { name: '案件管理' } },
						{ text: '工地列表', to: { name: '工地列表' }, active: true },
					]
				},
			},
		]
	},
	{
		path: '/assign',
		name: '派工系統',
		redirect: '/assign/map',
		component: Views.Page,
		props: true,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{ text: '首頁', to: { name: '首頁' } },
				{ text: '派工系統', to: { name: '派工系統' }, active: true },
			]
		},
		children: [
			{
				path: 'member',
				name: '外務人員',
				component: Views.AssignMemberManager,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '派工系統', to: { name: '派工系統' } },
						{ text: '外務人員', to: { name: '外務人員' }, active: true },
					]
				},
			},
			{
				path: 'map',
				name: '派工頁面',
				component: Views.Assignment,
				props: true,
				meta: {
					requiresAuth: true,
					breadcrumb: [
						{ text: '首頁', to: { name: '首頁' } },
						{ text: '派工系統', to: { name: '派工系統' } },
						{ text: '派工頁面', to: { name: '派工頁面' }, active: true },
					]
				},
			},
		]
	},
	{
		path: '/measure/map/:siteID',
		name: '編輯案件成果',
		component: Views.ProjectMapEditor,
		props: true,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{ text: '首頁', to: { name: '首頁' } },
				{ text: '專案管理', to: { name: '專案管理' } },
				{ text: '專案資訊', to: { name: '專案資訊' } },
				{ text: '案件列表', to: { name: '專案案件列表' } },
				{ text: '測量成果', to: { name: '測量成果' }, },
				{ text: '編輯成果', to: { name: '編輯成果' }, active: true },
			]
		},
	},
]

const router = new VueRouter({
	routes,
	mode:"history"
})


router.beforeEach(async(to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {

		let isLogin = store.getters.isLoggedIn

		// 驗證是否已經登入 (看自己的實作機制)
		// 可能是檢查 localStorage / vuex 狀態
		
		if (isLogin === false && from.path !== '/login') {
			next({
				path: '/login',
				query: { redirect: to.fullPath }
			})
		} else {
			next() //必須向下走
		}

	} else {
		next() // 必須向下走
	}
})

export default router
