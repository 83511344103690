import Position from './Position';
import Type from './Type';
import Info from './Info';
import AdditionalInfo from './AdditionalInfo';
import Constants from "./Constants"
import { GetPointTargets } from './utils'

/**
 * 測量點
 */
class Node {
	/**
	 * @var {int}
	 */
	static count = 0
	/**
	 * @param {string} name
	 * @param {Position} position
	 * @param {Type} type
	 */
	constructor(collection, name, position, type, info, data) {
		/**
		 * @var NodeCollection
		 */
		this.collection = collection
		/**
		 * @var {string}
		 */
		this.name = name
		/**
		 * @var {int}
		 */
		this.index = Node.count;
		Node.count++
		/**
		 * 座標位置
		 * @var {Position}
		 */
		this.position = position
		/**
		 * 此測量點的種類
		 * @var {Type}
		 */
		this.type = type

		/**
		 * 點位資訊
		 * @var {Info[]}
		 */
		this.info = info

		/**
		 * 目標點資訊
		 * @var {Info{}}
		 */
		this.targetInfos = {}
		let targetInfos = GetPointTargets(data)
		Object.keys(targetInfos).forEach(key => {
			this.addTarget(key, Info.newByString(targetInfos[key], Info.format[data.pointInfoFormat] ?? Info.format.ROUND_PIPELINE))
		})

		/**
		 * 目標點
		 * @var {Node{}}
		 */
		this.targetNodes = {}

		/**
		 * 下一連接點（目標為此點的點位）
		 * @var {Node{}}
		 */
		this.connectionNodes = {}

		/**
		 * @var {AdditionalInfo}
		 */
		this.additionalInfo = new AdditionalInfo(data)

		this.data = {}
		this.setData(data)

		this.rawData = data
	}

	/**
	 * 重新計數
	 */
	static resetCounter() {
		Node.count = 0;
	}

	/**
	 * @param {Info} info
	 */
	addInfo(info) {
		this.info = info;
		return this
	}

	/**
	 * 新增目標點Info
	 * @param {string} target
	 * @param {Info} info
	 */
	addTarget(target, info, throwError=false) {
		if(this.checkInfo(info, throwError)) {
			this.targetInfos[target] = info
		}
		return this
	}

	/**
	 * 設定目標點Node
	 * @param {Node} node
	 */
	setTargetNode(node) {
		if(this.targetInfos[node.name]) {
			this.targetNodes[node.name] = node
		}
		return this
	}

	/**
	 * 新增連接點
	 * @param {Node} node
	 * @param {Info} info
	 */
	addConnection(node, info, throwError=false) {
		if(this.checkInfo(info, throwError)) {
			this.connectionNodes[node.name] = node
		}
		return this
	}

	/**
	 * 檢查此點是否含有相同Info
	 * @param {Info} info 
	 * @returns 
	 */
	checkInfo(info, throwError=false) {
		let checked = Info.equals(this.info, info)
		if(!checked && throwError) {
			throw new Error(`連接點資訊與點位不符: ${this.name} (${info.toString()})`);
		}
		return checked;
	}

	/**
	 * 設定是否點位附加資訊
	 * @param {String} key
	 * @param {Boolean} value
	 */
	setAdditionalInfo(key, value) {
		this.additionalInfo.set(key, value)
		return this
	}

	setData(data) {
		[
			'length',
			'width',
			'height',
			'hole_depth',
			'content',
			'identify_code',
			'feature_number',
			'size_unit',
			'hydrant_diameter',
			'other_facility_length',
			'other_facility_width',
			'other_facility_height',
			'note',
		].forEach(key => {
			let dataKey = key.toCamelCase()
			let value = data[key] !== undefined ? data[key] : (data[dataKey] !== undefined ? data[dataKey] : this.data[dataKey])
			if(value !== undefined ){
				if(typeof value === 'object') {
					value = value.VALUES[this.type.getKeys()[value.KEY]]
				}
				if(value !== undefined ){
					this.data[dataKey] = value
				}
			}
		})
		return this
	}
	
	/**
	 * 是否是設施物
	 * @returns {Boolean}
	 */
	isFacility() {
		return this.type.isFacilityType()
	}
	/**
	 * 是否是管線
	 * @returns {Boolean}
	 */
	isPipeline() {
		return !this.isFacility()
	}
	/**
	 * 是否是管線且管徑>100
	 * @returns {Boolean}
	 */
	isPipelineOverDiameter() {
		return this.isPipeline() && this.info.pipelineWidth >= Constants.GML.PIPELINE_MINIMUM_SPLIT_DIAMETER
	}
	isWatershedWithBothOverDiameter() {
		let nodes = this.collection.getCopiedNodes(this.name).filter(node => node.isPipelineOverDiameter())
		return nodes.length >= 2 || nodes.length && Object.flatMap([
			...nodes.mapValues('targetNodes'),
			...nodes.mapValues('connectionNodes'),
		], Object.keys).uniq().length >= 3
	}
	/**
	 * 是否是管線且位於設施物下
	 * @returns {Boolean}
	 */
	isPipelineUnderFacility() {
		return this.isPipeline() && Object.values(this.getConnectionNodes()).some(node => node.isFacility())
	}
	/**
	 * 是否是管線且位於過溝蓋板下
	 * @returns {Boolean}
	 */
	isPipelineAcrossDitchCover() {
		return this.isPipeline() && Object.values(this.getConnectionNodes()).some(node => node.isDitchCover())
	}
	/**
	 * 是否是管線且為套筒
	 * @returns {Boolean}
	 */
	isPipelineSocket() {
		return this.isPipeline() && this.type.getFormTypeKey() === 'SOCKET'
	}
	/**
	 * 是否是管線且為分水點
	 * @returns {Boolean}
	 */
	isPipelineWatershed() {
		return this.isPipeline() && this.getCopies().length > 1
	}
	/**
	 * 是否是過溝蓋板
	 * @returns {Boolean}
	 */
	isDitchCover() {
		return this.isFacility() && this.type.getFormTypeKey() === 'DITCH_COVER'
	}
	getFacilityAbovePipeline() {
		return this.isPipelineUnderFacility() ? Object.values(this.getConnectionNodes()).find(node => node.isFacility()) : undefined
	}

	toString() {
		return this.name
	}

	getData(key) {
		return (key ?? false) ? this.data[key] : this.data
	}
	getNumber() {
		return this.data.featureNumber
	}

	getCopies() {
		return this.collection.getCopiedNodes(this.name)
	}

	getConnectionNodes() {
		return this.getCopies().reduce((obj, node) => {
			return {
				...node.connectionNodes,
				...obj,
			}
		}, {})
	}

	getTargetNodes() {
		return this.getCopies().reduce((obj, node) => {
			return {
				...node.targetNodes,
				...obj,
			}
		}, {})
	}

	get(detail=true) {
		return {
			// name: this.name,
			number: this.getNumber(),
			coordinate: this.position.get(),
			...detail ? {
				// targets: Object.values(Object.map(this.targetNodes, node => node.get(false))),
				...this.isPipelineUnderFacility() || this.isPipelineAcrossDitchCover() ? {
					connection: this.getFacilityAbovePipeline().get(false)
				} : {},
				values: this.data,
			} : {},
		}
	}
}

export default Node
