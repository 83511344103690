import Feature from './Feature'
import Type from './Type'
import Node from './Node'
import Info from './Info'
import AdditionalInfo from './AdditionalInfo'
import Position from './Position'
import Utility from './Utility'
import Constants from './Constants'

/**
 * 設施物-線段
 * @extends Feature 
 */
class Line extends Feature {
	/**
	 * @param {Node[]} nodes
	 * @param {Info} info
	 */
	constructor(collection, nodes, info) {
		let type = Type.combineTypes(nodes.reduce((obj, node) => {
			obj[node.name] = node.type
			return obj
		}, {}))
		/**
		 * @var {Type} type 
		 * @var {Info} info 
		 */
		super(collection, type, info)
		/**
		 * @var {Node[]} node 
		 */
		this.nodes = []
		if(!nodes.every(node => this.#checkNode(node))) {
			throw new Error(`無法連接點位，連接點資訊與點位不符: ${nodes.reverse().mapValues('name').join('->')}`);
		}
		nodes.forEach(node => this.#pushNode(node))
	}

	#updateType = () => {
		this.type = Type.combineTypes(this.nodes.reduce((obj, node) => {
			obj[node.name] = node.type
			return obj
		}, {}))
		return this
	}

	/**
	 * @param {Node}
	 */
	#checkNode = (node, throwError=false) => {
		return !this.nodes.find(n => n.name === node.name) && node.checkInfo(this.info, throwError);
	}

	/**
	 * @param {Node}
	 */
	#pushNode = (node) => {
		if(this.#checkNode(node, true)) {
			this.nodes.push(node)
		}
		this.#updateType()
		return this
	}

	/**
	 * @param {Node}
	 */
	#unshiftNode = (node) => {
		if(this.#checkNode(node, true)) {
			this.nodes.unshift(node)
		}
		this.#updateType()
		return this
	}

	/**
	 * @param {Line}
	 */
	#pushLine = (line) => {
		if(this.checkInfo(line.info, true)) {
			line.nodes.forEach(node => this.#pushNode(node))
		}
		return this
	}
	/**
	 * @param {Line}
	 */
	#unshiftLine = (line) => {
		if(this.checkInfo(line.info, true)) {
			line.nodes.map(o=>o).reverse().forEach(node => this.#unshiftNode(node))
		}
		return this
	}

	/**
	 * 反轉
	 */
	reverse() {
		this.nodes.reverse()
		return this
	}
	
	/**
	 * 檢查info是否相同
	 * @param {Info} info 
	 * @param {Boolean} throwError 是否丟出錯誤
	 * @returns 
	 */
	checkInfo(info, throwError=false) {
		let checked = Info.equals(this.info, info)
		if(!checked && throwError) {
			throw new Error(`管線資料錯誤，新增線段資訊不合: ${this.info.toString()}、${info.toString()}`)
		}
		return checked;
	}

	/**
	 * 檢查相連
	 * @param {Line} line
	 * @returns {Boolean}
	 */
	checkConnection(line, throwError=false){
		return (this.nodes.first.name === line.nodes.latest.name || line.nodes.first.name === this.nodes.latest.name || this.nodes.first.name === line.nodes.first.name || line.nodes.latest.name === this.nodes.latest.name) && this.checkInfo(line.info, throwError) && Type.equals(this.type, line.type) && this.checkAcyclicLine(line)
	}
	/**
	 * 檢查是否無環
	 * @param {Line} line
	 * @returns {Boolean}
	 */
	checkAcyclicLine(line) {
		return line.nodes.filter(node => !this.#checkNode(node)).length <= 1;
	}
	/**
	 * 相連方向
	 * @param {*} line 
	 * @returns {Number} 0: 無法相連，1: 連接起點，2: 連接終點
	 */
	checkConnectionSide(line) {
		if(!this.checkConnection(line)) return 0;
		if(this.nodes.first.name === line.nodes.latest.name || this.nodes.first.name === line.nodes.first.name) return 1;
		else if(line.nodes.first.name === this.nodes.latest.name || line.nodes.latest.name === this.nodes.latest.name) return 2;
		return 0;
	}

	getConnectionPriority(line) {
		if(this.checkConnection(line)) {
			let r1 = undefined;
			let r2 = undefined;
			let l1 = undefined;
			let l2 = undefined;
			if(this.nodes.first.name === line.nodes.first.name) {
				r1 = this.getDirection(0); l1 = this.getLength(0);
				r2 = line.getDirection(0, true); l2 = line.getLength(0);
			}
			else if(this.nodes.first.name === line.nodes.latest.name) {
				r1 = this.getDirection(0); l1 = this.getLength(0);
				r2 = line.getDirection(-1); l2 = line.getLength(-1);
			}
			else if(this.nodes.latest.name === line.nodes.first.name) {
				r1 = this.getDirection(-1); l1 = this.getLength(-1);
				r2 = line.getDirection(0);  l2 = line.getLength(0); 
			}
			else if(this.nodes.latest.name === line.nodes.latest.name) {
				r1 = this.getDirection(-1); l1 = this.getLength(-1);
				r2 = line.getDirection(-1, true);  l2 = line.getLength(-1); 
			}
			return r1 !== undefined && r2 !== undefined ? ((r1.X * r2.X + r1.Y * r2.Y) / (l1 * l2)) : -1;
		}
		return -1;
	}

	/**
	 * 連接兩線
	 * @param {Line} line
	 * @returns {Boolean}
	 */
	connectLine(line) {
		if(this.checkConnection(line, true)) {
			if(this.nodes.first.name === line.nodes.latest.name) {
				this.#unshiftLine(line)
			}
			else if(line.nodes.first.name === this.nodes.latest.name) {
				this.#pushLine(line)
			}
			else if(this.nodes.first.name === line.nodes.first.name) {
				line.reverse()
				this.#unshiftLine(line)
			}
			else if(line.nodes.latest.name === this.nodes.latest.name) {
				line.reverse()
				this.#pushLine(line)
			}
		}
		return this
	}

	/**
	 * 線段長度
	 */
	getLength(index=undefined) {
		if(index === undefined) {
			return Position.getPointsDistance(this.nodes.mapValues('position'))
		}
		let [start, end] = this.getSlicedNodes(index);
		return start && end ? Position.getPointsDistance([start, end].mapValues('position')) : Position.getPointsDistance(this.nodes.mapValues('position'))
	}
	getSurfaceLength(index=undefined) {
		if(index === undefined) {
			return Position.getPointsSurfaceDistance(this.nodes.mapValues('position'))
		}
		let [start, end] = this.getSlicedNodes(index);
		return start && end ? Position.getPointsSurfaceDistance([start, end].mapValues('position')) : Position.getPointsSurfaceDistance(this.nodes.mapValues('position'))
	}
	/**
	 * 線段方向
	 */
	getDirection(index=undefined, reverse=false) {
		let [start, end] = this.getSlicedNodes(index);
		if(reverse) {
			let tmp = start
			start = end
			end = tmp
		}
		return start && end ? Position.getDirection(start.position, end.position) : undefined
	}
	getSlicedNodes(index=undefined) {
		let start = undefined;
		let end = undefined;
		if(index === undefined) {
			start = this.nodes.first;
			end = this.nodes.latest;
		} else if(index >= 0) {
			start = Array.getByIndex(this.nodes, index)
			end = Array.getByIndex(this.nodes, index + 1)
		} else if(index < 0) {
			start = Array.getByIndex(this.nodes, index - 1)
			end = Array.getByIndex(this.nodes, index)
		}
		return [start, end];
	}

	/**
	 * @returns {Utility[]}
	 */
	toUtilities(meta, rules=[]) {
		let nodes = this.#getSplitNodes(rules)
		let lines = this.#splitByNodes(nodes)
		return lines.map(line => {
			let utility = new Utility(line, meta)
			return utility
		})
	}

	/**
	 * 此管線的分割點
	 * @param {*} rules 
	 * @returns 
	 */
	#getSplitNodes = (rules=[]) => {
		rules = [...new Set([
			...rules,
			...Constants.GML.PIPELINE_SPLIT_DEFAULT_RULES
		])].map(rule => Constants.GML.PIPELINE_SPLIT_RULES[rule])
		return this.nodes.filter(node => rules.some(rule => rule(node, this)))
	}

	/**
	 * 指定幾個節點將此管線分割成不同管線
	 * @param {Node[]} nodes 
	 * @returns {Line[]}
	 */
	#splitByNodes = (nodes) => {
		let groups = [];
		let names = nodes.map(n=>n.name);
		// 找出此管線中含指定測量點的位置->求出切割的位置
		let indexes = [...new Set(
			names.map(name => this.nodes.findIndex(node => node.name === name)).filter(index => index > 0 && index < this.nodes.length - 1)
		)]
		if(!indexes.length) return [this];
		indexes.sort((a, b) => a - b)
		indexes.push(this.nodes.length - 1)
		let lastIndex = 0
		indexes.forEach(index => {
			// 拆開來的點需要交疊故+1
			groups.push(this.nodes.slice(lastIndex, index + 1))
			lastIndex = index
		})
		return groups.map(nodes => new Line(this.collection, nodes, this.info))
	}

	/**
	 * 此管線座標
	 * @returns {Position[]}
	 */
	getPosition() {
		return this.nodes.map(node => node.position)
	}

	/**
	 * 依keys取得此管線座標值
	 * @param {String[]} keys 
	 * @param {String} delim 
	 * @returns {String}
	 */
	getPositionByKey(keys=[], delim=' ') {
		return this.nodes.map(node => node.position.getPositionByKey(keys, delim)).join(' ')
	}

	getData() {
		let keys = [ 'identifyCode' ]
		let nodesData = Object.map(keys.filter(key => this.nodes.first.data[key] && this.nodes.latest.data[key]).keyBy(), key => `${this.nodes.first.data[key]}-${this.nodes.latest.data[key]}`)
		return {
			...super.getData(),
			...this.nodes.first.data,
			...nodesData,
			length: this.getLength(),
			surfaceLength: this.getSurfaceLength(),
			startPointNumber: this.nodes.first.name,
			endPointNumber: this.nodes.latest.name,
		}
	}
	getName() {
		return this.nodes.map(node=>node.toString()).join('->')
	}
	getNumber() {
		let nodes = [this.nodes.first, this.nodes.latest]
		return nodes.every(node => node.isPipelineUnderFacility()) ? nodes.map(node => (node.getFacilityAbovePipeline().getNumber() ?? '').replace('-', '')).join('-') : undefined
	}

	getNodeData(index, key) {
		let node = this.nodes.get(index)
		return node ? node.getData(key) : undefined
	}

	/**
	 * @returns {AdditionalInfo}
	 */
	getAdditionalInfo() {
		return AdditionalInfo.combine(this.nodes.mapValues('additionalInfo'))
	}
	
	toString(){
		return `${this.type.getTypeKey()}: ${this.getName()}`;
	}

	get() {
		return {
			...super.get(),
			startNode: this.nodes.first.get(),
			endNode: this.nodes.latest.get(),
		}
	}
}
export default Line