<template>
	<div>
		<loading mode="fixed" image="1" size="lg" v-if="downloading"/>
		<b-card class="shadow" id="Card" :title="title">
			<loading class="info-wrapper" v-if="loading"/>
			<!-- <bv-table v-else :columns="table.columns" :data="projectList" :configs="table.configs" :actions="table.actions" search></bv-table> -->
			<paging-table ref="table" v-else :columns="tableColumns" :data="projects" :configs="table.configs" :detail-columns="tableDetailColumns" :paging="table.paging" :actions="table.actions" :options="table.options" :sortOptions="sorting" :searchOptions="projectFilter"  @changePage="changePage" @sort="getData" @search="getData"  @row-selected="rowSelected"></paging-table>
			<b-row class="col-12 justify-content-start align-items-center">
				<b-button class="form-btn submit-btn" @click="button.onclick" v-for="(button, key) in exportButtons" :key="key">下載{{button.label}}</b-button>
				<template v-if="editable">
					<b-button class="form-btn clear-btn" v-if="!table.configs.selectable" @click="table.configs.selectable=true">設定案件估驗時間</b-button>
					<div class="info-wrapper" v-else>
						<b-button class="form-btn delete-btn" @click="cancelSelect">取消</b-button>
						<label class="ml-3">目前案件選擇數量：{{setAcceptedAtData.length}}</label>
						<b-button class="form-btn clear-btn" @click="setAcceptedAt">確認設定估驗時間</b-button>
					</div>
				</template>
			</b-row>
		</b-card>
		<modal v-model="excelExportModal" centered size="xl" @ok="exportExcel" title="輸出Excel報表">
			<div style="min-height: 50vh">
				<project-filter v-model="excelExportFormData.conditions" :data-key="excelFilterColumn.key" :label="excelFilterColumn.label"></project-filter>
				<input-form class="p-0 col-12" :options="excelFormOptions" :actions="{submit:false,cancel:false}" v-model="excelExportFormData"></input-form>
			</div>
		</modal>
		<modal v-model="tpwProjectDailyReceivedExcelExportModal" centered size="xl" @ok="exportTpwProjectDailyReceivedExcel" title="輸出每日收件檢核表">
			<div style="min-height: 50vh">
				<input-form class="p-0 col-12" :options="tpwProjectDailyReceivedExcelFormOptions" :actions="{submit:false,cancel:false}" v-model="tpwProjectDailyReceivedExcelExportFormData"></input-form>
			</div>
		</modal>
		<modal v-model="tpwProjectUnitReportSubmittedExcelModal.show" centered size="xl" @ok="exportTpwProjectUnitReportSubmittedExcel" title="輸出報告繳交紀錄Excel">
			<div style="min-height: 50vh">
				<input-form class="p-0 col-12" :options="tpwProjectUnitReportSubmittedExcelFormOptions" :actions="{submit:false,cancel:false}" v-model="tpwProjectUnitReportSubmittedExcelModal.formData"></input-form>
			</div>
		</modal>
		<modal v-model="tpwProjectPavedInformationExcelModal.show" centered size="xl" @ok="exportTpwProjectPavedInformationExcel" title="輸出銑鋪資料清冊">
			<div style="min-height: 50vh">
				<project-filter v-model="tpwProjectPavedInformationExcelModal.formData" :data-key="excelFilterColumn.key" :label="excelFilterColumn.label"></project-filter>
			</div>
		</modal>
	</div>
</template>

<script>
import { deepCopy, isEmpty, sortArray, checkAllTrue } from '@/utils/assist';
import BvTable from '@/components/Table/BvTable.vue'
import PagingTable from '@/components/Table/PagingTable.vue'
import ProjectFilter from '@/components/Project/List/ProjectFilter.vue'
import InputForm from '@/components/Input/InputForm';
import Modal from '@/components/Modal/Modal'
import Loading from '@/components/Loading/Loading.vue'
import fileManager from '@/utils/file';
import ProjectConfigs from '@/projects';

export default {
	name: 'ProjectList',
	components:{
		BvTable,
		PagingTable,
		ProjectFilter,
		InputForm,
		Modal,
		Loading
	},
	props: {
		group: {
			type: Object,
		},
		permission: {
			type: Object,
		},
		title: {
			type: String,
			default: ""
		},
		filter: {
			type: Object,
			default: () => { return {}; }
		}
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			table: {
				detailColumns: [
					{ key: 'excavation_permit', label: "路證編號" },
					{ key: 'expected_constructed_at', label: "施工日期" },
					{ key: 'created_at', label: "新增日期" },
					{ key: 'assigned_at', label: "派工日期" },
					{ key: 'first_received_at', label: "首次收件日期" },
					{ key: 'received_at', label: "收件日期" },
					{ key: 'status', label: "案件狀態" },
					{ key: 'party_a_name', label: "甲方",  },
					{ key: 'contractor_name', label: "承包商", },
					{ key: 'pipeline_center_name', label: "道管中心" },
					{ key: 'address', label: "施工地點" },
					{ key: 'bid_name', label: "標案名稱" },
					{ key: 'construction_name', label: "工程名稱" },
					{ key: 'supervisor_name', label: '監工' },
					{ key: 'pipeline_length', label: '管線長度', format: v => (v !== null) && v >= 0 ? `${Number(v.toFixed(3))}M` : '-' },
				],
				columns: [
					{ key: 'key', label: 'ID', isRowHeader: true, stickyColumn: true, sortable: true, searchable: true, hide: this.$store.getters.isSupervisor },
					{ key: 'excavation_permit', label: '路證編號', isRowHeader: true, stickyColumn: true, sortable: true, searchable: true },
					{ key: 'created_at', label: '新增日期', sortable: true, searchable: true },
					{ key: 'assigned_at', label: '派工日期', sortable: true, searchable: true },
					{ key: 'first_received_at', label: '首次收件日期', sortable: true, searchable: true },
					{ key: 'received_at', label: '收件日期', sortable: true, searchable: true },
					{ key: 'status', label: '案件狀態', sortable: true, searchable: true },
					{ key: 'construction_name', label: '工程', sortable: true, searchable: true },
					{ key: 'contractor_name', label: '承包商', sortable: true, searchable: true },
					{ key: 'address', label: '施工地點', sortable: true, searchable: true },
					{ key: 'pipeline_length', label: '管線長度', format: v => (v !== null) && v >= 0 ? `${Number(v.toFixed(3))}M` : '-' },
				],
				actions: [
					{
						//icon, label
						button: { type: 'icon', icon: "fas fa-file", label: "資料" },
						//function, link, route
						action: { type: 'link', link: this.getProjectSceneUrl, target: "_blank" },
						hide: (index) => !this.projects[index].scene_pictures_url
					},
					{
						//icon, label
						button: { icon: "fas fa-cog", label: "管理" },
						//function, link, route
						action: { type: 'route', route: this.routeToProjectInfo }
					}
				],
				configs: {
					actionsColumn: true,
					rowDetail: true,
					busy: true,
					showStatistic: true,
					selectable: false,
					selectMode: 'multi',
				},
				paging: {
					perPage: 10,
					pageLimit: 9,
				},
				options: {}
			},
			projects: [],
			call: { getUnitList: false, getProjectList: false },
			loading: true,
			project_category_keys: ["party_a"],
			unit_list: [],
			paging: {
				page: 1,
				per_page: 10,
			},
			sorting: {},
			projectFilter: deepCopy(this.filter),
			excelExportModal: false,
			excelExportFormData: {
				conditions: {
					project_group_id: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
				},
				excelData: []
			},
			tpwProjectDailyReceivedExcelExportModal: false,
			tpwProjectDailyReceivedExcelExportFormData: {
				conditions: {
					project_group_id: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
				},
				first_received_at: new Date().toDateString(),
			},
			tpwProjectUnitReportSubmittedExcelModal: {
				show: false,
				formData: {},
			},
			tpwProjectPavedInformationExcelModal: {
				show: false,
				formData: {},
			},
			downloading: false,
			setAcceptedAtData: [],
		}
	},
	created() {
		this.getUnitList()
		this.getProjectList()
	},
	destroyed() {
	},
	mounted() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value)
			}
		},
		apiQuery: {
			deep: true,
			handler(value) {
				this.getProjectList();
			}
		},
		paging: {
			deep: true,
			handler(value) {
				this.getProjectList();
			}
		},
		'table.configs.selectable': {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = true;
				this.$nextTick(() => {
					this.loading = false
				})
			}
		}
	},
	computed: {
		editable() {
			return this.checkPermission('edit') ? true : false
		},
		projectTypeEnum() {
			return this.$store.getters.enum(this.group.project_type_enum)
		},
		projectTypeStatusEnumKey() {
			let key = [this.projectTypeEnum.data.name, 'status'].filter(o => o).join('_')
			return `project.${key}`
		},
		permissionType() {
			return this.permission ? this.permission.permission_type_index : -1;
		},
		tableColumns() {
			return this.table.columns.filter(column => {
				let key = column.key
				return [
					'key',
					...ProjectConfigs.listParams(this.group.project_type_key),
				].includes(key)
			})
		},
		tableDetailColumns() {
			return this.table.detailColumns.filter(column => {
				let key = column.key
				return [
					'key',
					...ProjectConfigs.listParams(this.group.project_type_key),
				].includes(key)
			})
		},
		apiQuery() {
			return {
				...(typeof this.projectFilter.key === 'undefined') ? this.projectFilter : {
					...this.projectFilter,
					id: this.projectFilter.key
				},
				...this.paging,
				orderBy: {
					created_at: 'desc',
					...(typeof this.sorting.key === 'undefined') ? this.sorting : {
						...this.sorting,
						id: this.sorting.key
					},
				},
			}
		},
		projectListExports() {
			return ProjectConfigs.listExports(this.group.project_type_key)
		},
		exportButtons() {
			const buttons = [
				{ key: 'list_csv', label: '案件列表csv', visible: this.editable, onclick: this.exportProjectCsv },
				{ key: 'list_excel', label: 'excel清冊', visible: true, onclick: this.showExcelExportModal },
				{ key: 'tpw_daily_received_excel', label: '每日收件檢核表', visible: this.editable, onclick: this.showTpwProjectDailyReceivedExcelModal },
				{ key: 'tpw_project_implementation_excel', label: '專案執行狀況', visible: this.editable, onclick: this.exportTpwProjectImplementationExcel },
				{ key: 'tpw_report_submitted_excel', label: '報告繳交紀錄', visible: this.editable, onclick: this.showTpwProjectUnitReportSubmittedExcelModal },
				{ key: 'tpw_gml2_uncommitted_excel', label: '尚未收到GML2清冊', visible: this.editable, onclick: this.exportTpwProjectGml2UncommittedExcel },
				{ key: 'tpw_paved_information_excel', label: '銑鋪資料清冊', visible: this.editable, onclick: this.showTpwProjectPavedInformationExcelModal },
			]
			return buttons.filter(btn => this.projectListExports.includes(btn.key) && (btn.visible ?? true))
		},
		excelFilterColumn() {
			const columns = { first_received_at: "首次收件日期", assigned_at: "派工日期", created_at: "新增日期", }
			let key = Object.findKey(columns, (v, k) => Object.keys(this.excelColumns).includes(k)) ?? 'created_at'
			return { key: key, label: columns[key] }
		},
		excelColumns() {
			const columns = {
				excavation_permit_name: "路證編號", expected_constructed_at: "施工日期", assigned_at: "派工日期", first_received_at: "收件日期", 
				status: "案件狀態", party_a_name: "甲方", party_a_branch_name: "甲方區處", contractor_name: "承包商", supervisor_name: "監工名稱",
				pipeline_center_name: "道管中心", address: "施工地點", bid_name: "標案名稱", construction_name: "工程名稱",
				not_passed_construction_data: "施工資料不全",
			}
			return Object.filter(columns, (value, key) => [
				...ProjectConfigs.dataParams(this.group.project_type_key),
				'not_passed_construction_data',
			].includes(key))
		},
		excelFormOptions() {
			return [
				{ key: "excelData", type: "checkbox", label: "報表欄位", required: true, options: Object.keys(this.excelColumns).map(key => {return {value: key, text:this.excelColumns[key]}}) },
			]
		},
		tpwProjectDailyReceivedExcelFormOptions() {
			return [
				{ key: "first_received_at", type: "date", label: "收件日期", required: true },
			]
		},
		groupReportTypes() {
			return this.group.project_unit_report_types.sortBy('project_data_type_id')
		},
		tpwProjectUnitReportSubmittedExcelFormOptions() {
			return [
				{ key: 'submitted_start_at', type: 'date', label: '繳交起日', required: true },
				{ key: 'submitted_end_at', type: 'date', label: '繳交迄日', required: true },
				{ key: 'project_data_type_id', type: 'select', label: '報告', required: true, options: this.groupReportTypes.uniqBy('project_data_type_id'), keys: {value: 'project_data_type_id', text: 'project_data_type.name'} },
				{ key: 'unit_type', type: 'select', label: '繳交單位', required: true, options: this.getGroupReportUnitTypes(this.tpwProjectUnitReportSubmittedExcelModal.formData.project_data_type_id), keys: {value: 'unit_type_index', text: 'unit_type'} },
			]
		},
	},
	methods:{
		getUnitList() {
			this.call.getUnitList = false
			let types_index = this.$store.getters.enum('unit.type').filter(type => this.project_category_keys.includes(type.key)).map(type => type.index)
			this.$axios.getUnitList({types_index: types_index}, (response) => {
				this.unit_list = response.data;
				this.call.getUnitList = true
			}, (error) => {
				this.call.getUnitList = true
			})
		},
		getProjectList() {
			this.call.getProjectList = false;
			this.$axios.getProjectList(this.apiQuery, (response) => {
				// sortArray(response.data, 'created_at', -1)
				this.projects = response.data.map(project => {
					return {
						key: `${project.project_type_index}-${project.id}`,
						...project,
						tags: project.unit_tags.map(tag => tag.name).join(','),
					}
				})
				this.call.getProjectList = true
				this.table.paging = {
					...this.table.paging,
					count: response.paging.count,
					currentPage: response.paging.current_page,
					from: response.paging.from,
					lastPage: response.paging.last_page,
					perPage: response.paging.per_page,
					to: response.paging.to,
					total: response.paging.total,
				};
				this.table.configs.busy = false;
			}, (error) => {
				this.table.configs.busy = false;
			})
		},
		routeToProjectInfo(index) {
			if(this.group) {
				return {name: '案件', params: {groupID: this.groupID, projectType: this.projects[index].project_type_index, projectID: this.projects[index].id}}
			}
			return {name: '案件', params: {groupID: this.groupID, projectType: this.projects[index].project_type_index, projectID: this.projects[index].id}}
		},
		getProjectSceneUrl(index) {
			return this.projects[index].scene_pictures_url;
		},
		changePage(page) {
			this.paging.page = page
		},
		getData(options) {
			this.projectFilter = {
				...this.filter,
				...options.search
			}
			this.sorting = options.sort
			this.paging.page = 1
		},
		checkPermission(action_key) {
			if(!this.groupID) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
		showExcelExportModal() {
			this.excelExportModal = true
			this.excelExportFormData.conditions = deepCopy(this.projectFilter)
			this.excelExportFormData.excelData = Object.keys(this.excelColumns)
		},
		showTpwProjectDailyReceivedExcelModal() {
			this.tpwProjectDailyReceivedExcelExportModal = true
		},
		showTpwProjectUnitReportSubmittedExcelModal() {
			this.tpwProjectUnitReportSubmittedExcelModal.formData = {
				project_data_type_id: this.groupReportTypes.first ? this.groupReportTypes.first.project_data_type_id : undefined,
				unit_type: this.groupReportTypes.first ? this.groupReportTypes.first.unit_type_index : undefined,
				submitted_start_at: (new Date()).addWorkDays(-1).toDateString(),
				submitted_end_at: (new Date()).addWorkDays(-1).toDateString(),
			}
			this.tpwProjectUnitReportSubmittedExcelModal.show = true
		},
		showTpwProjectPavedInformationExcelModal() {
			this.tpwProjectPavedInformationExcelModal.formData = {
				// submitted_start_at: (new Date()).addWorkDays(-1).toDateString(),
				// submitted_end_at: (new Date()).addWorkDays(-1).toDateString(),
			}
			this.tpwProjectPavedInformationExcelModal.show = true
		},
		getGroupReportUnitTypes(projectDataTypeId) {
			return this.groupReportTypes.groupBy('project_data_type_id')[projectDataTypeId] ?? []
		},
		showSetProjectAcceptedAtModal() {
			this.setProjectAcceptedAtModal = true
			this.setAcceptedAtConditions = {
				assigned_at: [],
				...deepCopy(this.projectFilter)
			}
		},
		exportExcel() {
			this.downloading = true
			this.$axios.exportProjectExcel(this.excelExportFormData, (response) => {
				let filename = `${this.group.name}案件清單_${this.excelExportFormData.conditions[this.excelFilterColumn.key].uniq().map(date => new Date(date).format('yyyyMMdd')).join('-')}`
				fileManager.saveFile(filename, 'xlsx', response)
				this.downloading = false
			}, (error) => {
				this.downloading = false
			})
		},
		exportTpwProjectDailyReceivedExcel() {
			this.downloading = true
			let date = new Date(this.tpwProjectDailyReceivedExcelExportFormData.first_received_at)
			this.$axios.exportTpwProjectDailyReceivedExcel(this.tpwProjectDailyReceivedExcelExportFormData, (response) => {
				fileManager.saveFile(`${date.format('yyyy-MM-dd')}_當日收件檢核表`, 'xlsx', response)
				this.downloading = false
			}, (error) => {
				this.downloading = false
			})
		},
		exportTpwProjectImplementationExcel() {
			this.downloading = true
			this.$axios.exportTpwProjectImplementationExcel({
				project_group_id: this.groupID,
			}, (response) => {
				fileManager.saveFile(`${new Date().format('yyyy-MM-dd')}_專案執行狀況`, 'xlsx', response)
				this.downloading = false
			}, (error) => {
				this.downloading = false
			})
		},
		exportProjectCsv() {
			this.downloading = true
			this.$axios.exportProjectCsv({
				...this.apiQuery, paging: 0
			}, (response) => {
				fileManager.saveFile(`${this.group.name}案件列表${new Date().format('yyyy-MM-dd')}`, 'csv', response)
				this.downloading = false
			}, (error) => {
				this.downloading = false
			})
		},
		exportTpwProjectUnitReportSubmittedExcel() {
			this.downloading = true
			let data = {
				...this.tpwProjectUnitReportSubmittedExcelModal.formData,
				project_group_id: this.group.id,
			}
			let groupReportUnitTypes = this.getGroupReportUnitTypes(this.tpwProjectUnitReportSubmittedExcelModal.formData.project_data_type_id)
			let dataTypeName = groupReportUnitTypes.first.project_data_type.name
			let date = [
				(new Date(data.submitted_start_at)).format('yyyyMMdd'),
				(new Date(data.submitted_end_at)).format('yyyyMMdd'),
			].uniq().join('-')
			this.$axios.exportTpwProjectUnitReportSubmittedExcel(data, (response) => {
				fileManager.saveFile(`${dataTypeName}上傳清冊${date}`, 'xlsx', response)
				this.downloading = false
			}, (error) => {
				this.downloading = false
			})
		},
		exportTpwProjectGml2UncommittedExcel() {
			let dataTypeName = 'GML2'
			let reportType = this.groupReportTypes.find(reportType => reportType.project_data_type.name === dataTypeName)
			let data = {
				project_group_id: this.group.id,
				// project_data_type_id: reportType ? reportType.project_data_type_id : undefined,
				// unit_type: reportType ? reportType.unit_type_index : undefined,
				only_with_excavation_permit: true,
				status: this.$store.getters.enum(`${this.projectTypeStatusEnumKey}.party_a_report_submitted`).index
			}
			let date = (new Date()).toDateString()
			this.downloading = true
			this.$axios.exportTpwProjectStatusExcel(data, (response) => {
				fileManager.saveFile(`${this.group.name}尚未收到${dataTypeName}清冊${date}`, 'xlsx', response)
				this.downloading = false
			}, (error) => {
				this.downloading = false
			})
		},
		exportTpwProjectPavedInformationExcel() {
			let data = {
				...this.tpwProjectPavedInformationExcelModal.formData,
				project_group_id: this.group.id,
			}
			let date = this.tpwProjectPavedInformationExcelModal.formData[this.excelFilterColumn.key].uniq().map(date => new Date(date).format('yyyyMMdd')).join('-')
			this.downloading = true
			this.$axios.exportTpwProjectPavedInformationExcel(data, (response) => {
				fileManager.saveFile(`${this.group.name}銑舖資料清冊${date}`, 'xlsx', response)
				this.downloading = false
			}, (error) => {
				this.downloading = false
			})
		},
		cancelSelect(){
			this.table.configs.selectable = false
			this.setAcceptedAtData = []
		},
		rowSelected(data) {
			this.setAcceptedAtData = data
		},
		setAcceptedAt() {
			if(!this.setAcceptedAtData.length){
				alert("請選擇案件")
				return
			}
			let data = this.setAcceptedAtData.map(datum => {
				return {
					project_type: datum.project_type_index,
					project_id: datum.id,
				}; 
			})
			this.$axios.setProjectAcceptedAt({set_accepted_at_projects: data}, (response) => {
				alert("更新成功")
				location.reload();
			}, (error) => {

			})
		},
	}
}
</script>

<style scoped>
.table-paging {
	margin: 1rem;
}
.search-bar {
	margin: 0 1rem;
}
/* 清除自動填字背景色 */
.input-text:-webkit-autofill,
.input-text:-webkit-autofill:hover,
.input-text:-webkit-autofill:focus,
.input-text:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
	-webkit-transition: background-color 5000s ease-in-out 0s;
	-moz-transition: background-color 5000s ease-in-out 0s;
	-o-transition: background-color 5000s ease-in-out 0s;
	box-shadow: 0 0 0 30px white inset !important;
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	-moz-box-shadow: 0 0 0 30px white inset !important;
	-o-box-shadow: 0 0 0 30px white inset !important;
}
.input-text {
	border: none;
	z-index: 3;
}
.input-text:focus {
	border: none;
	box-shadow: none;
}
.input-text:focus + .input-label + .input-background {
	border: 1px solid #9bc8de;
}
.input-text.is-invalid + .input-label + .input-background{
	border: 1px solid red;
}
.link:hover {
	cursor: pointer;
	text-decoration: none;
}
.input-background {
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 1.5rem;
	border: 1px solid #e0e8e7;
	width: 100%;
	height: 100%;
}
.input-label {
	background-color: transparent;
	border: none;
	z-index: 3;
}
.table-bar {
	display: flex;
}
@media (min-width:576px) {
	.table-bar {
		flex-direction: row;
		justify-content: space-between;
	}
}

@media (max-width:575px) {
	.table-bar {
		flex-direction: column;
	}
}
.table-btn {
	color: #4c6b8a;
	margin: 0 .5rem;
	background-color: transparent;
	border: none;
}
.table-btn:hover {
	color: #6b8ca9;
	cursor: pointer;
}
.table-btn:hover > label {
	cursor: pointer;
}
.table-btn:focus {
	box-shadow: none !important;
}
.action-btn {
	padding: .25rem .75rem;
	border-radius: .5rem;
	background: #b2c8d8aa;
	color: #050b10cc;
	border-color: #b2c8d8;
}
.action-btn:hover {
	text-decoration: none;
	background: #8ea9c2cc;
	color: #050b10;
}
.action-btn:focus {
	box-shadow: none;
}
</style>