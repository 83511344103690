<template>
	<loading v-if="loading"/>
	<div v-else>
		<h6 style="font-size:1.2em">測量點位</h6>
		<div class="pl-3 pr-1">
			<b-alert class="mt-3 mb-3 py-3 px-md-3 px-1 alert-point-list" show variant="danger" v-if="Object.values(errorPoints).some(err=>err.points.length)">
				<template v-for="(error, error_key) in errorPoints">
					<div v-if="error.points.length" :key="error_key">
						<b-row class="align-items-center font-weight-bold"><i class="fa-fw fas fa-exclamation-triangle mr-1"></i>{{error.msg}}</b-row>
						<template v-for="(point, key) in error.points">
							<li class="ml-4 pl-4" style="white-space: normal;text-indent: -1.3rem;" :key="key" v-if="error.pointMsg(point)">{{error.pointMsg(point)}}</li>
						</template>
					</div>
				</template>
			</b-alert>
			<b-alert class="mt-3 mb-3 py-3 px-md-3 px-1 alert-point-list" show variant="warning" v-if="Object.values(warningPoints).some(err=>err.points.length)">
				<template v-for="(error, error_key) in warningPoints">
					<div v-if="error.points.length" :key="error_key">
						<b-row class="align-items-center font-weight-bold"><i class="fa-fw fas fa-exclamation-circle mr-1"></i>{{error.msg}}</b-row>
						<template v-for="(point, key) in error.points">
							<li class="ml-4 pl-4" style="white-space: normal;text-indent: -1.3rem;" :key="key" v-if="error.pointMsg(point)">{{error.pointMsg(point)}}</li>
						</template>
					</div>
				</template>
			</b-alert>
			<bv-table :columns="pointTable.columns" :data="pointTable.data" :configs="pointTable.configs" :actions="pointTable.actions" :detail-columns="pointTable.detailColumns" :rowConfigs="pointTable.rowConfigs"></bv-table>
			<b-row class="col-12 justify-content-end align-items-center">
				<b-button class="ml-2 form-btn submit-btn" @click="postFourPitchPoints">新增四支距點位</b-button>
				<b-button class="ml-2 form-btn submit-btn" @click="downloadPointsPositionCsv">下載大地起伏csv</b-button>
				<!-- <b-button class="ml-2 form-btn submit-btn" @click="downloadPointsPhotos">下載測量照片</b-button> -->
				<!-- <b-button class="ml-2 form-btn submit-btn" @click="downloadPointsGeoCoordinate">下載經緯度座標正高轉換檔</b-button> -->
				<b-button class="ml-2 form-btn submit-btn" @click="downloadPointsToOrthometricHeight">下載正高轉換檔</b-button>
				<a class="row align-items-center" href="https://egnss.nlsc.gov.tw/trans/geo.aspx" target="_blank"><i class="fa-fw fas fa-globe"></i>轉正高網頁連結<i class="fa-fw fas fa-angle-double-right ml-1"></i></a>
			</b-row>
		</div>
		<div class="progress-wrapper" v-if="posting">
			<loading/>
		</div>
	</div>
</template>

<script>
import BvTable from '@/components/Table/BvTable'
import Loading from '@/components/Loading/Loading.vue';
import { checkAllTrue } from '@/utils/assist';
import fileManager from '@/utils/file';
import { IsFacilityType } from '@/gml/utils'

export default {
	name: 'ProjectResultPointList',
	components: {
		BvTable,
		Loading
	},
	props: {
		project: {
			type: Object,
			default: () => { return {} }
		},
		points: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: false,
			pointTable: {
				detailColumns: [],
				columns: [
					{ key: 'name', label: '名稱', sortable: true, searchable: true, isRowHeader: true },
					{ key: 'twd97_x', label: 'X', sortable: true, searchable: true },
					{ key: 'twd97_y', label: 'Y', sortable: true, searchable: true },
					// { key: 'ellipsoidal_height', label: '橢球高', sortable: true, searchable: true },
					{ key: 'orthometric_height', label: '正高(Z)', sortable: true, searchable: true },
					{ key: '必填欄位_埋深', label: '埋深(H)', sortable: true, searchable: true },
					{ key: '必填欄位_設施物', label: '種類', sortable: true, searchable: true },
					// { key: 'facility_height', label: '管頂高程(Z-H)', sortable: true, searchable: true },
				],
				data: [],
				actions: [],
				configs: {
					actionsColumn: true,
					rowDetail: true,
					busy: true,
					perPage: 10,
					showStatistic: true,
				},
				rowConfigs: {}
			},
			errorPoints: {
				errorCoordinate: {
					validator: pt => pt.coordinate === null,
					msg: "未取得測量座標，請重新上傳測量座標csv",
					points: [],
					pointMsg: (point) => ""
				},
				errorOrthometricHeight: {
					validator: pt => pt.coordinate ? pt.coordinate.orthometric_height === null : false,
					msg: "測量點正高轉換錯誤，請下載後手動轉換",
					points: [],
					pointMsg: (point) => ""
				},
			},
			warningPoints: {
				singleFacility: {
					validator: pt => !IsFacilityType(pt.transformed_data.type) && typeof this.getPipelinePointUnderFacility(pt) === "undefined",
					msg: "單點設施物",
					points: [],
					pointMsg: (point) => `${point.name}：未與任何管線相連`
				},
				incorrectDepth: {
					validator: pt => pt.transformed_data.depth > 3,
					msg: "點位深度過深",
					points: [],
					pointMsg: (point) => `${point.name}：埋深大於3公尺`
				},
				facilityPosition: {
					validator: pt => !IsFacilityType(pt.transformed_data.type) && this.getDistanceFromFacilityToPipeline(pt) >= 0.01,
					msg: "設施物座標與所在管線距離大於1cm",
					points: [],
					pointMsg: (point) => `${point.name}：所在管線點號 ${point.transformed_data.target1}`
				},
			},
			downloading: false,
			posting: false,
		}
	},
	created() {
		this.validatePoints()
		this.setPointTable()
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	mounted() {
	},
	computed: {
		distance(pos1, pos2) {
			return (pos1, pos2) => Math.sqrt(Math.pow(pos1.twd97_x - pos2.twd97_x, 2) + Math.pow(pos1.twd97_y - pos2.twd97_y, 2))
		},
	},
	methods:{
		reload() { this.$emit('reload') },
		setPointTable() {
			this.pointTable.detailColumns = [
				{ key: 'id', label: 'ID', hide: !this.$store.getters.isDeveloper },
				{ key: 'name', label: '名稱' },
				{ key: 'coordinate.twd97_x', label: 'X', deep: true },
				{ key: 'coordinate.twd97_y', label: 'Y', deep: true },
				{ key: 'coordinate.orthometric_height', label: '正高', deep: true },
				{ key: 'coordinate.ellipsoidal_height', label: '橢球高', deep: true },
				{ key: 'transformed_data.depth', label: '埋深', sortable: true, searchable: true, deep: true},
				{ key: 'transformed_data.type', label: '種類', sortable: true, searchable: true, deep: true},
				...[...new Set(this.points.map(point => Object.keys(point.modified_form_data)).flat())].map(key => {
					return { key: `modified_form_data.${key}`, label: key, deep: true }
				}),
				...[...new Set(this.points.map(point => Object.keys(point.data_list)).flat())].map(label => {
					return { key: `data_list.${label}.content`, label: label, deep: true, type: 'image' }
				})
			]
			this.pointTable.rowConfigs = {
				class: (row, index) => {
					if(Object.values(this.errorPoints).find(error => error.points.find(pt => pt.name === row.name)))
						return "table-danger"
					if(Object.values(this.warningPoints).find(error => error.points.find(pt => pt.name === row.name)))
						return "table-warning"
					return ""
				}
			}
			this.pointTable.data = this.points.map( pt => {
				return {
					...pt,
					...pt.coordinate,
					...Object.keys(pt.form_data).reduce((obj, key) => {
						obj[key] = pt.form_data[key].value
						return obj
					}, {}),
				}
			})
			this.pointTable.configs.busy = false
		},
		getDistanceFromFacilityToPipeline(facility_point) {
			let target = this.getPipelinePointUnderFacility(facility_point)
			return target && target.coordinate ? this.distance(facility_point.coordinate, target.coordinate) : 0
		},
		getPipelinePointUnderFacility(facility_point) {
			return this.points.find(pt => pt.name === facility_point.transformed_data.target1)
		},
		getErrorPoints() {
			for(let error in this.errorPoints) {
				this.errorPoints[error].points = this.points.filter(this.errorPoints[error].validator)
			}
		},
		getWarningPoints() {
			for(let error in this.warningPoints) {
				this.warningPoints[error].points = this.points.filter(this.warningPoints[error].validator)
			}
		},
		validatePoints() {
			this.getErrorPoints()
			this.getWarningPoints()
		},
		downloadPointsPositionCsv() {
			let content = ["序號,點號,N,E,橢球高,大地起伏,正高,埋深,種類,地表高度,目標,目標2,目標3"]
			this.points.forEach( (pt, index, arr) => {
				let coordinate = {
					twd97_y : pt.coordinate ? pt.coordinate.twd97_y : 0,
					twd97_x : pt.coordinate ? pt.coordinate.twd97_x : 0,
					ellipsoidal_height : pt.coordinate ? pt.coordinate.ellipsoidal_height : 0,
					orthometric_height : pt.coordinate ? pt.coordinate.orthometric_height : 0,
				}
				content.push([
					index + 1,
					pt.name,
					coordinate.twd97_y,
					coordinate.twd97_x,
					coordinate.ellipsoidal_height,
					coordinate.orthometric_height !== null ? (coordinate.ellipsoidal_height - coordinate.orthometric_height).toFixed(3) : '',
					coordinate.orthometric_height !== null ? coordinate.orthometric_height : '',
					pt.transformed_data.depth,
					pt.transformed_data.type,
					0,
					!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target1 : '',
					!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target2 : '',
					!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target3 : ''
				].join(","))
			})
			fileManager.saveFile(`${this.project.excavation_permit}_${this.project.address}-大地起伏轉換`, "csv", content.join("\r\n"), {bom: true})
			this.downloadPointsPositionCsvWithPointName()
		},
		downloadPointsPositionCsvWithPointName() {
			let content = ["序號,點號,N,E,橢球高,大地起伏,正高,埋深,種類,地表高度,目標,目標2,目標3"]
			this.points.forEach( (pt, index, arr) => {
				let coordinate = {
					twd97_y : pt.coordinate ? pt.coordinate.twd97_y : 0,
					twd97_x : pt.coordinate ? pt.coordinate.twd97_x : 0,
					ellipsoidal_height : pt.coordinate ? pt.coordinate.ellipsoidal_height : 0,
					orthometric_height : pt.coordinate ? pt.coordinate.orthometric_height : 0,
				}
				content.push([
					index + 1,
					pt.full_name,
					coordinate.twd97_y,
					coordinate.twd97_x,
					coordinate.ellipsoidal_height,
					coordinate.orthometric_height !== null ? (coordinate.ellipsoidal_height - coordinate.orthometric_height).toFixed(3) : '',
					coordinate.orthometric_height !== null ? coordinate.orthometric_height : '',
					pt.transformed_data.depth,
					pt.transformed_data.type,
					0,
					!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target1 : '',
					!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target2 : '',
					!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target3 : ''
				].join(","))
			})
			fileManager.saveFile(`${this.project.excavation_permit}_${this.project.address}-大地起伏轉換(GML)`, "csv", content.join("\r\n"), {bom: true})
		},
		downloadPointsToOrthometricHeight() {
			let content = []
			this.points.forEach( pt => {
				let coordinate = this.getPointCoordinate(pt.coordinate)
				content.push([pt.full_name, "NEh", coordinate.twd97_y, coordinate.twd97_x, coordinate.ellipsoidal_height].join(","))
			})
			fileManager.saveFile(`${this.project.excavation_permit}_${this.project.address}-轉正高`, "txt", content.join("\n"))
			// window.open("https://egnss.nlsc.gov.tw/trans/geo.aspx", '_blank');
		},
		downloadPointsGeoCoordinate() {
			let content = []
			this.points.forEach( pt => {
				let coordinate = this.getPointCoordinate(pt.coordinate)
				content.push([pt.full_name, coordinate.twd97_y, coordinate.twd97_x, coordinate.lng, coordinate.lat, coordinate.ellipsoidal_height].join(" "))
			})
			fileManager.saveFile(`${this.project.excavation_permit}_${this.project.address}-經緯度轉正高`, "txt", content.join("\r\n"))
			// window.open("https://egnss.nlsc.gov.tw/trans/geo.aspx", '_blank');
		},
		postFourPitchPoints() {
			this.posting = true
			this.$axios.postFourPitchSystem(this.site.id, (response) => {
				this.posting = false
				if(response.new_construction_site) {
					alert(`成功新增${response.new_points_count}個點位，請至四支距系統查詢。`);
				}
				else {
					alert(`無需測量四支距之點位或北水巡查系統已有此工地之四支距點位。`);
				}
			}, (error) => {
				this.posting = false
				let errors = Array.isArray(error.response.data.errors) ? error.response.data.errors : Object.values(error.response.data.errors);
				switch(error.response.status) {
					case 422:
						alert(`新增失敗！資料錯誤：\n${errors.flat().join("\n")}`);
						break;
					default:
						alert(error);
						break;
				}
			})
		},
		getPointCoordinate(coordinate) {
			return {
				twd97_y : coordinate ? coordinate.twd97_y : 0,
				twd97_x : coordinate ? coordinate.twd97_x : 0,
				ellipsoidal_height : coordinate ? coordinate.ellipsoidal_height : 0,
				orthometric_height : coordinate ? coordinate.orthometric_height : 0,
			}
		},
	}
}
</script>

<style scoped>
.google-map {
	min-height: 500px;
	height: 500px;
}

.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}



.alert-point-list {
	max-height: 300px;
	overflow-y: auto;
	word-wrap: break-word;
}
</style>