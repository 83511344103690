<template>
	<loading mode="fixed" image="1" size="lg" v-if="loading"/>
	<div class="p-2" v-else>
		<b-list-group-item class="p-0">
			<div class="px-md-4 px-2 py-3 d-flex justify-content-between align-items-center collapsed-icon" v-b-toggle="`${site.id}-result-data`">
				<b>工地成果</b>
			</div>
			<b-collapse :id="`${site.id}-result-data`" class="px-md-4 p-2">
				<template v-for="(data, key) in site.data">
					<b-row class="list-unstyled" v-if="data" :key="key">
						<font class="pl-3" style="flex: 0 1 7rem;">{{data.name}}：</font>{{data.value}}
					</b-row>
				</template>
				<hr />
				<template v-for="(data, key) in site.result.form_data">
					<b-row class="list-unstyled" v-if="data" :key="key">
						<font class="pl-3" style="flex: 0 1 7rem;">{{data.name}}：</font>{{data.value}}
					</b-row>
				</template>
			</b-collapse>
		</b-list-group-item>
		<b-list-group-item class="p-0">
			<div class="px-md-4 px-2 py-3 d-flex justify-content-between align-items-center collapsed-icon" v-b-toggle="`${site.id}-point-list`">
				<b>點位列表</b>
			</div>
			<b-collapse :id="`${site.id}-point-list`" class="px-md-4 p-2">
				<result-point-list :site="site" @reload="reload"></result-point-list>
			</b-collapse>
		</b-list-group-item>
		<b-list-group-item class="p-0">
			<div class="px-md-4 px-2 py-3 d-flex justify-content-between align-items-center collapsed-icon" v-b-toggle="`${site.id}-point-photo`">
				<b>測量資料</b>
			</div>
			<b-collapse :id="`${site.id}-point-photo`" class="px-md-4 p-2">
				<result-data-viewer class="col-12 mb-3" :site="site" :permission="permission" :project-data-type="projectDataType" @reload="reload"></result-data-viewer>
			</b-collapse>
		</b-list-group-item>
		<b-list-group-item class="p-0">
			<div class="px-md-4 px-2 py-3 d-flex justify-content-between align-items-center collapsed-icon" v-b-toggle="`${site.id}-four-pitch-data`">
				<b>四支距資料（北水巡查系統）</b>
			</div>
			<b-collapse :id="`${site.id}-four-pitch-data`" class="px-md-4 p-2">
				<result-four-pitch-point-list :site="site"></result-four-pitch-point-list>
			</b-collapse>
		</b-list-group-item>
		<div class="row col-12 justify-content-end">
			<b-button class="form-btn submit-btn" @click="measuredConstructionSite" v-if="editable && (site.status_index === $store.getters.enum('construction_site.status.remeasured').index)">確認工地已測量</b-button>
			<b-button class="form-btn clear-btn" @click="remeasureConstructionSite" v-if="editable && (site.status_index === $store.getters.enum('construction_site.status.measured').index || site.status_index === $store.getters.enum('construction_site.status.measurement_finished').index)">工地待補測</b-button>
			<b-button class="form-btn submit-btn" @click="finishConstructionSite" v-if="editable && (site.status_index === $store.getters.enum('construction_site.status.measured').index)">確認工地已完成</b-button>
			<!-- <b-button class="form-btn submit-btn" @click="updateConstructionSiteData">回復</b-button> -->
		</div>
		<hr />
		<div class="progress-wrapper" v-if="downloading">
			<b-progress class="progress-bar-wrapper" :max="1">
				<b-progress-bar :value="1" striped animated></b-progress-bar>
			</b-progress>
		</div>
	</div>
</template>

<script>
import ResultPointList from '@/components/Project/Result/ResultPointList.vue'
import ResultDataViewer from '@/components/Project/Result/ResultDataViewer.vue'
import ResultFourPitchPointList from '@/components/Project/Result/ResultFourPitchPointList.vue'
import BvTable from '@/components/Table/BvTable.vue'
import Loading from '@/components/Loading/Loading.vue'
import { checkAllTrue } from '@/utils/assist';

export default {
	name: 'ResultViewer',
	components: {
		ResultPointList,
		ResultDataViewer,
		ResultFourPitchPointList,
		BvTable,
		Loading,
	},
	props: {
		project: {
			type: Object,
		},
		site: {
			type: Object,
			default: () => { return {} }
		},
		permission: {
			type: Object
		},
		projectDataType: {
			type: Array
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: true,
			result: {},
			points: [],
			downloading: false,
		}
	},
	created() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	mounted() {
	},
	computed: {
		editable() {
			return this.checkPermission('edit') ? true : false
		},
	},
	methods:{
		reload() { this.$emit('reload') },
		remeasureConstructionSite() {
			this.$axios.setConstructionSiteRemeasure(this.site.id, {}, (response) => {
				alert("更新成功");
				this.call.finishConstructionSite = true
				this.reload();
			}, (error) => {
				this.call.finishConstructionSite = true
			})
		},
		measuredConstructionSite() {
			this.$axios.setConstructionSiteMeasured(this.site.id, {}, (response) => {
				alert("更新成功");
				this.call.finishConstructionSite = true
				this.reload();
			}, (error) => {
				this.call.finishConstructionSite = true
			})
		},
		finishConstructionSite() {
			this.$set(this.call, "finishConstructionSite", false);
			this.$axios.setConstructionSiteComplete(this.site.id, {}, (response) => {
				alert("更新成功");
				this.call.finishConstructionSite = true
				this.reload();
			}, (error) => {
				this.call.finishConstructionSite = true
			})
		},
		updateConstructionSiteData() {
			this.$set(this.call, "updateConstructionSiteData", false);
			this.$axios.updateConstructionSiteData(this.site.id, {status: this.$store.getters.enum("construction_site.status.measured").index}, (response) => {
				alert("更新成功");
				this.call.updateConstructionSiteData = true
				this.reload();
			}, (error) => {
				this.call.updateConstructionSiteData = true
			})
		},
		checkPermission(action_key) {
			if(!this.groupID) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
	}
}
</script>

<style scoped>
.google-map {
	min-height: 500px;
	height: 500px;
}

.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>