<template>
	<loading v-if="loading"/>
	<div class="" v-else>
		<h6 style="font-size:1.2em">工地資料</h6>
		<result-info class="col-12 mt-2" :site="site" v-for="(site, key) in sites" :key="key" />
	</div>
</template>

<script>
import ResultInfo from '@/components/Project/Result/ResultInfo.vue'
import Loading from '@/components/Loading/Loading.vue'
import { checkAllTrue } from '@/utils/assist';

export default {
	name: 'ProjectResultInfo',
	components: {
		ResultInfo,
		Loading,
	},
	props: {
		sites: {
			type: Array,
			default: () => []
		},
		project: {
			type: Object
		}
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: { },
			loading: true,
			result: {},
			points: [],
			downloading: false,
		}
	},
	created() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	mounted() {
	},
	computed: {
	},
	methods:{
		routeToEditor() {
			this.$router.push({name: '管線編輯', params: {projectID: this.projectID, siteID: this.site.id, data: { project: this.project, site: this.site, points: this.site.points }}});
		},
	}
}
</script>

<style scoped>
.google-map {
	min-height: 500px;
	height: 500px;
}


.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>