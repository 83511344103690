import Login from './Login.vue'
import Register from './Register.vue'
import Home from './Home.vue'
import Logout from './Logout.vue'

import Page from './Page.vue'
import EmptyPage from './EmptyPage.vue'

import Profile from './Profile.vue'

import MemberManager from './MemberManager.vue'
import UnitManager from './UnitManager.vue'
import UnitInfo from './UnitInfo.vue'
import BidManager from './BidManager.vue'
import ConstructionManager from './ConstructionManager.vue'
import ProjectDataTypeManager from './ProjectDataTypeManager.vue'

import ProjectCreator from './ProjectCreator.vue'
import ProjectList from './ProjectList.vue'
import ProjectInfo from './ProjectInfo.vue'
import Project from './Project.vue'
import ProjectPage from './ProjectPage.vue'
import GroupList from './GroupList.vue'
import GroupCreator from './GroupCreator.vue'
import Group from './Group.vue'
import GroupInfo from './GroupInfo.vue'
import GroupSetting from './GroupSetting.vue'
import GroupProjectList from './GroupProjectList.vue'
import GroupProjectFormList from './GroupProjectFormList.vue'
import GroupConstructionSiteList from './GroupConstructionSiteList.vue'

import ConstructionSiteManager from './ConstructionSiteManager.vue'
import ConstructionSiteList from './ConstructionSiteList.vue'
import ProjectResult from './ProjectResult.vue'
import ProjectReport from './ProjectReport.vue'
import ProjectMapEditor from './ProjectMapEditor.vue'

import Assignment from './Assignment.vue'
import AssignMemberManager from './AssignMemberManager.vue'

import DynamicFormManager from './DynamicFormManager.vue'
import DynamicFormEditor from './DynamicFormEditor.vue'

import ControlPointViewer from './ControlPointViewer.vue'



const Views = {
	Login,
	Register,
	Home,
	Logout,
	Page,
	EmptyPage,
	Profile,
	MemberManager,
	UnitManager,
	UnitInfo,
	BidManager,
	ConstructionManager,
	ProjectDataTypeManager,
	ProjectCreator,
	ProjectList,
	ProjectInfo,
	Project,
	ProjectPage,
	GroupList,
	GroupCreator,
	Group,
	GroupInfo,
	GroupSetting,
	GroupProjectList,
	GroupProjectFormList,
	GroupConstructionSiteList,
	ConstructionSiteList,
	ConstructionSiteManager,
	ProjectResult,
	ProjectReport,
	ProjectMapEditor,
	Assignment,
	AssignMemberManager,
	DynamicFormManager,
	DynamicFormEditor,
	ControlPointViewer,
}

export default Views